import { VueQueryPlugin, keepPreviousData } from '@tanstack/vue-query';
import { type App } from 'vue';

export default function registerQuery(app: App): void {
  app.use(VueQueryPlugin, {
    queryClientConfig: {
      defaultOptions: {
        queries: {
          placeholderData: keepPreviousData,
          refetchOnWindowFocus: false,
        },
      },
    },
  });
}
