import { ref, type Ref, watch } from 'vue';
import { useRoute } from '~/infrastructure';

interface UseFilterReturn {
  isOpen: Ref<boolean>;
  open: () => void;
  close: () => void;
  toggleFilterState: () => void;
}

export default function useFilter(): UseFilterReturn {
  const isOpen = ref(false);
  const route = useRoute();

  const open = (): void => {
    isOpen.value = true;
  };

  const close = (): void => {
    isOpen.value = false;
  };

  const toggleFilterState = (): void => {
    isOpen.value = !isOpen.value;
  };

  watch(
    () => route.path,
    (newPath) => {
      if (!newPath.includes('cto-apple')) close();
    },
  );

  return {
    isOpen,
    close,
    open,
    toggleFilterState,
  };
}
