<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 3C4.79086 3 3 4.79086 3 7V17C3 19.2091 4.79086 21 7 21H17C19.2091 21 21 19.2091 21 17V7C21 4.79086 19.2091 3 17 3H7ZM16.4791 9.43922C16.7217 9.17459 16.7038 8.76342 16.4392 8.52085C16.1746 8.27827 15.7634 8.29615 15.5208 8.56078L10.5233 14.0126L8.49934 11.5839C8.26953 11.3081 7.85966 11.2708 7.58388 11.5007C7.3081 11.7305 7.27084 12.1403 7.50066 12.4161L10.0007 15.4161C10.1207 15.5602 10.2972 15.6454 10.4847 15.6498C10.6722 15.6542 10.8524 15.5775 10.9791 15.4392L16.4791 9.43922Z"
      fill="#1C4D88"
    />
  </svg>
</template>
