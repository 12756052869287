<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.4487 3.7904L11.4487 3.79042L5.25141 7.17954L8.17314 8.93805L14.7492 4.99242L12.5512 3.79042L12.5512 3.7904C12.3832 3.69853 12.1934 3.64998 12 3.64998C11.8065 3.64998 11.6168 3.69853 11.4487 3.7904ZM16.0711 5.71533L9.43454 9.69726L11.9936 11.2375L18.6086 7.10304L16.0711 5.71533ZM4.64998 15.846V8.33486L11.35 12.3674V20.1555L5.22664 16.8051L5.22648 16.805C5.05022 16.7086 4.90463 16.5683 4.80375 16.3997C4.70296 16.2312 4.65016 16.0401 4.64998 15.846ZM18.7733 16.8051L12.65 20.1555V12.3602L19.35 8.17274V15.8453V15.8454C19.3499 16.0396 19.2971 16.2309 19.1963 16.3995C19.0955 16.5682 18.9498 16.7086 18.7735 16.805L18.7733 16.8051ZM10.825 2.6498C11.185 2.45294 11.5894 2.34998 12 2.34998C12.4106 2.34998 12.8149 2.45294 13.1749 2.6498L13.175 2.64982L19.855 6.30292L19.8553 6.30308C20.0945 6.43407 20.2951 6.62637 20.4353 6.86112C20.5756 7.09595 20.65 7.36426 20.65 7.63817V15.8454V15.8456C20.6499 16.2756 20.5329 16.6972 20.3121 17.0666C20.0914 17.4358 19.7751 17.7389 19.3972 17.9456L13.1751 21.3501L13.1749 21.3501C12.8149 21.547 12.4106 21.65 12 21.65C11.5894 21.65 11.185 21.547 10.825 21.3501L10.8249 21.3501L4.6028 17.9456C4.22503 17.739 3.90889 17.4361 3.68814 17.0671C3.46733 16.698 3.3503 16.2766 3.34998 15.8468V15.8463V7.63817V7.63814C3.34997 7.36425 3.42436 7.09595 3.56462 6.86112C3.70484 6.62637 3.90546 6.43407 4.1447 6.30308L4.14499 6.30292L10.825 2.64982L10.825 2.6498ZM7.29066 11.4186C6.96958 11.2581 6.57914 11.3882 6.4186 11.7093C6.25805 12.0304 6.3882 12.4208 6.70929 12.5814L8.70929 13.5814C9.03037 13.7419 9.42081 13.6117 9.58135 13.2907C9.7419 12.9696 9.61175 12.5791 9.29066 12.4186L7.29066 11.4186Z"
      fill="currentColor"
    />
  </svg>
</template>
