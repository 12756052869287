import { type ProductInfo } from '~/domains/product';
import { ComparedFeature, ComparedSpecification } from '~/domains/compare';

export const createComparedProductsSpecifications = (
  products: ProductInfo[],
): ComparedSpecification[] => {
  const comparedSpecifications = new Map<string, ComparedSpecification>();

  products.forEach((product) => {
    product.specification?.forEach((specification) => {
      const existingComparedSpecification = comparedSpecifications.get(
        specification.caption,
      );

      const sku = product.product.sku.replace(/\./g, '');

      if (existingComparedSpecification == null) {
        const comparedFeatures: ComparedFeature[] = specification.features.map(
          (feature) => ComparedFeature.fromProductSpecification(sku, feature),
        );

        comparedSpecifications.set(
          specification.caption,
          new ComparedSpecification(
            specification.caption,
            new Map(
              comparedFeatures.map((feature) => [feature?.code, feature]),
            ),
          ),
        );

        return;
      }

      const existingComparedFeatures =
        existingComparedSpecification.mergedFeatures;

      specification.features.forEach((feature) => {
        const existingComparedFeature = existingComparedFeatures?.get(
          feature.code,
        );

        if (existingComparedFeature != null) {
          existingComparedFeature?.addValue(sku, feature?.value);
        } else {
          existingComparedFeatures?.set(
            feature.code,
            ComparedFeature.fromProductSpecification(sku, feature),
          );
        }
      });
    });
  });

  return [...comparedSpecifications.values()];
};
