import { type Product } from '~/domains/product';
import { ProductDeliveryStatuses } from '~/domains/product/types.d';

const products: Product[] = [
  {
    id: 1,
    article: 'CKG939404030',
    title:
      'A modern USB-C > DisplayPort active adapter AXAGON RVC-DP for connecting a DisplayPort monitor/TV/projector to a notebook or mobile phone using USB type C connector. DisplayPort monitor/TV/projector to a notebook or mobile phone using USB type C connector DisplayPort monitor/TV/projector to a notebook or mobile phone using USB type C connector',
    sku: 'MVFH2RWA_2ND',
    stock: ['4+'],
    image: {
      src: '/images/product-wide.png',
      alt: 'product',
    },
    slug: 'iphone-14-pro-max-mq9q3hxa-is-mq9q3hxa-is',
    price: {
      currency: 'EUR',
      value: 10.75,
      retail: 15.0,
      sale: 12.0,
      markup: 5,
    },
    equipment: 10,
    marks: [
      {
        text: 'Coming',
        color: 'olive',
      },
    ],
    warranty: 'Lifetime',
    quantity: {
      ordered: 1,
      accepted: 2,
    },
    measure: 'units',
    reserved: new Date(),
    status: ProductDeliveryStatuses.ACCEPTED,
    arrivalDate: new Date(),
    line: 2,
  },
  {
    id: 2,
    article: 'CKG939404030',
    title:
      'Active USB-C > HDMI 2.0 cable - adapter AXAGON RVC-HI2C for connecting a HDMI monitor/TV/projector to a notebook or mobile phone using USB type C connector.',
    sku: 'MVFH2RWA_2ND',
    stock: ['4+', '10+ (Jun 12)'],
    image: {
      src: '/images/product-wide-1.png',
      alt: 'product',
    },
    slug: 'iphone-14-pro-max-mq9q3hxa-is-mq9q3hxa-is',
    price: {
      currency: 'EUR',
      value: 13.75,
      markup: 5,
    },
    equipment: 10,
    marks: [
      {
        text: 'HOT',
        color: 'red',
      },
    ],
    warranty: '24',
    quantity: {
      ordered: 1,
      accepted: 2,
    },
    measure: 'units',
    reserved: false,
    status: ProductDeliveryStatuses.ACCEPTED,
    arrivalDate: new Date(),
    line: 3,
  },
  {
    id: 3,
    article: 'CKG939404030',
    title:
      'Aluminum high-speed USB-C 3.2 Gen 1 memory card reader. 3 slots, UHS-II.',
    sku: 'MVFH2RWA_2ND',
    stock: ['Call'],
    image: {
      src: '/images/product-wide-2.png',
      alt: 'product',
    },
    slug: 'iphone-14-pro-max-mq9q3hxa-is-mq9q3hxa-is',
    price: {
      currency: 'EUR',
      value: 10.75,
      retail: 13,
      markup: 5,
    },
    equipment: 10,
    marks: [
      {
        text: 'Super price',
        color: 'purple',
      },
    ],
    warranty: '24',
    quantity: {
      ordered: 1,
      accepted: 2,
    },
    measure: 'units',
    reserved: new Date(),
    status: ProductDeliveryStatuses.ACCEPTED,
    arrivalDate: new Date(),
    line: 2,
  },
  {
    id: 4,
    article: 'CKG939404030',
    title:
      'Aluminum high-speed USB-C 3.2 Gen 1 memory card reader. 3 slots, UHS-II.',
    sku: 'MVFH2RWA_2ND',
    stock: ['Call'],
    image: {
      src: '/images/product-wide-2.png',
      alt: 'product',
    },
    slug: 'iphone-14-pro-max-mq9q3hxa-is-mq9q3hxa-is',
    price: {
      currency: 'EUR',
      value: 10.75,
      retail: 15.0,
      sale: 12.0,
      markup: 5,
    },
    equipment: 10,
    marks: [
      {
        text: 'HOT',
        color: 'red',
      },
    ],
    warranty: '24',
    quantity: {
      ordered: 1,
      accepted: 2,
    },
    measure: 'boxes',
    reserved: false,
    status: ProductDeliveryStatuses.ACCEPTED,
    arrivalDate: new Date(),
    line: 2,
  },
  {
    id: 5,
    article: 'CKG939404030',
    title:
      'Aluminum high-speed USB-C 3.2 Gen 1 memory card reader. 3 slots, UHS-II.',
    sku: 'MVFH2RWA_2ND',
    stock: ['Call'],
    image: {
      src: '/images/product-wide-2.png',
      alt: 'product',
    },
    slug: 'iphone-14-pro-max-mq9q3hxa-is-mq9q3hxa-is',
    price: {
      currency: 'EUR',
      value: 10.75,
      retail: 15.0,
      sale: 12.0,
      markup: 5,
    },
    equipment: 10,
    marks: [
      {
        text: 'Super price',
        color: 'purple',
      },
    ],
    warranty: '24',
    quantity: {
      ordered: 1,
      accepted: 2,
    },
    measure: 'boxes',
    reserved: false,
    status: ProductDeliveryStatuses.ACCEPTED,
    arrivalDate: new Date(),
    line: 2,
  },
  {
    id: 6,
    article: 'CKG939404030',
    title:
      'Aluminum high-speed USB-C 3.2 Gen 1 memory card reader. 3 slots, UHS-II.',
    sku: 'MVFH2RWA_2ND',
    stock: ['Call'],
    image: {
      src: '/images/product-wide-2.png',
      alt: 'product',
    },
    slug: 'iphone-14-pro-max-mq9q3hxa-is-mq9q3hxa-is',
    price: {
      currency: 'EUR',
      value: 10.75,
      retail: 13.0,
      sale: 12.0,
      markup: 5,
    },
    equipment: 10,
    marks: [
      {
        text: 'HOT',
        color: 'red',
      },
    ],
    warranty: '24',
    quantity: {
      ordered: 1,
      accepted: 2,
    },
    measure: 'units',
    reserved: false,
    status: ProductDeliveryStatuses.ACCEPTED,
    arrivalDate: new Date(),
    line: 2,
  },
  {
    id: 7,
    article: 'CKG939404030',
    title:
      'A modern USB-C > DisplayPort active adapter AXAGON RVC-DP for connecting a DisplayPort monitor/TV/projector to a notebook or mobile phone using USB type C connector. DisplayPort monitor/TV/projector to a notebook or mobile phone using USB type C connector DisplayPort monitor/TV/projector to a notebook or mobile phone using USB type C connector',
    sku: 'MVFH2RWA_2ND',
    stock: ['4+'],
    image: {
      src: '/images/product-wide.png',
      alt: 'product',
    },
    slug: 'iphone-14-pro-max-mq9q3hxa-is-mq9q3hxa-is',
    price: {
      currency: 'EUR',
      value: 10.75,
      retail: 15.0,
      markup: 5,
    },
    equipment: 10,
    marks: [
      {
        text: 'Very special',
        color: 'red',
      },
    ],
    warranty: 'Lifetime',
    quantity: {
      ordered: 1,
      accepted: 2,
    },
    measure: 'units',
    reserved: false,
    status: ProductDeliveryStatuses.IN_TRANSIT,
    arrivalDate: new Date(),
    line: 2,
  },
  {
    id: 8,
    article: 'CKG939404030',
    title:
      'Active USB-C > HDMI 2.0 cable - adapter AXAGON RVC-HI2C for connecting a HDMI monitor/TV/projector to a notebook or mobile phone using USB type C connector.',
    sku: 'MVFH2RWA_2ND',
    stock: ['4+', '10+ (Jun 12)'],
    image: {
      src: '/images/product-wide-1.png',
      alt: 'product',
    },
    slug: 'iphone-14-pro-max-mq9q3hxa-is-mq9q3hxa-is',
    price: {
      currency: 'EUR',
      value: 13.75,
      markup: 5,
    },
    equipment: 10,
    marks: [
      {
        text: 'Fresh arrival',
        color: 'olive',
      },
    ],
    warranty: '24',
    quantity: {
      ordered: 1,
      accepted: 2,
    },
    measure: 'units',
    reserved: false,
    status: ProductDeliveryStatuses.ACCEPTED,
    arrivalDate: new Date(),
  },
  {
    id: 9,
    article: 'CKG939404030',
    title:
      'Aluminum high-speed USB-C 3.2 Gen 1 memory card reader. 3 slots, UHS-II.',
    sku: 'HUE-S1B',
    stock: ['4+', '10+ (Jun 12)'],
    image: {
      src: '/images/product-wide.png',
      alt: 'product',
    },
    slug: 'iphone-14-pro-max-mq9q3hxa-is-mq9q3hxa-is',
    price: {
      currency: 'EUR',
      value: 10.75,
      markup: 0,
    },
    equipment: 10,
    marks: [
      {
        text: 'Fresh arrival',
        color: 'olive',
      },
    ],
    warranty: '24',
    quantity: {
      ordered: 1,
      accepted: 2,
    },
    measure: 'units',
    reserved: false,
    status: ProductDeliveryStatuses.ACCEPTED,
    arrivalDate: new Date(),
    line: 2,
  },
  {
    id: 10,
    article: 'CKG939404030',
    title:
      'Active USB-C > HDMI 2.0 cable - adapter AXAGON RVC-HI2C for connecting a HDMI monitor/TV/projector to a notebook or mobile phone using USB type C connector.',
    sku: 'RVC-DP',
    stock: ['4+', '10+ (Jun 12)'],
    image: {
      src: '/images/product-wide-1.png',
      alt: 'product',
    },
    slug: 'iphone-14-pro-max-mq9q3hxa-is-mq9q3hxa-is',
    price: {
      currency: 'EUR',
      value: 10.75,
      markup: 0,
    },
    equipment: 10,
    marks: [
      {
        text: 'Fresh arrival',
        color: 'olive',
      },
    ],
    warranty: '24',
    quantity: {
      ordered: 1,
      accepted: 2,
    },
    measure: 'units',
    reserved: false,
    status: ProductDeliveryStatuses.ACCEPTED,
    arrivalDate: new Date(),
    line: 2,
  },
  {
    id: 11,
    article: 'CKG939404030',
    title:
      'A modern USB-C > DisplayPort active adapter AXAGON RVC-DP for connecting a DisplayPort monitor/TV/projector to a notebook or mobile phone using USB type C connector.',
    sku: '21CB006GPB4JFLG84J34',
    stock: ['4+', '10+ (Jun 12)'],
    image: {
      src: '/images/product-wide-2.png',
      alt: 'product',
    },
    slug: 'iphone-14-pro-max-mq9q3hxa-is-mq9q3hxa-is',
    price: {
      currency: 'EUR',
      value: 10.75,
      markup: 0,
    },
    equipment: 10,
    marks: [
      {
        text: 'Fresh arrival',
        color: 'olive',
      },
    ],
    warranty: '24',
    quantity: {
      ordered: 1,
      accepted: 2,
    },
    measure: 'units',
    reserved: false,
    status: ProductDeliveryStatuses.ACCEPTED,
    arrivalDate: new Date(),
    line: 2,
  },
  {
    id: 12,
    article: 'CKG939404030',
    title: 'Axagon Foldable pocket USB-A contact Smart / ID card reader.',
    sku: '21CB006GPB4JFLG84J34',
    stock: ['4+', '10+ (Jun 12)'],
    image: {
      src: '/images/product-wide.png',
      alt: 'product',
    },
    slug: 'iphone-14-pro-max-mq9q3hxa-is-mq9q3hxa-is',
    price: {
      currency: 'EUR',
      value: 10.75,
      markup: 0,
    },
    equipment: 10,
    marks: [
      {
        text: 'SALE',
        color: 'red',
      },
    ],
    warranty: '24',
    quantity: {
      ordered: 1,
      accepted: 2,
    },
    measure: 'units',
    reserved: false,
    status: ProductDeliveryStatuses.ACCEPTED,
    arrivalDate: new Date(),
    line: 2,
  },
  {
    id: 13,
    article: 'CKG939404030',
    title:
      'Aluminum high-speed USB-C 3.2 Gen 1 memory card reader. 3 slots, UHS-II.',
    sku: 'HUE-S1B',
    stock: ['4+', '10+ (Jun 12)'],
    image: {
      src: '/images/product-wide-1.png',
      alt: 'product',
    },
    slug: 'iphone-14-pro-max-mq9q3hxa-is-mq9q3hxa-is',
    price: {
      currency: 'EUR',
      value: 10.75,
      markup: 0,
    },
    equipment: 10,
    marks: [
      {
        text: 'SALE',
        color: 'red',
      },
    ],
    warranty: '24',
    quantity: {
      ordered: 1,
      accepted: 2,
    },
    measure: 'units',
    reserved: false,
    status: ProductDeliveryStatuses.ACCEPTED,
    arrivalDate: new Date(),
    line: 2,
  },
  {
    id: 14,
    article: 'CKG939404030',
    title:
      'Active USB-C > HDMI 2.0 cable - adapter AXAGON RVC-HI2C for connecting a HDMI monitor/TV/projector to a notebook or mobile phone using USB type C connector.',
    sku: 'RVC-DP',
    stock: ['4+', '10+ (Jun 12)'],
    image: {
      src: '/images/product-wide-2.png',
      alt: 'product',
    },
    slug: 'iphone-14-pro-max-mq9q3hxa-is-mq9q3hxa-is',
    price: {
      currency: 'EUR',
      value: 10.75,
      markup: 0,
    },
    equipment: 10,
    marks: [
      {
        text: 'SALE',
        color: 'red',
      },
    ],
    warranty: '24',
    quantity: {
      ordered: 1,
      accepted: 2,
    },
    measure: 'units',
    reserved: false,
    status: ProductDeliveryStatuses.ACCEPTED,
    arrivalDate: new Date(),
    line: 2,
  },
  {
    id: 15,
    article: 'CKG939404030',
    title:
      'A modern USB-C > DisplayPort active adapter AXAGON RVC-DP for connecting a DisplayPort monitor/TV/projector to a notebook or mobile phone using USB type C connector.',
    sku: '21CB006GPB4JFLG84J34',
    stock: ['4+', '10+ (Jun 12)'],
    image: {
      src: '/images/product-wide.png',
      alt: 'product',
    },
    slug: 'iphone-14-pro-max-mq9q3hxa-is-mq9q3hxa-is',
    price: {
      currency: 'EUR',
      value: 10.75,
      markup: 0,
    },
    equipment: 10,
    marks: [
      {
        text: 'SALE',
        color: 'red',
      },
    ],
    warranty: '24',
    quantity: {
      ordered: 1,
      accepted: 2,
    },
    measure: 'units',
    reserved: false,
    status: ProductDeliveryStatuses.IN_TRANSIT,
    arrivalDate: new Date(),
    line: 2,
  },
  {
    id: 16,
    article: 'CKG939404030',
    title: 'Axagon Foldable pocket USB-A contact Smart / ID card reader.',
    sku: '21CB006GPB4JFLG84J34',
    stock: ['4+', '10+ (Jun 12)'],
    image: {
      src: '/images/product-wide-1.png',
      alt: 'product',
    },
    slug: 'iphone-14-pro-max-mq9q3hxa-is-mq9q3hxa-is',
    price: {
      currency: 'EUR',
      value: 10.75,
      markup: 0,
    },
    equipment: 10,
    marks: [
      {
        text: 'SALE',
        color: 'red',
      },
    ],
    warranty: '24',
    quantity: {
      ordered: 1,
      accepted: 2,
    },
    measure: 'units',
    reserved: false,
    status: ProductDeliveryStatuses.ACCEPTED,
    arrivalDate: new Date(),
    line: 3,
  },
  {
    id: 17,
    article: 'CKG939404030',
    title:
      'Aluminum high-speed USB-C 3.2 Gen 1 memory card reader. 3 slots, UHS-II.',
    sku: 'HUE-S1B',
    stock: ['4+', '10+ (Jun 12)'],
    image: {
      src: '/images/product-wide-2.png',
      alt: 'product',
    },
    slug: 'iphone-14-pro-max-mq9q3hxa-is-mq9q3hxa-is',
    price: {
      currency: 'EUR',
      value: 10.75,
      markup: 0,
    },
    equipment: 10,
    marks: [
      {
        text: 'SALE',
        color: 'red',
      },
    ],
    warranty: '24',
    quantity: {
      ordered: 1,
      accepted: 2,
    },
    measure: 'units',
    reserved: false,
    status: ProductDeliveryStatuses.ACCEPTED,
    arrivalDate: new Date(),
    line: 2,
  },
  {
    id: 18,
    article: 'CKG939404030',
    title:
      'Active USB-C > HDMI 2.0 cable - adapter AXAGON RVC-HI2C for connecting a HDMI monitor/TV/projector to a notebook or mobile phone using USB type C connector.',
    sku: 'RVC-DP',
    stock: ['4+', '10+ (Jun 12)'],
    image: {
      src: '/images/product-wide-2.png',
      alt: 'product',
    },
    slug: 'iphone-14-pro-max-mq9q3hxa-is-mq9q3hxa-is',
    price: {
      currency: 'EUR',
      value: 10.75,
      markup: 0,
    },
    equipment: 10,
    marks: [
      {
        text: 'SALE',
        color: 'red',
      },
    ],
    warranty: '24',
    quantity: {
      ordered: 1,
      accepted: 2,
    },
    measure: 'units',
    reserved: false,
    status: ProductDeliveryStatuses.ACCEPTED,
    arrivalDate: new Date(),
    line: 2,
  },
  {
    id: 19,
    article: 'CKG939404030',
    title:
      'A modern USB-C > DisplayPort active adapter AXAGON RVC-DP for connecting a DisplayPort monitor/TV/projector to a notebook or mobile phone using USB type C connector.',
    sku: '21CB006GPB4JFLG84J34',
    stock: ['4+', '10+ (Jun 12)'],
    image: {
      src: '/images/product-wide-1.png',
      alt: 'product',
    },
    slug: 'iphone-14-pro-max-mq9q3hxa-is-mq9q3hxa-is',
    price: {
      currency: 'EUR',
      value: 10.75,
      markup: 0,
    },
    equipment: 10,
    marks: [
      {
        text: 'SALE',
        color: 'red',
      },
    ],
    warranty: '24',
    quantity: {
      ordered: 1,
      accepted: 2,
    },
    measure: 'units',
    reserved: false,
    status: ProductDeliveryStatuses.ACCEPTED,
    arrivalDate: new Date(),
    line: 2,
  },
  {
    id: 20,
    article: 'CKG939404030',
    title: 'Axagon Foldable pocket USB-A contact Smart / ID card reader.',
    sku: '21CB006GPB4JFLG84J34',
    stock: ['4+', '10+ (Jun 12)'],
    image: {
      src: '/images/product-wide.png',
      alt: 'product',
    },
    slug: 'iphone-14-pro-max-mq9q3hxa-is-mq9q3hxa-is',
    price: {
      currency: 'EUR',
      value: 10.75,
      markup: 0,
    },
    equipment: 10,
    marks: [
      {
        text: 'SALE',
        color: 'red',
      },
    ],
    warranty: '24',
    quantity: {
      ordered: 1,
      accepted: 2,
    },
    measure: 'units',
    reserved: false,
    status: ProductDeliveryStatuses.ACCEPTED,
    arrivalDate: new Date(),
    line: 2,
  },
];

export default products;
