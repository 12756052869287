import { type ProductFilters } from '~/domains/product';

interface ProductFilterQuery {
  brand_id?: number[];
  pt_id?: number[];
  stock?: string[];
  nid?: number[];
  subnid?: number[];
  price_min?: number;
  price_max?: number;
  ka_id?: Array<string | undefined>;
  kav_id?: string[];
  search?: string;
  at?: string[];
}

export default function productFilterDeserializer(
  data: ProductFilters,
): ProductFilterQuery {
  // TODO: refactor
  const transformedObject = {
    keyAttributes: {},
  };

  for (const key in data) {
    if (key.startsWith('keyAttributes[') && data[key][0] !== false) {
      const newKey = key.match(/\[(.*?)\]/)[1];
      if (!Array.isArray(data[key])) {
        transformedObject.keyAttributes[data[key]] = newKey;
      } else {
        data[key].forEach((value) => {
          transformedObject.keyAttributes[value] = newKey;
        });
      }
    }
  }
  // END TODO

  return {
    brand_id: data.brands?.map((b) => +b),
    pt_id: data.productTypes?.map((t) => +t),
    stock: data.stock,
    nid: data.nodes?.map((c) => +c),
    subnid: data.subnodes?.map((c) => +c),
    price_min: data?.price?.[0],
    price_max: data?.price?.[1],
    ka_id: Object.values(transformedObject.keyAttributes)?.map(
      (parentId) => parentId as string,
    ),
    kav_id: Object.keys(transformedObject.keyAttributes)?.map((id) => id),
    search: data.search === '' ? undefined : data?.search,
    at: data.actions ?? [],
    ...(data['only-cto'] !== false && {
      various: 'cto',
      value: true,
    }),
  };
}
