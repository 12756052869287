import {
  asbisDark,
  asbisLight,
  asbcDark,
  asbcLight,
  breezyDark,
  breezyLight,
} from './images';
import { type Logos } from '~/domains/core';

export const logoConfig: Logos = {
  ASBIS: {
    dark: asbisDark,
    light: asbisLight,
  },
  ASBC: {
    dark: asbcDark,
    light: asbcLight,
  },
  BREEZY: {
    dark: breezyDark,
    light: breezyLight,
  },
};
