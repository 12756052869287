<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5155 3.04404C11.8059 2.83949 12.1938 2.83949 12.4843 3.04404C12.7684 3.2441 13.0319 3.46776 13.2717 3.6713C13.3503 3.73803 13.4264 3.80259 13.4998 3.86345C13.8102 4.1208 14.123 4.35726 14.521 4.56611C15.3097 4.98005 16.5197 5.33008 18.8021 5.34915C19.2525 5.35292 19.6499 5.71601 19.6499 6.19997V12C19.6499 13.4417 18.8187 15.4419 17.5381 17.1779C16.2473 18.9276 14.396 20.552 12.211 21.117C12.0725 21.1528 11.9272 21.1528 11.7887 21.117C9.6037 20.552 7.7524 18.9276 6.46165 17.1779C5.18101 15.4419 4.34985 13.4417 4.34985 12V6.19997C4.34985 5.71601 4.74717 5.35292 5.1976 5.34915C7.47997 5.33008 8.69 4.98005 9.47874 4.56611C9.87669 4.35726 10.1895 4.1208 10.4999 3.86345C10.5733 3.80259 10.6494 3.73803 10.728 3.6713C10.9678 3.46776 11.2313 3.2441 11.5155 3.04404ZM11.9999 4.30488C11.8698 4.40786 11.7431 4.51536 11.6065 4.63126C11.5194 4.70518 11.4282 4.78251 11.3296 4.86426C10.988 5.14748 10.5957 5.44807 10.0829 5.71722C9.11476 6.22528 7.79378 6.58195 5.64985 6.64125V12C5.64985 13.0481 6.30659 14.7778 7.5078 16.4061C8.67349 17.9863 10.2543 19.3366 11.9999 19.8275C13.7454 19.3366 15.3262 17.9863 16.4919 16.4061C17.6931 14.7778 18.3499 13.0481 18.3499 12V6.64125C16.2059 6.58195 14.8849 6.22528 13.9169 5.71722C13.404 5.44807 13.0117 5.14748 12.6701 4.86426C12.5715 4.78251 12.4804 4.70518 12.3932 4.63126C12.2566 4.51536 12.1299 4.40786 11.9999 4.30488Z"
      fill="currentColor"
    />
  </svg>
</template>
