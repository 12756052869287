<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0001 3.35001C12.3591 3.35001 12.6501 3.64102 12.6501 4.00001V4.85001H18.0001C18.2463 4.85001 18.4714 4.98911 18.5815 5.20932L21.5815 11.2093C21.6266 11.2996 21.6501 11.3991 21.6501 11.5C21.6501 12.468 21.2655 13.3964 20.581 14.0809C19.8965 14.7655 18.9681 15.15 18.0001 15.15C17.0321 15.15 16.1037 14.7655 15.4192 14.0809C14.7347 13.3964 14.3501 12.468 14.3501 11.5C14.3501 11.3991 14.3736 11.2996 14.4187 11.2093L16.9484 6.15001H12.6501V18.85H17.0001C17.3591 18.85 17.6501 19.141 17.6501 19.5C17.6501 19.859 17.3591 20.15 17.0001 20.15H12.0001H7.0001C6.64111 20.15 6.3501 19.859 6.3501 19.5C6.3501 19.141 6.64111 18.85 7.0001 18.85H11.3501V6.15001H7.05182L9.58148 11.2093C9.6266 11.2996 9.6501 11.3991 9.6501 11.5C9.6501 12.468 9.26555 13.3964 8.58104 14.0809C7.89653 14.7655 6.96814 15.15 6.0001 15.15C5.03206 15.15 4.10367 14.7655 3.41916 14.0809C2.73465 13.3964 2.3501 12.468 2.3501 11.5C2.3501 11.3991 2.37359 11.2996 2.41872 11.2093L5.41872 5.20932C5.52883 4.98911 5.7539 4.85001 6.0001 4.85001H11.3501V4.00001C11.3501 3.64102 11.6411 3.35001 12.0001 3.35001ZM6.0001 6.95345L3.65454 11.6446C3.68968 12.2151 3.93188 12.7552 4.3384 13.1617C4.77911 13.6024 5.37684 13.85 6.0001 13.85C6.62336 13.85 7.22109 13.6024 7.6618 13.1617C8.06832 12.7552 8.31052 12.2151 8.34565 11.6446L6.0001 6.95345ZM18.0001 6.95345L15.6545 11.6446C15.6897 12.2151 15.9319 12.7552 16.3384 13.1617C16.7791 13.6024 17.3768 13.85 18.0001 13.85C18.6234 13.85 19.2211 13.6024 19.6618 13.1617C20.0683 12.7552 20.3105 12.2151 20.3457 11.6446L18.0001 6.95345Z"
      fill="currentColor"
    />
  </svg>
</template>
