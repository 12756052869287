import { type WarehouseResponse } from '~/domains/order';
import type { ProductStockWarehouse } from '../types.d';

export default function productWarehouseSerializer(
  data?: WarehouseResponse,
): ProductStockWarehouse | undefined {
  if (data === undefined) return undefined;

  return {
    id: +data.id,
    name: data.name,
  };
}
