import { PATH_DICTIONARY } from '~/domains/auth';
import type { RouteLocationNormalized } from 'vue-router';

export default (to: RouteLocationNormalized): boolean | string | undefined => {
  const isNeedToChangePassword =
    localStorage.getItem('isNeedToChangePassword') === 'true';

  if (isNeedToChangePassword && to.path !== PATH_DICTIONARY.changePassword) {
    return PATH_DICTIONARY.changePassword;
  }

  if (isNeedToChangePassword && to.path === PATH_DICTIONARY.changePassword) {
    return true;
  }
};
