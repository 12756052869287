import { type BasketStatistics } from '../entities';
import { type BasketRepository } from '../ports';

interface Dependencies {
  storage: {
    updateBasketStats: (basketLines: BasketStatistics) => void;
  };
  basketRepository: BasketRepository;
}

export default async function getBasketStatsUseCase({
  storage,
  basketRepository,
}: Dependencies): Promise<void> {
  try {
    const response = await basketRepository.getBasketStatistics();

    storage.updateBasketStats(response);
  } catch (e) {
    console.error(e);
  }
}
