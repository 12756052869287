import { type ProductCertificateResponse } from '~/domains/product';
import type ProductCertificate from '~/domains/product/entities/ProductCertificate';

export default function productCertificateSerializer(
  data: ProductCertificateResponse,
): ProductCertificate {
  return {
    type: data?.content_type_code ?? null,
    filename: data.name,
    url: data.url,
  };
}
