<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.9996 4.97029C21.9995 4.97062 21.9995 4.97094 21.9994 4.97126L20.056 19.0384L20.0539 19.0527C19.9681 19.586 19.7629 20.0202 19.4476 20.3424C19.1334 20.6635 18.751 20.8312 18.3809 20.9027C17.6711 21.0397 16.9374 20.8394 16.5065 20.5995C16.486 20.5881 16.4661 20.5755 16.4469 20.5619L11.1018 16.7741C11.0624 16.7462 11.0263 16.714 10.994 16.6781C10.4674 16.0917 10.3388 15.4436 10.4214 14.8782C10.4997 14.3428 10.7618 13.9036 10.994 13.645L14.116 10.1685L9.43077 13.5225C8.05374 14.5419 6.55244 14.3874 5.90631 14.1476L2.99076 13.0654C2.64285 12.9362 2.21608 12.6852 2.12388 12.1892C2.03694 11.7217 2.31449 11.3574 2.48768 11.1727C2.86781 10.7672 3.50188 10.4272 3.9225 10.2397C3.92311 10.2394 3.92371 10.2391 3.92432 10.2389L19.4703 3.20592L19.4797 3.20165L19.4798 3.20173C20.086 2.93901 20.7528 2.98642 21.2627 3.31414C21.7885 3.65202 22.0992 4.26117 21.9996 4.97029ZM20.0008 4.39279C20.2359 4.29236 20.4404 4.331 20.5599 4.40778C20.664 4.47469 20.7405 4.58898 20.7122 4.78969L20.7119 4.79152L18.7693 18.8528C18.7177 19.1669 18.6125 19.3371 18.5185 19.4332C18.4228 19.531 18.2964 19.595 18.1344 19.6263C17.7941 19.692 17.3896 19.593 17.1658 19.4781L11.9184 15.7595C11.7066 15.4998 11.6789 15.2638 11.7078 15.0662C11.7429 14.826 11.8695 14.6158 11.9612 14.5136L16.8204 9.10247C16.8437 9.07661 16.8648 9.04893 16.8836 9.01969C17.1329 8.63187 17.1611 8.10912 16.8484 7.72926C16.6902 7.53711 16.4581 7.40759 16.1898 7.38697C15.9262 7.36672 15.6832 7.45451 15.4844 7.59025L15.4725 7.59853L8.66966 12.4686L8.66961 12.4685L8.66057 12.4752C7.70647 13.1835 6.68367 13.0495 6.3587 12.9288L3.61019 11.9086C3.83288 11.7387 4.15604 11.5588 4.45328 11.4264L4.45328 11.4264L4.45671 11.4249L20.0008 4.39279ZM3.31592 11.786C3.31624 11.7859 3.31927 11.7879 3.32406 11.7921C3.318 11.7882 3.31561 11.7861 3.31592 11.786ZM3.38956 12.1176C3.38646 12.1243 3.38412 12.1277 3.38372 12.1278C3.38332 12.1278 3.38487 12.1245 3.38956 12.1176Z"
      fill="#2BACEF"
    />
  </svg>
</template>
