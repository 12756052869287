<template>
  <UiListItem
    :value="value"
    :class="{ 'bg-blue-50': activeMode === 'color' && isActive }"
    @click="emit('click', value)"
  >
    <template #prepend>
      <slot name="prepend" />
    </template>

    <UiListItemTitle
      :class="{ 'text-blue-500': activeMode === 'color' && isActive }"
    >
      <slot />
    </UiListItemTitle>
    <template v-if="isActive && activeMode === 'default'" #append>
      <UiIcon icon="$check" color="blue-700" size="20" />
    </template>
  </UiListItem>
</template>

<script setup lang="ts">
import { UiListItem, UiListItemTitle, UiIcon } from '~/ui';

type ActiveMode = 'default' | 'color';

withDefaults(
  defineProps<{
    value: string;
    isActive?: boolean;
    activeMode?: ActiveMode;
  }>(),
  {
    isActive: false,
    activeMode: 'default',
  },
);

const emit = defineEmits<(e: 'click', value: string) => void>();
</script>
