import { type CompareRepository } from '../ports';
import { type CompareGroup } from '~/domains/compare';

interface Dependencies {
  notifier?: {
    notifyError: () => void;
  };
  storage?: {
    updateCompare: (groups: CompareGroup[]) => void;
  };
  compareRepository: CompareRepository;
}

export async function getCompareUseCase({
  notifier,
  storage,
  compareRepository,
}: Dependencies): Promise<void> {
  try {
    const result = await compareRepository.getProducts();
    storage?.updateCompare(result);
  } catch (e) {
    notifier?.notifyError();
  }
}
