<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.3501 7.99998C6.3501 4.87957 8.87969 2.34998 12.0001 2.34998C15.1205 2.34998 17.6501 4.87957 17.6501 7.99998V11C17.6501 14.1204 15.1205 16.65 12.0001 16.65C8.87969 16.65 6.3501 14.1204 6.3501 11V7.99998ZM12.0001 3.64998C9.59766 3.64998 7.6501 5.59754 7.6501 7.99998V11C7.6501 13.4024 9.59766 15.35 12.0001 15.35C14.4025 15.35 16.3501 13.4024 16.3501 11V7.99998C16.3501 5.59754 14.4025 3.64998 12.0001 3.64998ZM7.48 17.61C7.69539 17.3228 8.10281 17.2646 8.39 17.48L11.79 20.03C11.9144 20.1233 12.0856 20.1233 12.21 20.03L15.61 17.48C15.8972 17.2646 16.3046 17.3228 16.52 17.61C16.7354 17.8972 16.6772 18.3046 16.39 18.52L12.99 21.07C12.4033 21.51 11.5967 21.51 11.01 21.07L7.61 18.52C7.32281 18.3046 7.26461 17.8972 7.48 17.61ZM12.65 6C12.65 5.64102 12.359 5.35 12 5.35C11.641 5.35 11.35 5.64102 11.35 6V8C11.35 8.35898 11.641 8.65 12 8.65C12.359 8.65 12.65 8.35898 12.65 8V6Z"
      fill="currentColor"
    />
  </svg>
</template>
