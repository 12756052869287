<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9998 6.14998C13.7882 6.14995 15.6729 7.11894 17.3385 8.36736C18.9866 9.60259 20.326 11.0397 21.009 11.8269C21.0975 11.9289 21.0975 12.0709 21.009 12.1729C20.3261 12.9601 18.9866 14.3972 17.3386 15.6325C15.673 16.881 13.7882 17.85 11.9999 17.85C10.2115 17.85 8.32679 16.881 6.66118 15.6326C5.01315 14.3973 3.67365 12.9602 2.99065 12.1731C2.90214 12.071 2.90214 11.929 2.99065 11.827C3.67365 11.0399 5.01315 9.60271 6.66117 8.36746C8.32679 7.11901 10.2115 6.15 11.9998 6.14998ZM21.9909 10.9749C21.2783 10.1536 19.8702 8.64025 18.1182 7.32712C16.3839 6.02719 14.2164 4.84995 11.9998 4.84998C9.78333 4.85 7.61582 6.02727 5.88148 7.32722C4.12955 8.64037 2.72139 10.1537 2.00875 10.975C1.49611 11.5659 1.49611 12.4342 2.00875 13.025C2.7214 13.8464 4.12956 15.3597 5.88149 16.6728C7.61584 17.9727 9.78336 19.15 11.9999 19.15C14.2164 19.15 16.3839 17.9727 18.1183 16.6727C19.8702 15.3596 21.2783 13.8462 21.991 13.0249C22.5036 12.434 22.5036 11.5657 21.9909 10.9749ZM9.64991 12C9.64991 10.7021 10.702 9.64998 11.9999 9.64998C13.2978 9.64998 14.3499 10.7021 14.3499 12C14.3499 13.2979 13.2978 14.35 11.9999 14.35C10.702 14.35 9.64991 13.2979 9.64991 12ZM11.9999 8.34998C9.98407 8.34998 8.34991 9.98414 8.34991 12C8.34991 14.0158 9.98407 15.65 11.9999 15.65C14.0158 15.65 15.6499 14.0158 15.6499 12C15.6499 9.98414 14.0158 8.34998 11.9999 8.34998Z"
      fill="currentColor"
    />
  </svg>
</template>
