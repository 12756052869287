import { type UserPreferencesResponse } from '../types.d';
import { type UserPreferences } from '~/domains/user';
import userPreferencesSortingSerializer from '~/domains/user/serializers/user-preferences-sorting.serializer.ts';
import userPreferencesTableStateSerializer from '~/domains/user/serializers/user-preferences-table-state.serializer.ts';

export default function userPreferencesSerializer(
  data: UserPreferencesResponse,
): UserPreferences {
  return {
    displayView: data.preferences?.displayView,
    stockFilter: data.preferences?.stockFilter,
    sorting: userPreferencesSortingSerializer(data.preferences?.sorting),
    tableState: userPreferencesTableStateSerializer(
      data.preferences?.tableState,
    ),
  };
}
