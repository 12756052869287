import { formatPrice } from './formatPrice';

export default class Price {
  public readonly amount: number;

  constructor(amount: number | string) {
    this.amount = Price.parse(amount);
  }

  private static parse(value: number | string): number {
    const valueAsNumber = Number(value);
    return +valueAsNumber.toFixed(2);
  }

  public toString(): string {
    return formatPrice(this.amount.toString()) ?? '';
  }

  public static sum(...args: Price[]): Price {
    const prices: Price[] = [...args];

    const sum: number = prices.reduce((currentValue, nextValue) => {
      return (currentValue += nextValue.amount);
    }, 0);

    return new Price(sum);
  }

  public deserialize(): string {
    return this.amount.toString().split(' ').join('');
  }

  public toFixed(fixed = 2): string {
    return Number(this.deserialize()).toFixed(fixed);
  }
}
