import { storeToRefs } from 'pinia';
import categoryService from '../category.service';
import { useCategoriesTreeStore } from '../stores';
import { computed, type ComputedRef, type Ref } from 'vue';
import type { Category, CategoryTree } from '../types';

export default function useCategoriesTree(): {
  fetchCategoriesTree: () => Promise<void>;
  categoriesTree: Ref<CategoryTree | undefined>;
  macCTOProductTypes: ComputedRef<Category[] | undefined>;
} {
  const { updateCategoriesTree } = useCategoriesTreeStore();
  const { categoriesTree } = storeToRefs(useCategoriesTreeStore());

  const macCTOProductTypes = computed(() => {
    return categoriesTree.value?.tree.children?.find(
      (category) => category.additional?.[0].type === 'ACTO',
    )?.additional[0]?.children;
  });

  const fetchCategoriesTree = async (): Promise<void> => {
    const categoriesTree = await categoryService.getCategoriesTree();

    updateCategoriesTree(categoriesTree);
  };

  return {
    fetchCategoriesTree,
    categoriesTree,
    macCTOProductTypes,
  };
}
