import { type SocialRepository } from '../../ports';
import { getSocial } from '~/domains/core';

const socialLocalRepository: SocialRepository = {
  async getSocial() {
    return getSocial();
  },
};

export default socialLocalRepository;
