<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.5001 1.34998C7.03654 1.34998 5.8501 2.53642 5.8501 3.99998V6.34998H4.5001C3.03654 6.34998 1.8501 7.53642 1.8501 8.99998V15C1.8501 16.4635 3.03654 17.65 4.5001 17.65H5.8501V20C5.8501 21.4635 7.03654 22.65 8.5001 22.65H15.5001C16.9637 22.65 18.1501 21.4635 18.1501 20V17.65H19.5001C20.9637 17.65 22.1501 16.4635 22.1501 15V8.99998C22.1501 7.53642 20.9637 6.34998 19.5001 6.34998H18.1501V3.99998C18.1501 2.53642 16.9637 1.34998 15.5001 1.34998H8.5001ZM5.8501 14V16.35H4.5001C3.75451 16.35 3.1501 15.7456 3.1501 15V8.99998C3.1501 8.25439 3.75451 7.64998 4.5001 7.64998H19.5001C20.2457 7.64998 20.8501 8.25439 20.8501 8.99998V15C20.8501 15.7456 20.2457 16.35 19.5001 16.35H18.1501V14C18.1501 13.0887 17.4114 12.35 16.5001 12.35H7.5001C6.58883 12.35 5.8501 13.0887 5.8501 14ZM16.8501 6.34998V3.99998C16.8501 3.25439 16.2457 2.64998 15.5001 2.64998H8.5001C7.75451 2.64998 7.1501 3.25439 7.1501 3.99998V6.34998H16.8501ZM7.5001 13.65C7.3068 13.65 7.1501 13.8067 7.1501 14V20C7.1501 20.7456 7.75451 21.35 8.5001 21.35H15.5001C16.2457 21.35 16.8501 20.7456 16.8501 20V14C16.8501 13.8067 16.6934 13.65 16.5001 13.65H7.5001ZM8.5001 14.85C8.14111 14.85 7.8501 15.141 7.8501 15.5C7.8501 15.859 8.14111 16.15 8.5001 16.15H11.5001C11.8591 16.15 12.1501 15.859 12.1501 15.5C12.1501 15.141 11.8591 14.85 11.5001 14.85H8.5001ZM8.5001 16.85C8.14111 16.85 7.8501 17.141 7.8501 17.5C7.8501 17.859 8.14111 18.15 8.5001 18.15H14.5001C14.8591 18.15 15.1501 17.859 15.1501 17.5C15.1501 17.141 14.8591 16.85 14.5001 16.85H8.5001ZM8.5001 18.85C8.14111 18.85 7.8501 19.141 7.8501 19.5C7.8501 19.859 8.14111 20.15 8.5001 20.15H14.5001C14.8591 20.15 15.1501 19.859 15.1501 19.5C15.1501 19.141 14.8591 18.85 14.5001 18.85H8.5001Z"
      fill="currentColor"
    />
  </svg>
</template>
