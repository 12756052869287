<template>
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 994233">
      <g id="004-instagram">
        <path
          id="Shape"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.875 0.5H15.125C18.9214 0.5 22 3.57862 22 7.375V15.625C22 19.4214 18.9214 22.5 15.125 22.5H6.875C3.07862 22.5 0 19.4214 0 15.625V7.375C0 3.57862 3.07862 0.5 6.875 0.5ZM15.125 20.4376C17.7787 20.4376 19.9375 18.2789 19.9375 15.6251V7.3751C19.9375 4.72134 17.7787 2.56258 15.125 2.56258H6.875C4.22125 2.56258 2.0625 4.72134 2.0625 7.3751V15.6251C2.0625 18.2789 4.22125 20.4376 6.875 20.4376H15.125Z"
          fill="currentColor"
        />
        <path
          id="Shape_2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.5 11.5C5.5 8.46264 7.96263 6 11 6C14.0374 6 16.5 8.46264 16.5 11.5C16.5 14.5374 14.0374 17.0001 11 17.0001C7.96263 17.0001 5.5 14.5374 5.5 11.5ZM7.5625 11.5002C7.5625 13.3949 9.10525 14.9377 11 14.9377C12.8948 14.9377 14.4375 13.3949 14.4375 11.5002C14.4375 9.60405 12.8948 8.06267 11 8.06267C9.10525 8.06267 7.5625 9.60405 7.5625 11.5002Z"
          fill="currentColor"
        />
        <ellipse
          id="Oval"
          cx="16.9125"
          cy="5.58737"
          rx="0.732849"
          ry="0.732878"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
</template>
