import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { type Product, type ProductInfo } from '~/domains/product';
import { type CompareGroup } from '~/domains/compare';

export default defineStore('compare', () => {
  const items = ref<CompareGroup[]>([]);
  const products = ref<ProductInfo[]>([]);

  const count = computed(() => products.value.length.toString());
  const isEmpty = computed(() => products.value.length === 0);
  const loading = ref(false);
  const isActiveHidingOfMatches = ref(true);

  function updateCompare(groups: CompareGroup[]): void {
    items.value = arrangeAsMovedItems(groups);
    products.value = groups.reduce<ProductInfo[]>(
      (products, group) => [...products, ...group.products],
      [],
    );
  }

  const updateItemsWithNewArranging = (groups: CompareGroup[]): void => {
    items.value = [...groups];
  };

  const arrangeAsMovedItems = (newItems: CompareGroup[]): CompareGroup[] => {
    const originalItems = [...items.value];

    return newItems.map((newItem) => {
      const originalGroup = originalItems.find(
        (group) => group.type.id === newItem.type.id,
      );

      if (originalGroup == null) {
        return newItem;
      }

      const reorderedProducts = newItem.products.sort((a, b) => {
        const indexA = originalGroup.products.findIndex(
          (p) => p.product.id === a.product.id,
        );
        const indexB = originalGroup.products.findIndex(
          (p) => p.product.id === b.product.id,
        );

        return indexA - indexB;
      });

      return {
        type: newItem.type,
        products: reorderedProducts,
      };
    });
  };

  function clearCompare(): void {
    items.value = [];
    products.value = [];
  }

  function isProductInCompare(product: Product): boolean {
    return products.value.some((item) => item.product.id === product.id);
  }

  return {
    items,
    count,
    isEmpty,
    isProductInCompare,
    updateCompare,
    clearCompare,
    loading,
    isActiveHidingOfMatches,
    updateItemsWithNewArranging,
  };
});
