<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M19.7528 19.75H9.87308L9.76123 19.7473C4.49561 19.6191 0.25 15.2948 0.25 10C0.25 4.62328 4.62384 0.25 10.0014 0.25C15.379 0.25 19.7528 4.62328 19.7528 10V19.75Z"
      fill="currentColor"
      stroke="#58627C"
      stroke-width="0.5"
    />
  </svg>
</template>
