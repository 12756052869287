import { computed, type ComputedRef } from 'vue';
import { useRoute } from '~/infrastructure';

interface UseAdminReturn {
  isTranslateOff: ComputedRef<boolean>;
}

export default function useAdmin(): UseAdminReturn {
  const route = useRoute();
  const isTranslateOff = computed(
    () => route.query['translate-off'] === 'true',
  );

  return {
    isTranslateOff,
  };
}
