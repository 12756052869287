import { type PromisifyFn, useDebounceFn } from '@vueuse/core/index';
import { type Ref, ref, computed, type ComputedRef } from 'vue';
import type { UserSupplier, UserCompany } from '~/domains/user';

export interface ReturnUseSupplierSearch {
  searchingValue: Ref<string>;
  onSearchChange: PromisifyFn<(value: string) => void>;
  searchItemsByValue: ComputedRef<UserSupplier[] | UserCompany[]>;
}
export default (
  items: ComputedRef<UserSupplier[] | UserCompany[]>,
): ReturnUseSupplierSearch => {
  const searchingValue: Ref<string> = ref('');

  const searchItemsByValue: ComputedRef<UserSupplier[] | UserCompany[]> =
    computed(() => {
      if (searchingValue.value === '' || items.value === undefined) {
        return items.value ?? [];
      }

      return items?.value.filter((item) =>
        item.name.toLowerCase().includes(searchingValue.value.toLowerCase()),
      );
    });

  const onSearchChange = useDebounceFn(
    (value: string) => {
      searchingValue.value = value;
    },
    150,
    { maxWait: 1000 },
  );

  return {
    searchingValue,
    onSearchChange,
    searchItemsByValue,
  };
};
