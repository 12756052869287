<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.65023 11.5001C4.65023 7.16466 8.16479 3.6501 12.5002 3.6501C16.8357 3.6501 20.3502 7.16466 20.3502 11.5001C20.3502 15.8355 16.8357 19.3501 12.5002 19.3501C11.1623 19.3501 9.90426 19.0159 8.80327 18.4269L8.59079 18.3133L8.35557 18.3656L4.85593 19.1439L5.63291 15.6408L5.685 15.406L5.57163 15.1938C4.98377 14.0936 4.65023 12.8367 4.65023 11.5001ZM12.5002 2.3501C7.44682 2.3501 3.35023 6.44669 3.35023 11.5001C3.35023 12.9687 3.69668 14.358 4.31275 15.5893L3.36565 19.8593L3.14453 20.8563L4.14134 20.6346L8.40749 19.6858C9.63965 20.303 11.0303 20.6501 12.5002 20.6501C17.5536 20.6501 21.6502 16.5535 21.6502 11.5001C21.6502 6.44669 17.5536 2.3501 12.5002 2.3501ZM14.0002 13.0001C13.9171 13.0914 13.7099 13.1395 13.6131 13.1395C12.2207 13.1395 10.9064 11.5165 10.3228 10.3953C10.3228 10.3023 10.3228 10.2093 10.3712 10.1628C10.4158 10.1093 10.4749 10.0472 10.5399 9.97894C10.8024 9.70318 11.1617 9.32592 11.0486 8.99998C11.0003 8.76741 10.4196 7.5116 10.2745 7.23255C10.1042 6.98719 10.0278 6.98883 9.66738 6.99654C9.59273 6.99814 9.50589 7 9.40351 6.99998C9.0642 7.00008 8.90806 7.16466 8.67454 7.4108C8.63052 7.4572 8.58375 7.5065 8.53248 7.55814C8.19376 7.97674 8.00023 8.48836 8.00023 8.99998C8.00023 11.7606 11.2099 14.9112 13.8551 15.3181C15.3551 15.5488 16.3514 14.8986 16.8067 14.0232C16.9658 13.7173 17.0002 13.3834 17.0002 13.0465C17.0002 13.0465 16.9518 13 16.855 12.9535C16.6839 12.8787 16.5121 12.8051 16.3403 12.7314C15.9124 12.5479 15.4842 12.3644 15.0648 12.1628C14.8859 12.0768 14.8212 12.0982 14.0002 13.0001Z"
      fill="#60D669"
    />
  </svg>
</template>
