import { type UserSelectedResponse } from '../types.d';
import { type UserSelected } from '~/domains/user';

export default function userSelectedSerializer(
  data: UserSelectedResponse,
): UserSelected {
  return {
    shopId: data?.shop_id,
    supplierId: data?.sid,
    customerId: data?.cid,
    supplierWarehouse: data?.supplier_warehouse,
    customerWarehouse: data?.customer_warehouse,
    language: data?.language,
    currency: data?.currency,
    supplier: {
      id: data?.supplier?.id ?? '',
      name: data?.supplier?.name ?? '',
      shops: data?.supplier?.shops ?? [],
    },
    customer: data?.customer,
    showPricesWithVat: Boolean(data?.show_prices_with_vat),
  };
}
