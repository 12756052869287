// Hазбивает исходный массив на указанное количество массивов
// splitArray([1, 2, 3, 4, 5], 3) => [[1, 4], [2, 5], [3]]
export default function splitArray<T>(
  arr: T[] | undefined,
  size: number,
): T[][] {
  if (arr === undefined) return [];
  const result: T[][] = [];
  let currentArray = 0;
  arr.forEach((element) => {
    if (currentArray === size) currentArray = 0;
    if (result[currentArray] === undefined) result[currentArray] = [];
    result[currentArray].push(element);
    currentArray++;
  });

  return result;
}
