import {
  type CatalogSortBy,
  type CategorySortQuery,
  type CategorySortTypes,
} from '~/domains/category';
import { splitSortString } from '~/utils';

const getSortType = (data: CatalogSortBy | string): CategorySortTypes => {
  const actualField =
    typeof data === 'string' ? splitSortString(data).field : data.field;
  const actualSort =
    typeof data === 'string' ? splitSortString(data).sort : data.sort;

  switch (actualField) {
    case 'sku': {
      return actualSort === 'asc' ? 'ARTICLE_ASC' : 'ARTICLE_DESC';
    }
    case 'title': {
      return actualSort === 'asc' ? 'DESCRIPTION_ASC' : 'DESCRIPTION_DESC';
    }
    case 'price': {
      return actualSort === 'asc' ? 'PRICE_ASC' : 'PRICE_DESC';
    }
    case 'stock': {
      return actualSort === 'asc' ? 'STOCK_LTE_ASC' : 'STOCK_LTE_DESC';
    }
  }
};

export default function productSortDeserializer(
  data: CatalogSortBy | null,
): CategorySortQuery | null {
  if (data != null) {
    return {
      sb: getSortType(data),
    };
  }

  return null;
}
