import type { Brand } from '../types';
import type { ProductFilterBrandResponse } from '~/domains/product';

export default function brandByIdBrandSerializer(
  brand?: ProductFilterBrandResponse,
): Brand | undefined {
  if (brand === undefined) return undefined;

  return {
    id: +brand.id,
    name: brand.name,
  };
}
