<template>
  <UiHeader>
    <div v-if="isAuth" class="d-flex justify-space-between w-100 h-100">
      <div class="d-flex">
        <AppHeaderSupplier
          v-if="selectedSupplier"
          :selected-supplier="selectedSupplier"
          :country-code="supplierCountryCode"
          :suppliers="userSuppliers"
        />
        <AppHeaderManager
          v-if="manager && manager.fullName"
          :manager="manager"
        />
      </div>
      <div class="d-flex align-center">
        <AppHeaderCompany
          v-if="selectedCompany"
          :company="selectedCompany.name"
          :companies="userCompanies"
          :country-code="customerCountryCode"
          :city="customerCity"
        />
        <AppHeaderAccount v-if="userName" :user-name="userName" />
        <AppHeaderCurrency />
        <UiTooltip>
          <template #activator="{ props }">
            <AppHeaderActionWrapper v-bind="props">
              <UiIcon icon="$logout" @click="logout" />
            </AppHeaderActionWrapper>
          </template>
          {{ t('header.logout.text') }}
        </UiTooltip>
      </div>
    </div>
  </UiHeader>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { UiHeader, UiTooltip, UiIcon } from '~/ui';
import AppHeaderCurrency from './AppHeaderCurrency.vue';
import AppHeaderAccount from './AppHeaderAccount.vue';
import AppHeaderSupplier from './AppHeaderSupplier.vue';
import { useAuthStore, useLogout } from '~/domains/auth';
import AppHeaderCompany from './AppHeaderCompany.vue';
import { useI18n } from '~/infrastructure';
import AppHeaderManager from '~/app/layouts/header/components/AppHeaderManager.vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '~/domains/user';
import AppHeaderActionWrapper from './AppHeaderActionWrapper.vue';

const { logout } = useLogout();
const { isAuth } = storeToRefs(useAuthStore());
const { t } = useI18n();
const { user, selectedCompany } = storeToRefs(useUserStore());

const selectedSupplier = computed(() => user.value?.selected?.supplier?.name);
const supplierCountryCode = computed(
  () => user.value?.supplierBillToAddress?.countryCode,
);
const userSuppliers = computed(() => user.value?.suppliers);
const manager = computed(() => user.value?.manager);
const userCompanies = computed(() => user.value?.companies);
const customerCountryCode = computed(
  () => user.value?.customerBillToAddress?.countryCode,
);
const customerCity = computed(() => user.value?.customerBillToAddress?.city);
const userName = computed(() => user.value?.fullName);
</script>
