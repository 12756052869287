<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.3787 6.27421C9.86191 5.44133 14.1381 5.44147 18.6213 6.27425C19.754 6.48465 20.6582 7.32357 20.912 8.42084C21.4961 10.9462 21.4961 13.0532 20.9121 15.579C20.6582 16.6767 19.7536 17.5158 18.6205 17.726C14.1379 18.5576 9.8623 18.5576 5.37976 17.726C4.24659 17.5158 3.34193 16.6766 3.08812 15.5789C2.50406 13.0529 2.50409 10.9459 3.08819 8.42069C3.34198 7.32348 4.24611 6.48462 5.3787 6.27421ZM18.8587 4.99612C14.2186 4.13419 9.78145 4.13403 5.14125 4.99608C3.5258 5.29619 2.19772 6.50178 1.82163 8.12773C1.19294 10.8457 1.19293 13.1531 1.82153 15.8718C2.19765 17.4984 3.52645 18.7044 5.14265 19.0042C9.78193 19.8649 14.2183 19.8648 18.8576 19.0042C20.4737 18.7044 21.8025 17.4985 22.1786 15.8719C22.8073 13.1533 22.8072 10.846 22.1785 8.12789C21.8024 6.50184 20.4743 5.29621 18.8587 4.99612ZM10.3322 8.44094C10.1313 8.32154 9.8818 8.31916 9.67867 8.43473C9.47555 8.55029 9.3501 8.766 9.3501 8.99969V14.9997C9.3501 15.2343 9.47657 15.4508 9.68101 15.566C9.88544 15.6812 10.1361 15.6772 10.3368 15.5557L15.3368 12.5276C15.5321 12.4093 15.651 12.1973 15.6501 11.9689C15.6491 11.7406 15.5285 11.5295 15.3322 11.4129L10.3322 8.44094ZM10.6501 13.8461V10.1422L13.7367 11.9768L10.6501 13.8461Z"
      fill="#FF0000"
    />
  </svg>
</template>
