import {
  type CountryWithFlag,
  getCountryWithFlagUseCase,
} from '~/domains/core';
import countryWithFlagLocalRepository from '~/domains/core/infrastructure/repositories/countryWithFlagLocalRepository.ts';

const useGetCountryWithFlagAdapter = (): (() => Promise<
  CountryWithFlag[] | undefined
>) => {
  const getCountryWithFlag = async (): Promise<
    CountryWithFlag[] | undefined
  > => {
    return await getCountryWithFlagUseCase({
      countryWithFlagRepository: countryWithFlagLocalRepository,
    });
  };

  return getCountryWithFlag;
};

export default useGetCountryWithFlagAdapter;
