import {
  removeAllProductsFromCompareUseCase,
  compareRepository,
  useCompareStore,
} from '~/domains/compare';

import { useSnackbarNotificationService } from '~/domains/core';
import { useI18n } from '~/infrastructure';

export default function useRemoveAllProductsFromCompareAdapter(): {
  removeAllProductsFromCompare: () => Promise<void>;
} {
  const { notifyError, notifySuccess } = useSnackbarNotificationService();
  const compareStore = useCompareStore();
  const { t } = useI18n();
  const removeAllProductsFromCompare = async (): Promise<void> => {
    await removeAllProductsFromCompareUseCase({
      notifier: {
        notifySuccess: () => {
          notifySuccess(t('page.compare.allProductsHaveBeenRemoved.success'));
        },
        notifyError: () => {
          notifyError(t('common.error.somethingWentWrong'));
        },
      },
      compareRepository,
      storage: compareStore,
    });
  };

  return {
    removeAllProductsFromCompare,
  };
}
