import {
  useAddToCompareAdapter,
  useCompareStore,
  useRemoveProductFromCompareAdapter,
  useGetCompareAdapter,
  useRemoveAllFromProductTypeAdapter,
} from '~/domains/compare';
import { type Product, type ProductInfo } from '~/domains/product';
import { computed, type ComputedRef, type Ref } from 'vue';
import {
  type CompareGroup,
  type Specification,
  createComparedProductsSpecifications,
  convertToSpecificationsViewEntryValue,
  filterComparedProductSpecifications,
} from '~/domains/compare';
import useRemoveAllProductsFromCompareAdapter from '../../infrastructure/adapters/useRemoveAllProductsFromCompareAdapter';
import { storeToRefs } from 'pinia';
import { useSnackbarNotificationService } from '~/domains/core';
import { useI18n } from 'vue-i18n';

export default function (): {
  groups: Ref<CompareGroup[]>;
  loading: Ref<boolean>;
  getCompare: () => Promise<void>;
  addToCompare: (products: Product[]) => Promise<void>;
  removeFromCompare: (product: Product) => Promise<void>;
  clearCompare: () => Promise<void>;
  removeAllFromProductType: (productTypeId: string) => Promise<void>;
  isProductInCompare: (product: Product) => boolean;
  isEmpty: ComputedRef<boolean>;
  specifications: ComputedRef<
    Map<string, Array<Specification<string | boolean>>>
  >;
  isActiveHidingOfMatches: Ref<boolean>;
  onColumnMoved: (value: any) => void;
} {
  const { items, isEmpty, loading, isActiveHidingOfMatches } =
    storeToRefs(useCompareStore());
  const { isProductInCompare, updateItemsWithNewArranging } = useCompareStore();

  const { getCompare: getCompareAdapter } = useGetCompareAdapter();
  const { addToCompare: addToCompareAdapter } = useAddToCompareAdapter();
  const { removeProductFromCompare: removeProductFromCompareAdapter } =
    useRemoveProductFromCompareAdapter();
  const { removeAllProductsFromCompare: removeAllProductsFromCompareAdapter } =
    useRemoveAllProductsFromCompareAdapter();
  const { removeAllFromProductType: removeAllFromProductTypeAdapter } =
    useRemoveAllFromProductTypeAdapter();

  const { notifyError } = useSnackbarNotificationService();

  const { t } = useI18n();

  const specificationsView: ComputedRef<
    Map<string, Array<Specification<string | boolean>>>
  > = computed(() => {
    const entries = items.value.map((tab) => {
      const comparedProductSpecifications =
        createComparedProductsSpecifications(tab.products);

      const filtered = isActiveHidingOfMatches.value
        ? filterComparedProductSpecifications(comparedProductSpecifications)
        : comparedProductSpecifications;

      return [tab.type.id, convertToSpecificationsViewEntryValue(filtered)];
    });

    // TODO: fix this
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return new Map(entries as any);
  });

  const getCompare = async (): Promise<void> => {
    loading.value = true;
    try {
      await getCompareAdapter();
    } finally {
      loading.value = false;
    }
  };

  const productIsAlreadyAddedToCompare = (product: Product): boolean => {
    return items.value.some((item) => {
      return item.products.some(
        (itemProduct) => itemProduct.product.id === product.id,
      );
    });
  };

  const addToCompare = async (products: Product[]): Promise<void> => {
    loading.value = true;
    try {
      const productStatusMap = new Map(
        products.map((product) => [
          product.id,
          productIsAlreadyAddedToCompare(product),
        ]),
      );

      const everyProductsAreAddedToCompare = Array.from(
        productStatusMap.values(),
      ).every((status) => status);

      if (everyProductsAreAddedToCompare) {
        notifyError(t('page.catalog.productsHaveAlreadyBeenAddedToCompare'));
      } else {
        const filteredProducts = products.filter(
          (product) => productStatusMap.get(product.id) === false,
        );

        await addToCompareAdapter(filteredProducts);
      }
    } finally {
      loading.value = false;
    }
  };

  const removeFromCompare = async (product: Product): Promise<void> => {
    loading.value = true;
    try {
      await removeProductFromCompareAdapter(product);
    } finally {
      loading.value = false;
    }
  };

  const removeAllFromProductType = async (
    productTypeId: string,
  ): Promise<void> => {
    loading.value = true;
    try {
      await removeAllFromProductTypeAdapter(productTypeId);
    } finally {
      loading.value = false;
    }
  };

  const clearCompare = async (): Promise<void> => {
    loading.value = true;
    try {
      await removeAllProductsFromCompareAdapter();
    } finally {
      loading.value = false;
    }
  };

  const onColumnMoved = ({
    value,
    activeTab,
  }: {
    value: any;
    activeTab: string;
  }): void => {
    const activeTabItem = items.value.find(
      (item) => item.type.id.toString() === activeTab,
    );

    const productsOfActiveTab = activeTabItem?.products ?? [];

    const movedProductsList = value.api
      .getColumnDefs()
      .slice(1)
      .map((def: any) => def.headerComponentParams.product)
      .filter((product: Product) => product !== null);

    const updatedProducts = movedProductsList
      .map((product: Product) =>
        productsOfActiveTab.find((p) => p.product.id === product.id),
      )
      .filter(
        (productInfo: ProductInfo | undefined) => productInfo !== undefined,
      );

    if (activeTabItem !== undefined) {
      const indexOfActiveTabItem = items.value.indexOf(activeTabItem);

      const updatedTabItem = {
        type: { ...activeTabItem.type },
        products: updatedProducts,
      };

      const newItemsList = [...items.value];
      newItemsList.splice(indexOfActiveTabItem, 1, updatedTabItem);

      updateItemsWithNewArranging(newItemsList);
    }
  };

  return {
    groups: items,
    loading,
    getCompare,
    addToCompare,
    removeFromCompare,
    removeAllFromProductType,
    clearCompare,
    isProductInCompare,
    isEmpty,
    specifications: specificationsView,
    isActiveHidingOfMatches,
    onColumnMoved,
  };
}
