import { createRouter, createWebHistory } from 'vue-router/auto';
import { setupLayouts } from 'virtual:generated-layouts';
import {
  authGuard,
  loginByTokenGuard,
  permissionsGuard,
  translateOffGuard,
  redirectToLoginGuard,
} from './guards';

const router = createRouter({
  history: createWebHistory(),
  extendRoutes: (_routes) => {
    const routes = [
      ..._routes,
      {
        name: 'product',
        path: '/product/:id(.*)',
        component: async () =>
          await import('~/app/pages/product/[id].vue').then(
            (r) => r.default ?? r,
          ),
      },
    ];
    return setupLayouts(routes);
  },
});

router.beforeEach(translateOffGuard);
router.beforeEach(loginByTokenGuard);
router.beforeEach(permissionsGuard);
router.beforeEach(redirectToLoginGuard);
router.beforeResolve(authGuard);

router.onError((_, to) => {
  window.location.href = to.fullPath;
});

export default router;
