import { computed, type ComputedRef } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '~/domains/user';
import { type Permissions, type Entities } from '~/infrastructure';
import { type RouteLocationNormalized } from 'vue-router';
import { type UserFunctionTypes } from '~/domains/user';

interface UsePermissionsReturn {
  isEntityHaveAccess: (entity: Entities) => boolean;
  checkAccess: (currentRoute: RouteLocationNormalized) => boolean;
  showOrdering: ComputedRef<boolean>;
  showAccount: ComputedRef<boolean>;
}

const PERMISSIONS_MAP: Permissions = {
  dep: {
    name: 'DEP',
    routesToBlock: ['/dep/enrollments'],
  },
  warranty: {
    name: 'WARRANTY',
    routesToBlock: ['/warranty'],
  },
  order: {
    name: 'ORDERING',
    routesToBlock: ['/orders', '/basket'],
  },
  account: {
    name: 'ACCOUNTING',
    routesToBlock: ['/account'],
  },
};

const ENTITIES: Entities[] = ['dep', 'warranty', 'order', 'account'];

export default (): UsePermissionsReturn => {
  const { user } = storeToRefs(useUserStore());
  const entitiesWithAccess = computed(() => user.value?.functions ?? []);

  const showOrdering = computed(() =>
    entitiesWithAccess.value.includes(PERMISSIONS_MAP.order.name),
  );

  const showAccount = computed(() =>
    entitiesWithAccess.value.includes(PERMISSIONS_MAP.account.name),
  );

  const isEntityHaveAccess = (entity: Entities): boolean => {
    return entitiesWithAccess.value.includes(
      PERMISSIONS_MAP[entity].name as UserFunctionTypes,
    );
  };

  const isIncludePath = (
    entity: Entities,
    currentRoute: RouteLocationNormalized,
  ): boolean => {
    return PERMISSIONS_MAP[entity].routesToBlock.some((item) =>
      currentRoute.path.startsWith(item),
    );
  };

  const checkAccess = (currentRoute: RouteLocationNormalized): boolean => {
    let haveAccess: boolean = true;

    ENTITIES.forEach((entity) => {
      if (isIncludePath(entity, currentRoute)) {
        haveAccess = isEntityHaveAccess(entity);
      }
    });

    return haveAccess;
  };

  return {
    isEntityHaveAccess,
    checkAccess,
    showOrdering,
    showAccount,
  };
};
