import {
  type ProductGrade,
  type ProductGradeResponse,
} from '~/domains/product';
import { storeToRefs } from 'pinia';
import { useUserStore } from '~/domains/user';
import { computed, type ComputedRef } from 'vue';

interface ProductGradeInformation {
  grade: ProductGradeResponse;
  title: string;
  shortDescription: string;
  description: string;
}

interface ProductGradeReturn {
  gradesInformation: ProductGradeInformation[];
  showGradeColumns: ComputedRef<boolean | undefined>;
  getGradeInformation: (
    grade: ProductGrade,
  ) => ProductGradeInformation | undefined;
}

export default function useProductGrade(): ProductGradeReturn {
  const { user } = storeToRefs(useUserStore());

  const gradesInformation: ProductGradeInformation[] = [
    {
      grade: 'N',
      title: 'components.product.grade.n.title',
      shortDescription: 'components.product.grade.n.shortDescription',
      description: 'components.product.grade.n.description',
    },
    {
      grade: 'Q',
      title: 'components.product.grade.q.title',
      shortDescription: 'components.product.grade.q.shortDescription',
      description: 'components.product.grade.q.description',
    },
    {
      grade: 'A',
      title: 'components.product.grade.a.title',
      shortDescription: 'components.product.grade.a.shortDescription',
      description: 'components.product.grade.a.description',
    },
    {
      grade: 'B',
      title: 'components.product.grade.b.title',
      shortDescription: 'components.product.grade.b.shortDescription',
      description: 'components.product.grade.b.description',
    },
    {
      grade: 'C',
      title: 'components.product.grade.c.title',
      shortDescription: 'components.product.grade.c.shortDescription',
      description: 'components.product.grade.c.description',
    },
    {
      grade: 'D',
      title: 'components.product.grade.d.title',
      shortDescription: 'components.product.grade.d.shortDescription',
      description: 'components.product.grade.d.description',
    },
  ];

  const getGradeInformation = (
    grade: ProductGrade,
  ): ProductGradeInformation | undefined => {
    return gradesInformation.find((gradeData) => gradeData.grade === grade);
  };

  const showGradeColumns = computed(() => user.value?.isUseGrades);

  return {
    gradesInformation,
    showGradeColumns,
    getGradeInformation,
  };
}
