import { type ProductVideoResponse } from '~/domains/product';
import type ProductVideo from '~/domains/product/entities/ProductVideo';

export default function productVideoSerializer(
  data: ProductVideoResponse,
): ProductVideo {
  return {
    article: data.article,
    isoLang: data.iso_lang,
    playTime: data.play_time,
    description: data.description,
    url: data.url,
    kind: data.kind,
  };
}
