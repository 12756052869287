<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon" clip-path="url(#clip0_19212_65026)">
      <circle
        id="Ellipse 707"
        cx="9.9987"
        cy="10.0002"
        r="8.41667"
        stroke="currentColor"
        stroke-width="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_19212_65026">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
