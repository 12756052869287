import { type NotificationService } from '../ports';
import {
  type CountryWithFlag,
  type CountryWithFlagRepository,
} from '~/domains/core';

type GetFlagsUseCaseReturn = (dependencies: {
  countryWithFlagRepository: CountryWithFlagRepository;
  notifier?: NotificationService;
}) => Promise<CountryWithFlag[] | undefined>;

const getCountryWithFlagUseCase: GetFlagsUseCaseReturn = async ({
  countryWithFlagRepository,
  notifier,
}) => {
  try {
    return await countryWithFlagRepository.getCountryWithFlag();
  } catch (e) {
    notifier?.notifyError?.('Country with flag list was not loaded');
    return undefined;
  }
};

export default getCountryWithFlagUseCase;
