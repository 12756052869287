import { getSocialUseCase } from '../../useCases';
import { socialLocalRepository } from '../repositories';
import { type Social } from '../../entities';

const useGetSocialAdapter = async (): Promise<Social[] | undefined> => {
  return await getSocialUseCase({
    socialRepository: socialLocalRepository,
  });
};

export default useGetSocialAdapter;
