import { ref, type Ref } from 'vue';
import { useAdmin, useI18n, useRoute } from '~/infrastructure';
import { storeToRefs } from 'pinia';
import { useUserStore } from '~/domains/user';
import { i18nService } from '~/domains/i18n';
import { NO_SHOP_ID, UNKNOWN_LANG } from '../constants.d';

export default (): {
  getTranslates: () => Promise<void>;
  loading: Ref<boolean>;
} => {
  const { user } = storeToRefs(useUserStore());
  const i18n = useI18n();
  const cachedLangs: Record<string, true> = {};
  const { isTranslateOff } = useAdmin();
  const loading = ref(false);
  const route = useRoute();

  const setLocale = (newLocale: string): void => {
    i18n.locale.value = newLocale;
  };

  const getTranslates = async (): Promise<void> => {
    const useTranslation = import.meta.env.VITE_USE_TRANSLATION;

    if (useTranslation === 'LOCAL') {
      const translates = await i18nService.getLocalTranslates();

      i18n.setLocaleMessage('en', translates);
      setLocale('en');

      return;
    }

    const queryLang = route.query.lang as string;
    const language = user.value?.selected.language ?? queryLang ?? UNKNOWN_LANG;
    const shopId = user.value?.selected.shopId ?? NO_SHOP_ID;

    if (cachedLangs[language]) {
      setLocale(language);
      return;
    }

    try {
      loading.value = true;
      const translates = await i18nService.getTranslates(shopId, language);
      if (!isTranslateOff.value) {
        i18n.setLocaleMessage(language, translates);
        setLocale(language);

        cachedLangs[language] = true;
      }
    } catch (_) {
    } finally {
      loading.value = false;
    }
  };

  return { getTranslates, loading };
};
