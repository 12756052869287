import { type Product } from '../../product/types';
import { type CompareRepository } from '../ports';
import { type CompareGroup } from '~/domains/compare';

interface Dependencies {
  notifier?: {
    notifySuccess: (productsQuantity: number) => void;
    notifyError: () => void;
  };
  storage?: {
    updateCompare: (groups: CompareGroup[]) => void;
  };
  compareRepository: CompareRepository;
}

export async function addToCompareUseCase(
  products: Product[],
  { notifier, storage, compareRepository }: Dependencies,
): Promise<void> {
  try {
    const result = await compareRepository.addProducts(products);

    const productsQuantity: number = products.length;

    notifier?.notifySuccess(productsQuantity);
    storage?.updateCompare(result);
  } catch (e) {
    notifier?.notifyError();
  }
}
