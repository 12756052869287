<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.8827 6.47423C19.173 6.68538 19.2372 7.0919 19.0261 7.38222L11.0258 18.3822C10.9019 18.5526 10.7032 18.6523 10.4926 18.6499C10.282 18.6474 10.0856 18.5431 9.96573 18.3699L5.46573 11.8699C5.26139 11.5747 5.33502 11.1698 5.63017 10.9655C5.92532 10.7611 6.33024 10.8348 6.53458 11.1299L10.5132 16.8768L17.9747 6.61758C18.1859 6.32726 18.5924 6.26308 18.8827 6.47423Z"
      fill="currentColor"
    />
  </svg>
</template>
