import { type Product } from '~/domains/product';
import { CompareModes, type CompareRequest } from '~/domains/compare';

export default function compareRemoveProductDeserializer(
  product: Product,
): CompareRequest {
  const newLines: CompareRequest['lines'] = [
    {
      article: product.sku,
      mode: CompareModes.DELETE,
    },
  ];
  return {
    lines: newLines,
  };
}
