<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9998 4.64998C9.32571 4.64998 6.98348 6.0393 5.66956 8.12244L6.354 7.84273C6.6863 7.70692 7.06578 7.86622 7.20159 8.19852C7.33739 8.53083 7.1781 8.91031 6.84579 9.04611L4.59569 9.96568C4.32144 10.0778 4.00611 9.99021 3.8289 9.75279L2.479 7.94434C2.26427 7.65666 2.3234 7.24938 2.61108 7.03464C2.89876 6.81991 3.30605 6.87904 3.52078 7.16672L4.19978 8.07637C5.64749 5.26902 8.59867 3.34998 11.9998 3.34998C15.818 3.34998 19.0692 5.76862 20.2627 9.1475C20.3822 9.48599 20.2048 9.85732 19.8663 9.97687C19.5278 10.0964 19.1565 9.91895 19.0369 9.58046C18.0241 6.71296 15.2583 4.64998 11.9998 4.64998ZM3.95496 13.1443C4.30483 13.0639 4.65362 13.2823 4.734 13.6322C5.485 16.901 8.44923 19.35 11.9998 19.35C15.1008 19.35 17.7545 17.4819 18.8772 14.8311L17.6177 15.2791C17.2795 15.3994 16.9078 15.2227 16.7875 14.8845C16.6672 14.5462 16.8438 14.1745 17.1821 14.0542L19.6813 13.1653C19.9915 13.055 20.3353 13.1941 20.4815 13.4892L21.5823 15.7114C21.7417 16.0331 21.6101 16.4231 21.2884 16.5824C20.9667 16.7418 20.5768 16.6102 20.4174 16.2885L20.0147 15.4755C18.6598 18.5252 15.5784 20.65 11.9998 20.65C7.84063 20.65 4.35304 17.7798 3.46701 13.9233C3.38663 13.5734 3.60509 13.2246 3.95496 13.1443Z"
      fill="currentColor"
    />
  </svg>
</template>
