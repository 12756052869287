<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.5405 18.4596C17.7943 18.7134 18.2059 18.7134 18.4597 18.4596C18.7136 18.2057 18.7136 17.7942 18.4597 17.5403L12.9193 11.9999L18.4597 6.45956C18.7136 6.20572 18.7136 5.79417 18.4597 5.54033C18.2059 5.28649 17.7943 5.28649 17.5405 5.54033L12.0001 11.0807L6.45972 5.54033C6.20588 5.28648 5.79432 5.28648 5.54048 5.54033C5.28664 5.79417 5.28664 6.20572 5.54048 6.45956L11.0809 11.9999L5.54048 17.5403C5.28664 17.7942 5.28664 18.2057 5.54048 18.4596C5.79432 18.7134 6.20588 18.7134 6.45972 18.4596L12.0001 12.9192L17.5405 18.4596Z"
      fill="currentColor"
    />
  </svg>
</template>
