import { type Ref, ref, watch } from 'vue';
import {
  type MaybeRefOrGetter,
  useScroll,
  useWindowScroll,
  type UseScrollOptions,
} from '@vueuse/core';

interface UseScrollReturn {
  isScrollingBottom: Ref<boolean>;
  arrivedState: {
    left: boolean;
    right: boolean;
    top: boolean;
    bottom: boolean;
  };
}

export default (
  element: MaybeRefOrGetter<
    HTMLElement | SVGElement | Window | Document | null | undefined
  > = window,
  options?: UseScrollOptions,
): UseScrollReturn => {
  const isScrollingBottom = ref(false);
  const { directions, arrivedState } = useScroll(element, options);
  const { y } = useWindowScroll();

  watch([() => directions.bottom, y], ([newDirection, newY]) => {
    if (newDirection && !isScrollingBottom.value && Number(newY) > 0) {
      isScrollingBottom.value = true;
    }
  });

  watch(
    () => directions.top,
    (newValue) => {
      if (newValue && isScrollingBottom.value) {
        isScrollingBottom.value = false;
      }
    },
  );

  return {
    isScrollingBottom,
    arrivedState,
  };
};
