<template>
  <svg
    width="18"
    height="8"
    viewBox="0 0 18 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33333 0.666656H4.83333C2.99238 0.666656 1.5 2.15904 1.5 3.99999V3.99999C1.5 5.84094 2.99239 7.33332 4.83333 7.33332H7.33333M10.6667 0.666656H13.1667C15.0076 0.666656 16.5 2.15904 16.5 3.99999V3.99999C16.5 5.84094 15.0076 7.33332 13.1667 7.33332H10.6667M6.5 3.99999H11.5"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
