import {
  removeProductFromCompareUseCase,
  compareRepository,
  useCompareStore,
} from '~/domains/compare';
import { useSnackbarNotificationService } from '~/domains/core';
import { type Product } from '~/domains/product';
import { useI18n } from '~/infrastructure';

export default function useRemoveProductFromCompareAdapter(): {
  removeProductFromCompare: (product: Product) => Promise<void>;
} {
  const { notifyError, notifySuccess } = useSnackbarNotificationService();
  const compareStore = useCompareStore();
  const { t } = useI18n();
  const removeProductFromCompare = async (product: Product): Promise<void> => {
    await removeProductFromCompareUseCase(product, {
      notifier: {
        notifySuccess: (productArticle) => {
          notifySuccess(
            t('page.compare.productHasBeenRemoved.success', [productArticle]),
          );
        },
        notifyError: () => {
          notifyError(t('common.error.somethingWentWrong'));
        },
      },
      compareRepository,
      storage: compareStore,
    });
  };

  return {
    removeProductFromCompare,
  };
}
