<template>
  <UiListItem>
    <div v-if="productTypes.length">
      <div class="py-4">
        <UiText variant="body-2" class="mb-4 text-center" color="blue-700">
          {{ $t('common.CTO.menu.title') }}
        </UiText>
        <ProductTypeCardList class="product-type-card-list">
          <div v-for="(type, i) in productTypes" :key="i">
            <RouterLink :key="i" :to="`/cto-apple/${type.slug}`">
              <ProductTypeCard
                :product-type="type"
                bordered
                :is-active="slug === type.slug"
              />
            </RouterLink>
          </div>
        </ProductTypeCardList>
      </div>
      <UiDivider color="grey-100" class="mb-4" />
    </div>

    <UiRow class="px-6 py-2 flex-nowrap">
      <UiCol
        v-for="(item, i) in items"
        :key="i"
        class="ui-sidebar-menu-grid__column"
      >
        <div v-for="(group, j) in item" :key="j">
          <div v-if="group">
            <RouterLink
              v-if="group.link !== undefined"
              class="text-body-2 ui-sidebar-menu-grid__link"
              :to="group.link"
            >
              {{ group.label }}
            </RouterLink>

            <UiSidebarMenuGridItems
              v-if="group.children"
              :items="group.children"
            />
          </div>
        </div>
      </UiCol>
    </UiRow>
  </UiListItem>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { UiRow, UiCol, UiListItem, UiDivider, UiText } from '~/ui';
import { type SidebarItem } from '~/app/layouts/sidebar';
import UiSidebarMenuGridItems from './UiSidebarMenuGridItems.vue';
import { RouterLink, useRoute } from '~/infrastructure';
import { ProductTypeCardList, ProductTypeCard } from '~/domains/product';

export interface Props {
  items?: SidebarItem[][];
  additional?: SidebarItem;
}

const props = defineProps<Props>();
const route = useRoute();

const slug = computed(() => route.params.slug);

const productTypes = computed(() => {
  return (
    props.additional?.[0]?.children?.map((type) => ({
      title: type.name,
      slug: type.slug,
      image: {
        src: type.images.small,
      },
    })) ?? []
  );
});
</script>

<style scoped>
.ui-sidebar-menu-grid__link:hover {
  color: rgb(var(--v-theme-blue-500)) !important;
}

.ui-sidebar-menu-grid__column {
  display: flex;
  flex-direction: column;
  row-gap: 64px;
  min-width: 200px;
}

.product-type-card-list {
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-color: transparent;
}

.product-type-card-list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.product-type-card-list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
