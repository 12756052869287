import {
  useChangeUserSettingsAdapter,
  type UserCurrency,
} from '~/domains/user';
import { storeToRefs } from 'pinia';
import useUserStore from '../user.store.ts';
import { computed, type ComputedRef } from 'vue';
import { useGetCurrencyWithFlagViewModel } from '~/domains/core';

export interface ReturnUseChangeCurrency {
  changeCurrency: (currency: string) => Promise<void>;
  selectedCurrency: ComputedRef<string | undefined>;
  currencies: ComputedRef<readonly UserCurrency[] | undefined>;
  currencyImage: ComputedRef<string | undefined>;
  getFlagByCode: (code: string) => string | undefined;
}

export default (): ReturnUseChangeCurrency => {
  const { user } = storeToRefs(useUserStore());
  const { currencyWithFlag } = useGetCurrencyWithFlagViewModel();
  const { changeUserSettings } = useChangeUserSettingsAdapter();

  const selectedCurrency: ComputedRef<string | undefined> = computed(
    () => user.value?.selected.currency,
  );
  const currencies: ComputedRef<readonly UserCurrency[] | undefined> = computed(
    () => user.value?.currencies,
  );

  const currencyImage = computed(() =>
    selectedCurrency.value !== undefined
      ? getFlagByCode(selectedCurrency?.value)
      : undefined,
  );

  const getFlagByCode = (code: string): string | undefined => {
    return (
      currencyWithFlag?.value?.find((currency) => currency.code === code)
        ?.flag ?? undefined
    );
  };

  const changeCurrency = async (currency: string): Promise<void> => {
    await changeUserSettings({
      currency,
    });
  };

  return {
    changeCurrency,
    selectedCurrency,
    currencies,
    currencyImage,
    getFlagByCode,
  };
};
