<template>
  <span v-if="!isTranslateOff">
    <span v-if="rawHtml" v-html="translatedTitle" />
    <template v-else>
      {{ uppercase ? translatedTitle?.toUpperCase() : translatedTitle }}
    </template>
  </span>
  <UiMenu
    v-else
    :close-on-content-click="false"
    open-on-hover
    location="top left"
  >
    <template #activator="{ props: slotProps }">
      <span v-bind="slotProps" style="word-wrap: break-word">
        {{ identifier }}
      </span>
    </template>

    <template #default>
      <UiSheet
        rounded="xl"
        class="py-2 px-4 d-inline-flex"
        max-width="fit-content"
      >
        <span class="pr-2">{{ identifier }}</span>
        <UiCopyClipboard
          :copy="typeof identifier === 'string' ? identifier : ''"
          color="blue-500"
        />
      </UiSheet>
    </template>
  </UiMenu>
</template>

<script setup lang="ts">
import { useAdmin, useI18n } from '~/infrastructure';
import { computed } from 'vue';
import { UiCopyClipboard, UiMenu, UiSheet } from '~/ui';
import { type TranslateIdentifierTypes } from '~/domains/core';

export interface Props {
  identifier: TranslateIdentifierTypes;
  rawHtml?: boolean;
  uppercase?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  rawHtml: false,
  uppercase: false,
});

const { t } = useI18n();
const { isTranslateOff } = useAdmin();

const translatedTitle = computed<string>(() => {
  if (typeof props.identifier === 'object') {
    return t(props.identifier.key, props.identifier.params);
  }

  return t(props.identifier);
});
</script>
