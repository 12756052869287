import { defineStore } from 'pinia';
import { ref, readonly, computed, type Ref } from 'vue';
import { PATH_DICTIONARY } from './constants';
import { useLocalStorage } from '@vueuse/core';

const useAuthStore = defineStore('auth', () => {
  const redirectPathAfterLogin = ref(PATH_DICTIONARY.home);
  const authToken: Ref<string | null> = useLocalStorage('authToken', null);
  const isAuth = computed(() => Boolean(authToken.value));

  const setRedirectAfterLogin = (redirectPath = PATH_DICTIONARY.home): void => {
    redirectPathAfterLogin.value = redirectPath;
  };

  const setAuthToken = (token: string | null): void => {
    authToken.value = token;
  };

  const clearAuthToken = (): void => {
    authToken.value = null;
  };

  return {
    setRedirectAfterLogin,
    setAuthToken,
    clearAuthToken,
    redirectPathAfterLogin: readonly(redirectPathAfterLogin),
    isAuth,
  };
});

export default useAuthStore;
