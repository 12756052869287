import { Price } from '~/utils';
import { type UserCreditResponse } from '../types.d';
import { type UserCredit } from '~/domains/user';

export default function userCreditSerializer(
  data: UserCreditResponse,
): UserCredit | null {
  if (data === undefined) return null;
  return {
    overdue: new Price(data.overdue),
    creditLimit: new Price(data.credit_limit),
    openAccount: new Price(data.open_account),
    paymentDays: data?.payment_days ?? '',
  };
}
