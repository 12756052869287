<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0703 4.05322C11.3752 4.01807 11.6855 4 12 4C16.4182 4 20 7.58154 20 12C20 12.4189 19.9678 12.8306 19.9058 13.2319C19.8784 13.4092 19.8452 13.5845 19.8064 13.7578C20.5508 14.6309 21 15.7627 21 17C21 19.7612 18.7615 22 16 22C14.3643 22 12.9121 21.2144 12 20C7.58179 20 4 16.4185 4 12C4 11.6855 4.01807 11.3755 4.05347 11.0703C3.39331 10.2231 3 9.15723 3 8C3 5.23877 5.23853 3 8 3C9.15747 3 10.2229 3.39307 11.0703 4.05322Z"
      stroke="#01B9F1"
      stroke-width="1.3"
      stroke-linejoin="round"
    />
    <path
      d="M15.3349 9.18412C14.3218 7.12815 11.0436 6.69924 9.09512 8.68412C8.34944 9.44373 8.4683 11.1085 10.6312 11.4687C14.6294 12.1346 15.1593 12.9873 15.5168 13.5503C15.7353 13.8944 16.3799 15.8351 14.0469 16.6788C13.2999 16.949 11.4666 17.3398 9.5752 16.1841C9.01822 15.8437 8.61523 15.2798 8.61523 14.6841"
      stroke="#01B9F1"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
