import { type Ref, ref } from 'vue';
import {
  useGetUserInfoAdapter,
  type UserInfo,
  useUserStore,
} from '~/domains/user';
import { useAuthStore } from '~/domains/auth';

interface ViewModelReturn {
  loading: Ref<boolean>;
  fetchUserInfo: () => Promise<UserInfo | undefined>;
}

export default function getUserInfoViewModel(): ViewModelReturn {
  const getUserInfo = useGetUserInfoAdapter();
  const loading = ref(false);

  const fetchUserInfo = async (): Promise<UserInfo | undefined> => {
    const { clearAuthToken } = useAuthStore();
    loading.value = true;

    try {
      const response = await getUserInfo();

      if (response == null) {
        throw new Error('No user response');
      }

      const { setUser } = useUserStore();
      setUser(response);

      return response;
    } catch (e) {
      clearAuthToken();
    } finally {
      loading.value = false;
    }
  };

  return {
    fetchUserInfo,
    loading,
  };
}
