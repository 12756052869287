<template>
  <a v-if="isExternalLink" :href="href" :target="target" :download="download">
    <slot />
  </a>

  <RouterLink v-else :to="to">
    <slot />
  </RouterLink>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { RouterLink, type RouteLocation } from 'vue-router';

export interface Props {
  to: string | RouteLocation;
  target?: string;
  download?: boolean | undefined;
}

const props = withDefaults(defineProps<Props>(), {
  download: undefined,
  target: '_blank',
});

const isExternalLink = computed<boolean>(() =>
  typeof props.to === 'string'
    ? /^(https?|tel|mailto):/.test(props.to ?? '')
    : false,
);

const href = computed<string | undefined>(() => {
  if (typeof props.to === 'string') return props.to;
  else return props.to.fullPath;
});
</script>
