import { type ProductLinkResponse } from '~/domains/product';
import type ProductLink from '~/domains/product/entities/ProductLink';

export default function productLinkSerializer(
  data: ProductLinkResponse,
): ProductLink {
  return {
    name: data.description,
    kind: data.kind,
    src: data.url,
  };
}
