<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.4623 2.62726C16.647 2.36321 16.9974 2.27442 17.2856 2.41858L21.2842 4.41858C21.4976 4.52533 21.6354 4.74045 21.6431 4.97897C21.73 7.67473 21.0048 11.7232 18.5352 15.1663C16.043 18.6407 11.8118 21.4437 5.01889 21.6496C4.76566 21.6573 4.53104 21.5171 4.41777 21.2905L2.4185 17.2905C2.27448 17.0024 2.36322 16.6522 2.62708 16.4675L7.62526 12.9675C7.85553 12.8062 8.16334 12.8113 8.3882 12.98L10.367 14.4646C10.4606 14.5179 10.7395 14.5643 11.2966 14.3247C11.8269 14.0966 12.4362 13.6773 12.9895 13.1531C13.5429 12.6287 14.0019 12.0355 14.2624 11.4868C14.5157 10.9533 14.5323 10.5749 14.4344 10.335L12.976 8.38983C12.8075 8.16505 12.8025 7.85744 12.9636 7.62726L16.4623 2.62726ZM17.2058 3.8322L14.2986 7.98677L15.5156 9.61001C15.5388 9.64088 15.5591 9.67374 15.5764 9.70822C15.9598 10.4716 15.776 11.3299 15.4368 12.0443C15.0891 12.7768 14.5175 13.4961 13.8836 14.0967C13.2496 14.6975 12.515 15.2158 11.8103 15.5189C11.1528 15.8017 10.3054 15.9857 9.63725 15.541C9.62707 15.5343 9.61708 15.5272 9.60729 15.5198L7.98495 14.3027L3.83198 17.2108L5.39311 20.3342C11.5483 20.0423 15.2798 17.4744 17.4788 14.4086C19.6345 11.4032 20.3512 7.86925 20.3499 5.40484L17.2058 3.8322Z"
      fill="currentColor"
    />
  </svg>
</template>
