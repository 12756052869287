import { type RouteLocationNormalized } from 'vue-router';
import { PATH_DICTIONARY } from '~/domains/auth';
import { usePermissions } from '~/infrastructure';
import { useUserStore } from '~/domains/user';
import { storeToRefs } from 'pinia';

export default (to: RouteLocationNormalized): string | boolean => {
  const { checkAccess } = usePermissions();
  const { user } = storeToRefs(useUserStore());

  if (user.value !== null) {
    return !checkAccess(to) ? PATH_DICTIONARY.home : true;
  }

  return true;
};
