<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.6501 10.0001C4.6501 7.04537 7.04537 4.6501 10.0001 4.6501C12.9548 4.6501 15.3501 7.04537 15.3501 10.0001C15.3501 12.9548 12.9548 15.3501 10.0001 15.3501C7.04537 15.3501 4.6501 12.9548 4.6501 10.0001ZM10.0001 3.3501C6.3274 3.3501 3.3501 6.3274 3.3501 10.0001C3.3501 13.6728 6.3274 16.6501 10.0001 16.6501C11.6024 16.6501 13.0723 16.0834 14.2204 15.1396L19.0404 19.9596C19.2942 20.2135 19.7058 20.2135 19.9596 19.9596C20.2135 19.7058 20.2135 19.2942 19.9596 19.0404L15.1396 14.2204C16.0834 13.0723 16.6501 11.6024 16.6501 10.0001C16.6501 6.3274 13.6728 3.3501 10.0001 3.3501Z"
      fill="currentColor"
    />
  </svg>
</template>
