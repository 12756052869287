import { defineStore } from 'pinia';
import { ref } from 'vue';

const useSidebarStore = defineStore('sidebar', () => {
  const isSidebarOpen = ref<boolean>(true);

  const updateSidebarState = (value: boolean): void => {
    isSidebarOpen.value = value;
  };

  return {
    isSidebarOpen,
    updateSidebarState,
  };
});

export default useSidebarStore;
