<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.4161 5.49934C21.6919 5.26952 21.7292 4.85965 21.4993 4.58387C21.2695 4.30809 20.8597 4.27083 20.5839 4.50065L17.6229 6.96812C15.9799 5.8182 14.01 4.84994 11.9998 4.84997C9.78333 4.84999 7.61582 6.02726 5.88148 7.32722C4.12955 8.64036 2.72139 10.1537 2.00875 10.975C1.49611 11.5659 1.49611 12.4342 2.00875 13.025C2.7214 13.8463 4.12956 15.3597 5.88149 16.6728C5.8983 16.6854 5.91514 16.698 5.93202 16.7105L2.58388 19.5006C2.3081 19.7305 2.27084 20.1403 2.50066 20.4161C2.73047 20.6919 3.14034 20.7292 3.41612 20.4993L21.4161 5.49934ZM6.96138 15.8527L9.03296 14.1264C8.60305 13.5276 8.34991 12.7934 8.34991 12C8.34991 9.98414 9.98407 8.34998 11.9999 8.34998C13.0312 8.34998 13.9625 8.77764 14.6263 9.4653L16.5849 7.83315C15.1166 6.8531 13.5232 6.14995 11.9998 6.14997C10.2115 6.14999 8.32679 7.11901 6.66117 8.36745C5.01315 9.6027 3.67365 11.0398 2.99065 11.827C2.90214 11.929 2.90214 12.071 2.99065 12.173C3.67365 12.9602 5.01315 14.3973 6.66118 15.6326C6.76049 15.707 6.86058 15.7804 6.96138 15.8527ZM13.6235 10.301C13.2016 9.89769 12.6297 9.64998 11.9999 9.64998C10.702 9.64998 9.64991 10.7021 9.64991 12C9.64991 12.4767 9.79186 12.9203 10.0358 13.2907L13.6235 10.301ZM15.6499 11.9849L13.7481 13.5705C13.5971 13.7385 13.4221 13.8846 13.2287 14.0035L11.3279 15.5882C11.5457 15.6288 11.7703 15.65 11.9999 15.65C14.0158 15.65 15.6499 14.0158 15.6499 12L15.6499 11.9849ZM11.9999 17.85C11.1271 17.85 10.2314 17.6192 9.34941 17.2378L8.24661 18.1572C9.43016 18.7492 10.7067 19.15 11.9999 19.15C14.2164 19.15 16.3839 17.9727 18.1183 16.6727C19.8702 15.3596 21.2783 13.8462 21.991 13.0248C22.5036 12.434 22.5036 11.5657 21.9909 10.9749C21.5066 10.4167 20.7009 9.53872 19.6843 8.62125L18.6752 9.46259C19.7025 10.3752 20.5224 11.266 21.009 11.8269C21.0975 11.9289 21.0975 12.0709 21.009 12.1729C20.3261 12.9601 18.9866 14.3972 17.3386 15.6325C15.673 16.881 13.7882 17.85 11.9999 17.85Z"
      fill="currentColor"
    />
  </svg>
</template>
