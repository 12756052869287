<template>
  <UiSidebar
    :is-open="isSidebarOpen"
    :width="OPEN_SIDEBAR_WIDTH"
    :menu="items"
    @backdrop-visible-change="isBackdropVisible = $event"
    @is-open="updateSidebarState"
  >
    <template #prepend-drawer-content>
      <UiButton
        v-if="!isBackdropVisible && !isOpenFilter"
        class="ui-sidebar-header-button"
        size="small"
        color="grey-200"
        :icon="isSidebarOpen ? 'mdi-chevron-left' : 'mdi-chevron-right'"
        density="comfortable"
        width="16"
        height="24"
        @click.stop="updateSidebarState(!isSidebarOpen)"
      />
    </template>
    <template #logo>
      <AppLogo style="margin-top: -3px" />
    </template>
  </UiSidebar>
</template>

<script setup lang="ts">
import { UiSidebar, UiButton } from '~/ui';
import { AppLogo } from '~/app/layouts/logo';
import useSidebarItems from '../composables/useSidebarItems';
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useSidebarStore } from '~/app/layouts/sidebar/stores';
import { useProductFilterStore } from '~/domains/product';
import { OPEN_SIDEBAR_WIDTH } from '~/app/layouts/sidebar';

const { items } = useSidebarItems();

const isBackdropVisible = ref(false);
const { isOpenFilter } = storeToRefs(useProductFilterStore());
const { isSidebarOpen } = storeToRefs(useSidebarStore());
const { updateSidebarState } = useSidebarStore();

watch(
  () => isOpenFilter.value,
  (value) => {
    updateSidebarState(!value);
  },
);
</script>

<style lang="scss" scoped>
.ui-sidebar-header-button {
  position: absolute;
  right: -7px;
  top: 20px;
  color: white !important;
  z-index: 2;
}
</style>
