import {
  type UserChange,
  type UserChangeResponse,
  userSelectedSerializer,
} from '~/domains/user';

export default function userChangeSerializer(
  data: UserChangeResponse,
): UserChange {
  return {
    status: data.status,
    triesLeft: data.tries_left,
    token: data.token,
    errorMessage: data.error_message,
    shopUrl: data.shop_url,
    selected: userSelectedSerializer(data.selected),
    additional: data.additional,
  };
}
