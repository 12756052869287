import { type ProductSpecificationResponse } from '../../types';
import type ProductSpecificationBlock from '../../entities/ProductSpecificationBlock';

export default function productSpecificationSerializer(
  specification: ProductSpecificationResponse,
): ProductSpecificationBlock[] {
  const result: ProductSpecificationBlock[] = [];
  Object.keys(specification).forEach((key) => {
    result.push({
      caption: key,
      features: specification[key],
    });
  });

  return result;
}
