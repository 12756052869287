import { storeToRefs } from 'pinia';
import { useSidebarStore } from '~/app/layouts/sidebar/stores';
import { computed, type ComputedRef } from 'vue';
import { FILTER_WIDTH, useProductFilterStore } from '~/domains/product';
import { CLOSE_SIDEBAR_WIDTH, OPEN_SIDEBAR_WIDTH } from '~/app/layouts/sidebar';

export default function useNavbar(): {
  left: ComputedRef<string | null>;
  width: ComputedRef<string | null>;
} {
  const { isOpenFilter } = storeToRefs(useProductFilterStore());
  const { isSidebarOpen } = storeToRefs(useSidebarStore());

  const left = computed(() => {
    if (isOpenFilter.value) return CLOSE_SIDEBAR_WIDTH + FILTER_WIDTH + 'px';
    else if (!isSidebarOpen.value) return CLOSE_SIDEBAR_WIDTH + 'px';
    else if (isSidebarOpen.value) return OPEN_SIDEBAR_WIDTH + 'px';

    return null;
  });

  const width = computed(() => {
    if (isOpenFilter.value)
      return `calc(100% - ${CLOSE_SIDEBAR_WIDTH + 'px'} - ${FILTER_WIDTH + 'px'})`;
    else if (!isSidebarOpen.value)
      return `calc(100% - ${CLOSE_SIDEBAR_WIDTH + 'px'})`;
    else if (isSidebarOpen.value)
      return `calc(100% - ${OPEN_SIDEBAR_WIDTH + 'px'})`;

    return null;
  });

  return {
    left,
    width,
  };
}
