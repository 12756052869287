<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clip-path="url(#clip0_18543_349607)">
      <path
        d="M8.68653 5.44636L11.8685 2.26438C12.1985 1.93436 12.3635 1.76936 12.5538 1.70753C12.7212 1.65315 12.9015 1.65315 13.0688 1.70753C13.2591 1.76936 13.4241 1.93437 13.7541 2.26438L17.7611 6.27132C18.0911 6.60133 18.2561 6.76634 18.3179 6.95661C18.3723 7.12398 18.3723 7.30427 18.3179 7.47164C18.2561 7.66191 18.0911 7.82692 17.7611 8.15694L14.5791 11.3389M13.1059 5.15173L12.5167 5.74099M14.8737 6.9195L14.2845 7.50875M3.67786 12.2228L2.26365 13.637C1.93363 13.967 1.76863 14.132 1.7068 14.3223C1.65242 14.4897 1.65242 14.67 1.7068 14.8373C1.76863 15.0276 1.93363 15.1926 2.26365 15.5226L4.50282 17.7618C4.83283 18.0918 4.99784 18.2568 5.18811 18.3186C5.35548 18.373 5.53577 18.373 5.70314 18.3186C5.89342 18.2568 6.05842 18.0918 6.38844 17.7618L7.80265 16.3476M4.73852 13.2835L6.74199 15.2869C7.73203 16.277 8.22705 16.772 8.79787 16.9575C9.29998 17.1206 9.84085 17.1206 10.343 16.9575C10.9138 16.772 11.4088 16.277 12.3988 15.2869L14.5202 13.1656C14.8502 12.8356 15.0152 12.6706 15.077 12.4803C15.1314 12.3129 15.1314 12.1327 15.077 11.9653C15.0152 11.775 14.8502 11.61 14.5202 11.28L8.74546 5.50529C8.41544 5.17527 8.25044 5.01026 8.06016 4.94844C7.89279 4.89406 7.7125 4.89406 7.54514 4.94844C7.35486 5.01026 7.18985 5.17527 6.85984 5.50529L4.73852 7.62661C3.74848 8.61665 3.25346 9.11167 3.06799 9.68249C2.90484 10.1846 2.90484 10.7255 3.06799 11.2276C3.25346 11.7984 3.74848 12.2934 4.73852 13.2835Z"
        stroke="currentColor"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_18543_349607">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
