import useUserStore from '../user.store';
import { useWatchAfterInit } from '~/infrastructure';

/**
 * Call callback function after user.selected.currency param is changed
 *
 * @param callback - callback function to call
 *
 */
export default function useOnChangeCurrency(callback: () => void): void {
  const userStore = useUserStore();

  useWatchAfterInit(() => userStore.user?.selected.currency, callback);
}
