<template>
  <UiApp>
    <RouterView v-slot="{ Component }">
      <template v-if="Component">
        <Suspense>
          <AsyncComponent :inner-component="Component" />
          <template #fallback>
            <AppPreloader />
          </template>
        </Suspense>
      </template>
    </RouterView>
  </UiApp>
  <UiSnackbar top />
  <DevModeInfo />
</template>

<script setup lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue';
import { UiApp, UiSnackbar } from '~/ui';
import { RouterView, DevModeInfo, useGetDataByDomain } from '~/infrastructure';
import AppPreloader from '~/app/layouts/AppPreloader.vue';

const { updateFavicon } = useGetDataByDomain();
updateFavicon();

const AsyncComponent = defineAsyncComponent(async () => {
  return defineComponent({
    props: { innerComponent: { type: Object, default: null } },
    render() {
      return this.innerComponent;
    },
  });
});
</script>
