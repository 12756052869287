import { type UserInfo, type UserRepository } from '~/domains/user';
import { type NotificationService } from '~/domains/core';

type ReturnUseCase = (dependencies: {
  notifier?: NotificationService;
  userRepository: UserRepository;
  storage?: {
    setUser: (userInfo: UserInfo) => void;
  };
}) => Promise<UserInfo | undefined>;

const getUserInfoUseCase: ReturnUseCase = async ({
  notifier,
  userRepository,
  storage,
}) => {
  try {
    const response = await userRepository.getUser();

    if (response !== undefined) {
      storage?.setUser(response);
    }

    return response;
  } catch (e) {
    notifier?.notifyError('Something went wrong');
    throw e;
  }
};

export default getUserInfoUseCase;
