import i18nRepository from './i18n.repository';
import { type Translates } from './types.d';

export default {
  async getLocalTranslates(): Promise<Record<string, string>> {
    return await i18nRepository.fetchLocalTranslates();
  },

  async getTranslates(shop: string, lang: string): Promise<Translates> {
    try {
      return await i18nRepository.fetchTranslates(shop, lang);
    } catch (error) {
      return await this.getLocalTranslates();
    }
  },
};
