import api from '~/infrastructure/api/api.service';
import userChangeRequestSerializer from './serializers/user-change-request.serializer';
import {
  type UserInfoResponse,
  type UserPreferencesResponse,
  type UserChangeResponse,
} from './types.d';
import userChangeInfoSerializer from '~/domains/user/serializers/user-change-info.serializer';
import { type ChangePasswordResponse } from '~/domains/auth/types.d';
import {
  type UserSelected,
  type UserInfo,
  type UserChangeUserInfo,
  type UserPreferences,
  type UserChange,
  userChangeSerializer,
} from '~/domains/user';
import userInfoSerialiser from './user.serializer';
import userPreferencesDeserializer from '~/domains/user/deserializers/user-preferences.deserializer.ts';
import userPreferencesSerializer from '~/domains/user/serializers/user-preferences.serializer.ts';

const userRepository = {
  async getUser(): Promise<UserInfo> {
    const response: UserInfoResponse = await api('/user/info', {
      method: 'GET',
    });

    return userInfoSerialiser(response);
  },

  async changeUserInfo(body: UserChangeUserInfo): Promise<UserInfo> {
    const response: UserInfoResponse = await api('/user/info', {
      method: 'POST',
      body: userChangeInfoSerializer(body),
    });

    return userInfoSerialiser(response);
  },

  async changePassword(body: FormData): Promise<ChangePasswordResponse> {
    return await api('/user/password/change', {
      method: 'POST',
      body,
    });
  },

  async changeUserSettings(body: UserSelected): Promise<UserChange> {
    const response: UserChangeResponse = await api('/user/change', {
      method: 'POST',
      body: userChangeRequestSerializer(body),
    });

    return userChangeSerializer(response);
  },

  async getUserPreferences(): Promise<UserPreferences> {
    const response: UserPreferencesResponse = await api('/user/preferences', {
      method: 'GET',
    });

    return userPreferencesSerializer(response);
  },

  async changeUserPreferences(body: UserPreferences): Promise<UserPreferences> {
    const response: UserPreferencesResponse = await api('/user/preferences', {
      method: 'POST',
      body: userPreferencesDeserializer(body),
    });

    return userPreferencesSerializer(response);
  },
};

export default userRepository;
