<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3.75"
      y="3.75"
      width="16.5"
      height="16.5"
      rx="3.25"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>
