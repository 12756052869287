import { Price } from '~/utils';
import { type BasketStatisticsResponse } from '../../types.d';
import { type BasketStatistics } from '../../entities';

export default function basketStatisticsSerializer(
  data: BasketStatisticsResponse,
): BasketStatistics {
  return {
    quantity: data.qty,
    vatPercent: data.vat_percent,
    reserveLimit: new Price(data.limit) ?? '0',
    reservedAmount: new Price(data.amount_reserved) ?? '0',
    reservedProducts: data.reserved_qty ?? '0',
    currency: data.currency,
    amount: new Price(data.amount) ?? '0',
    subtotal: new Price(data.amount) ?? '0',
    vat: new Price(data.vat_amount),
    total: new Price(data.amount_wo_vat) ?? '0',
  };
}
