<template>
  <AppPreloader v-if="loading" />
  <template v-else>
    <AppHeader />
    <AppSidebar />
    <AppNavbar />
    <UiMain>
      <ErrorPage v-if="error" v-bind="error" />
      <RouterView v-else :key="route.path" />
      <template #footer>
        <AppFooter />
      </template>
    </UiMain>
  </template>
</template>

<script setup lang="ts">
import { UiMain } from '~/ui';
import { AppFooter } from '~/app/layouts/footer';
import { AppHeader } from '~/app/layouts//header';
import { AppNavbar } from '~/app/layouts/navbar';
import { AppSidebar } from '~/app/layouts/sidebar';
import AppPreloader from '~/app/layouts/AppPreloader.vue';
import {
  RouterView,
  useRoute,
  ErrorPage,
  useError,
  useDefaultLayout,
} from '~/infrastructure';

const { error } = useError();
const route = useRoute();
const { loading, initRequiredRequests } = useDefaultLayout();

initRequiredRequests();
</script>
