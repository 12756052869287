import api from '~/infrastructure/api/api.service';
import { ofetch } from 'ofetch';
import { type Translates } from './types.d';

export default {
  async fetchTranslates(shop: string, lang: string): Promise<Translates> {
    const response = await api<Translates>(`/nls/js/${shop}/${lang}`, {
      method: 'GET',
    });

    return response;
  },

  async fetchLocalTranslates(): Promise<Record<string, string>> {
    const response = await ofetch('/json/en.json', {
      method: 'GET',
    });

    return response;
  },
};
