<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.65 1C18.009 1 18.3 1.29101 18.3 1.65V4H20.65C21.009 4 21.3 4.29101 21.3 4.65C21.3 5.00899 21.009 5.3 20.65 5.3H18.3V7.65C18.3 8.00899 18.009 8.3 17.65 8.3C17.291 8.3 17 8.00899 17 7.65V5.3H14.65C14.291 5.3 14 5.00899 14 4.65C14 4.29101 14.291 4 14.65 4H17V1.65C17 1.29101 17.291 1 17.65 1ZM6.65 5.3C6.4567 5.3 6.3 5.4567 6.3 5.65V19.65C6.3 19.8433 6.4567 20 6.65 20H16.65C16.8433 20 17 19.8433 17 19.65V10.65C17 10.291 17.291 10 17.65 10C18.009 10 18.3 10.291 18.3 10.65V19.65C18.3 20.5613 17.5613 21.3 16.65 21.3H6.65C5.73873 21.3 5 20.5613 5 19.65V5.65C5 4.73873 5.73873 4 6.65 4H11.65C12.009 4 12.3 4.29102 12.3 4.65C12.3 5.00899 12.009 5.3 11.65 5.3H6.65ZM8.65 8C8.29102 8 8 8.29102 8 8.65C8 9.00898 8.29102 9.3 8.65 9.3H14.65C15.009 9.3 15.3 9.00898 15.3 8.65C15.3 8.29102 15.009 8 14.65 8H8.65ZM8.65 12C8.29102 12 8 12.291 8 12.65C8 13.009 8.29102 13.3 8.65 13.3H14.65C15.009 13.3 15.3 13.009 15.3 12.65C15.3 12.291 15.009 12 14.65 12H8.65ZM8.65 16C8.29102 16 8 16.291 8 16.65C8 17.009 8.29102 17.3 8.65 17.3H14.65C15.009 17.3 15.3 17.009 15.3 16.65C15.3 16.291 15.009 16 14.65 16H8.65Z"
      fill="currentColor"
    />
  </svg>
</template>
