<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M17.4571 6.81872C17.336 6.90872 15.1976 8.06284 15.1976 10.6291C15.1976 13.5974 17.9186 14.6475 18 14.6735C17.9875 14.7375 17.5677 16.1117 16.5654 17.5118C15.6717 18.744 14.7382 19.974 13.3183 19.974C11.8983 19.974 11.5329 19.184 9.89363 19.184C8.29615 19.184 7.72816 20 6.4293 20C5.13044 20 4.22418 18.8599 3.18217 17.4598C1.97519 15.8157 1 13.2614 1 10.8371C1 6.94873 3.63948 4.88652 6.23719 4.88652C7.61749 4.88652 8.76809 5.75461 9.63468 5.75461C10.4595 5.75461 11.7458 4.83451 13.3162 4.83451C13.9113 4.83451 16.0496 4.88652 17.4571 6.81872ZM12.5707 3.18833C13.2201 2.45025 13.6795 1.42615 13.6795 0.402042C13.6795 0.260027 13.667 0.116011 13.6398 0C12.5832 0.0380035 11.3261 0.674071 10.5681 1.51616C9.97297 2.16422 9.41751 3.18833 9.41751 4.22644C9.41751 4.38245 9.44465 4.53848 9.45718 4.58848C9.52402 4.60049 9.6326 4.61448 9.74119 4.61448C10.6892 4.61448 11.8816 4.00642 12.5707 3.18833Z"
      fill="currentColor"
    />
  </svg>
</template>
