import { useGetBasketStatsAdapter } from '../../infrastructure';
import { useOnChangeCurrency } from '~/domains/user';

export default function useBasketStats(): {
  getBasketStats: () => Promise<void>;
} {
  const { getBasketStats } = useGetBasketStatsAdapter();

  useOnChangeCurrency(() => {
    getBasketStats();
  });

  return {
    getBasketStats,
  };
}
