<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.04036 7.04048C9.2942 6.78664 9.70575 6.78664 9.95959 7.04048L14.9596 12.0405C15.2134 12.2943 15.2134 12.7059 14.9596 12.9597L9.95959 17.9597C9.70575 18.2136 9.2942 18.2136 9.04036 17.9597C8.78652 17.7059 8.78652 17.2943 9.04036 17.0405L13.5807 12.5001L9.04036 7.95972C8.78652 7.70588 8.78652 7.29432 9.04036 7.04048Z"
      fill="currentColor"
    />
  </svg>
</template>
