import {
  type AccountStatementDetailsResponse,
  AccountStatementDetailsLineCondition,
} from '../types.d';

export const invoiceDetailsMock: AccountStatementDetailsResponse = {
  id: '231009131422986353',
  total: '5524.69',
  vat_percent: '23.00',
  vat_value: '1033.07',
  so_number: 'APL56751 ',
  is_owner: true,
  row_so_total: '4491.62',
  row_txt_total: '0.00',
  lines: [
    {
      line_number: 1,
      article: 'MQ0T3HX/A ',
      sku: 'ZKMQ0T3HXA ',
      description: 'iPhone 14 Pro 256GB Space Black,Model A2890',
      qty: 1,
      price: 4491.62,
      sum: 4491.62,
      currency: 'PLN',
      element_id: 220908083450625504,
      condition: AccountStatementDetailsLineCondition.BRAND_NEW,
    },
  ],
  owner: true,
};
