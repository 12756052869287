import {
  type ProductActionIconType,
  type ProductGrade,
  type ProductGradeResponse,
} from './types.d';

export const FILTER_WIDTH = 250;
export const DEFAULT_PRODUCT_ACTION_COLOR = '#9EC272';

export const PRODUCT_ACTION_TYPES_MAP: Record<
  ProductActionIconType,
  { color: string; label: string }
> = {
  HOT: {
    color: '#E56862',
    label: 'HOT',
  },
  SALE: {
    color: '#E56862',
    label: 'SALE',
  },
  SPECIAL_OFFER: {
    color: '#E56862',
    label: 'SPECIAL_OFFER',
  },
  NEW: {
    color: '#9EC272',
    label: 'NEW',
  },
  COMN: {
    color: '#9EC272',
    label: 'COMN',
  },
  FRAR: {
    color: '#9EC272',
    label: 'FRAR',
  },
  OTHER: {
    color: '#9EC272',
    label: 'OTHER',
  },
  SUPER_PRICE: {
    color: '#826EC5',
    label: 'SUPER_PRICE',
  },
  GIFT: {
    color: '#9EC272',
    label: 'GIFT',
  },
  NONE: {
    color: '#9EC272',
    label: 'NONE',
  },
  QUDIS: {
    color: '#826EC5',
    label: 'QUDIS',
  },
  ROOT: {
    color: '#9EC272',
    label: '',
  },
  EXCLUSIVE: {
    color: '#9EC272',
    label: 'EXCLUSIVE',
  },
};

export const SEPARATOR_HEADER_ROW = 'separator-header-row';

export const PRODUCT_GRADE_MAP: Record<ProductGradeResponse, ProductGrade> = {
  Q: 'A+',
  N: 'BN',
  S: 'S',
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
};
