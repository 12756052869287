<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.54048 4.54043C5.75957 4.32134 6.10285 4.28735 6.36065 4.45921L9.1969 6.35005H14.8033L17.6395 4.45921C17.8973 4.28735 18.2406 4.32134 18.4597 4.54043L21.4597 7.54043C21.7136 7.79427 21.7136 8.20583 21.4597 8.45967L18.6501 11.2693V18C18.6501 19.4636 17.4637 20.65 16.0001 20.65H8.0001C6.53654 20.65 5.3501 19.4636 5.3501 18V11.2693L2.54048 8.45967C2.41858 8.33777 2.3501 8.17244 2.3501 8.00005C2.3501 7.82766 2.41858 7.66233 2.54048 7.54043L5.54048 4.54043ZM6.6501 11.65V18C6.6501 18.7456 7.25451 19.35 8.0001 19.35H16.0001C16.7457 19.35 17.3501 18.7456 17.3501 18V11.65H6.6501ZM18.0704 10.0105L20.0809 8.00005L17.9173 5.83646L15.9324 7.15974L18.0704 10.0105ZM14.6751 7.65005H9.3251L7.3001 10.35H16.7001L14.6751 7.65005ZM5.92977 10.0105L8.06783 7.15974L6.08292 5.83646L3.91934 8.00005L5.92977 10.0105Z"
      fill="currentColor"
    />
  </svg>
</template>
