<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.0002 2.3501C3.08893 2.3501 2.3502 3.08883 2.3502 4.0001V9.0001C2.3502 9.91137 3.08893 10.6501 4.0002 10.6501H20.0002C20.9115 10.6501 21.6502 9.91137 21.6502 9.0001V4.0001C21.6502 3.08883 20.9115 2.3501 20.0002 2.3501H4.0002ZM3.6502 4.0001C3.6502 3.8068 3.8069 3.6501 4.0002 3.6501H20.0002C20.1935 3.6501 20.3502 3.8068 20.3502 4.0001V9.0001C20.3502 9.1934 20.1935 9.3501 20.0002 9.3501H4.0002C3.8069 9.3501 3.6502 9.1934 3.6502 9.0001V4.0001ZM4.0001 13.35C3.08883 13.35 2.3501 14.0887 2.3501 15V20C2.3501 20.9113 3.08883 21.65 4.0001 21.65H20.0001C20.9114 21.65 21.6501 20.9113 21.6501 20V15C21.6501 14.0887 20.9114 13.35 20.0001 13.35H4.0001ZM3.6501 15C3.6501 14.8067 3.8068 14.65 4.0001 14.65H20.0001C20.1934 14.65 20.3501 14.8067 20.3501 15V20C20.3501 20.1933 20.1934 20.35 20.0001 20.35H4.0001C3.8068 20.35 3.6501 20.1933 3.6501 20V15Z"
      fill="currentColor"
    />
  </svg>
</template>
