import { type Product } from '~/domains/product';
import { CompareModes, type CompareRequest } from '~/domains/compare';

export default function compareAddProductsDeserializer(
  products: Product[],
): CompareRequest {
  const newLines = products.reduce<CompareRequest['lines']>(
    (lines, product) => {
      lines.push({
        article: product.sku,
        mode: CompareModes.ADD,
      });
      return lines;
    },
    [],
  );
  return {
    lines: newLines,
  };
}
