import { type CategoryBySlugResponse, type BrandById } from '../types';
import categoryBySlugSerializer from './category-by-slug.serializer';
import brandByIdBrandSerializer from './brand-by-id-brand.serializer';

export default function brandByIdSerializer(
  data: CategoryBySlugResponse,
): BrandById {
  return {
    ...categoryBySlugSerializer(data),
    brand: brandByIdBrandSerializer(data?.filter?.brand),
  };
}
