import { useAuthStore, PATH_DICTIONARY } from '~/domains/auth';

export default (to: {
  path: string;
  query: {
    token: string;
    redirect?: string;
  };
}): string | undefined => {
  if (
    to.path === PATH_DICTIONARY.loginByToken &&
    to.query.token !== undefined
  ) {
    const token = to.query.token;
    const { setAuthToken } = useAuthStore();

    try {
      setAuthToken(token);
      return to.query.redirect ?? PATH_DICTIONARY.home;
    } catch (error) {
      console.error(error);
      return PATH_DICTIONARY.login;
    }
  }
};
