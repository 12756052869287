import CountryWithFlagJSON from '../data/countryWithFlags.json';
import {
  type CountryWithFlagRepository,
  type CountryWithFlag,
} from '~/domains/core';

const countryWithFlagLocalRepository: CountryWithFlagRepository = {
  async getCountryWithFlag(): Promise<CountryWithFlag[]> {
    return CountryWithFlagJSON;
  },
};

export default countryWithFlagLocalRepository;
