<template>
  <VChip
    ref="chipRef"
    :model-value="modelValue"
    :size="size"
    :to="to"
    :rounded="rounded"
    :class="classes"
    :variant="variant"
    :closable="closable"
    :append-icon="appendIcon"
    :prepend-icon="prependIcon"
    :disabled="disabled"
    style="transition: all 0.2s"
    class="chip"
    @update:model-value="$emit('update:modelValue', $event)"
    @click:close="$emit('clickClose')"
  >
    <template #close>
      <UiSheet
        v-if="closable"
        rounded="pill"
        :color="isHovered ? 'blue-500' : 'transparent'"
        width="16"
        height="16"
        class="d-flex align-center justify-center"
        style="transition: all 0.2s"
      >
        <UiIcon
          icon="$close"
          size="12"
          :color="isHovered ? 'white' : 'grey-600'"
          style="transition: all 0.2s"
        />
      </UiSheet>
    </template>
    <slot></slot>
  </VChip>
</template>

<script setup lang="ts">
import { VChip } from 'vuetify/components/VChip';
import { UiIcon, UiSheet } from '~/ui';
import { computed, ref } from 'vue';
import { useElementHover } from '@vueuse/core';
import { type Size } from '~/infrastructure';

export interface Props {
  modelValue?: any;
  textColor?: string;
  bgColor?: string;
  size?: Size;
  to?: string;
  rounded?: string | boolean;
  closable?: boolean;
  variant?: 'text' | 'flat' | 'elevated' | 'tonal' | 'outlined' | 'plain';
  appendIcon?: string;
  prependIcon?: string;
  disableTextHover?: boolean;
  disabled?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  size: 'default',
  textColor: 'black',
  bgColor: 'grey-50',
  variant: 'tonal',
  to: undefined,
  rounded: undefined,
  closable: false,
  appendIcon: undefined,
  prependIcon: undefined,
  disableTextHover: true,
  modelValue: undefined,
  disabled: false,
});
defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'clickClose'): void;
}>();

const chipRef = ref();
const isHovered = useElementHover(chipRef);
const classes = computed(
  () =>
    `bg-${props.bgColor} text-${
      !props.disableTextHover && isHovered.value
        ? 'blue-500 text-decoration-line-through'
        : props.textColor
    }`,
);
</script>

<style lang="scss" scoped>
.chip {
  &.v-chip--size-x-small {
    --v-chip-size: 14px;
    --v-chip-height: 14px;
    font-size: 10px;
    padding: 0 7px;
    border-radius: 100px;

    :deep(.v-icon--start) {
      margin-left: 0;
      margin-right: 4px;
    }
  }

  &.v-chip--size-small {
    --v-chip-size: 20px;
    --v-chip-height: 20px;
    font-size: 12px;
    padding: 0 8px;
    border-radius: 100px;
  }

  :deep(.v-chip__underlay),
  :deep(.v-chip__overlay) {
    color: transparent;
  }

  :deep(.v-chip__close),
  :deep(.v-chip__append) {
    color: rgb(var(--v-theme-primary));
  }

  :deep(.v-chip__append .v-icon) {
    width: 14px;
  }
}
</style>
