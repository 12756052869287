import { type ProductType, type ProductTypeResponse } from '../types';

export default function productTypeSerializer(
  data: ProductTypeResponse,
): ProductType {
  return {
    id: data.id,
    name: data.name,
    quantity: data.qty,
    selected: data.selected,
  };
}
