<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="100"
    height="100"
    viewBox="0 0 48 48"
  >
    <path
      fill="#fff"
      d="M25.8,15.6H14.2c-2,0-3.7,1.7-3.7,3.7v9.5c0,2,1.7,3.7,3.7,3.7h11.6c2,0,3.7-1.7,3.7-3.7v-9.5	C29.5,17.2,27.8,15.6,25.8,15.6z"
    ></path>
    <path
      fill="#fff"
      d="M34.9,16.4L31,19.8c-0.2,0.2-0.4,0.5-0.4,0.8v6.7c0,0.3,0.1,0.6,0.4,0.8l3.9,3.5c0.9,0.8,2.4,0.2,2.4-1.1V17.5	C37.3,16.2,35.9,15.5,34.9,16.4z"
    ></path>
    <path
      fill="#0f0"
      d="M35,6H13c-3.9,0-7,3.1-7,7v22c0,3.9,3.1,7,7,7h22c3.9,0,7-3.1,7-7V13C42,9.1,38.9,6,35,6z M29,28.5	c0,1.9-1.6,3.5-3.5,3.5h-11c-1.9,0-3.5-1.6-3.5-3.5v-9c0-1.9,1.6-3.5,3.5-3.5h11c1.9,0,3.5,1.6,3.5,3.5V28.5z M37,29.9	c0,1.1-1.3,1.7-2.2,1l-3.5-3.1C31.1,27.6,31,27.3,31,27v-6c0-0.3,0.1-0.6,0.3-0.7l3.5-3.1c0.8-0.7,2.2-0.2,2.2,1V29.9z"
    ></path>
  </svg>
</template>
