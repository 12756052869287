import {
  useBasketStore,
  getBasketStatsUseCase,
  basketRepository,
} from '~/domains/basket';

export default function useGetBasketLinesAdapter(): {
  getBasketStats: () => Promise<void>;
} {
  const { updateBasketStats } = useBasketStore();

  const getBasketStats: () => Promise<void> = async () => {
    await getBasketStatsUseCase({
      basketRepository,
      storage: {
        updateBasketStats,
      },
    });
  };

  return {
    getBasketStats,
  };
}
