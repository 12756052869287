import { type ProductInfoResponse } from '../../types';
import type ProductInfo from '~/domains/product/entities/ProductInfo';
import productSerializer from '../../serializers/product.serializer';
import productSpecificationSerializer from './product-specification.serializer';
import productImagesSerializer from './product-images.serializer';
import productCertificateSerializer from './product-certificate.serializer';
import productLineManagerSerializer from './product-line-manager.serializer';
import productKavsSerializer from './product-kavs.serializer';
import productVideoSerializer from './product-video.serializer';
import productLinkSerializer from './product-link.serializer';

export default function productDataSerializer(
  productPage: ProductInfoResponse,
): ProductInfo {
  return {
    product: productSerializer(productPage.product),
    products:
      productPage.products !== undefined
        ? productPage.products.map((product) => productSerializer(product))
        : [],
    specification:
      productPage.specification !== undefined
        ? productSpecificationSerializer(productPage.specification)
        : null,
    description: productPage.description ?? null,
    content: productPage.content ?? null,
    features: productPage.features ?? null,
    documents: productPage.documents ?? null,
    certificates:
      productPage.certificates !== undefined
        ? productPage.certificates.map((item) =>
            productCertificateSerializer(item),
          )
        : null,
    images:
      productPage.images !== undefined
        ? productImagesSerializer(productPage.images)
        : null,
    lineManager:
      productPage.plm !== undefined
        ? productLineManagerSerializer(productPage.plm)
        : null,
    keyAttributes:
      productPage.kavs
        // TODO: remove filter after add functional on backend
        ?.filter((kav) => kav.values.length > 1)
        ?.map((kav) => productKavsSerializer(kav)) ?? [],
    videos:
      productPage.videos !== undefined
        ? productPage.videos.map((item) => productVideoSerializer(item))
        : null,
    links:
      productPage.links !== undefined
        ? productPage.links.map((item) => productLinkSerializer(item))
        : null,
  };
}
