import {
  type CategoryResponse,
  type Category,
  type CategoryRequestQuery,
} from './types.d';
import { productFilterDeserializer } from '../product';
import { pagerDeserializer } from '~/infrastructure';
import productSortDeserializer from '~/domains/product/deserializers/product-sort.deserializer.ts';

const categorySerialiser = {
  category(data: CategoryResponse): Category {
    return {
      id: data.id,
      parentId: data.pid,
      sort: data.sort,
      slug: data.slug,
      name: data.name,
      children: data.children?.map((child) => this.category(child)),
      quantity: data.qty,
    };
  },

  categoryBySlugQuery(params: CategoryRequestQuery) {
    return {
      ...productFilterDeserializer(params.filter),
      ...pagerDeserializer(params.pager),
      ...productSortDeserializer(params.sort),
    };
  },
};

export default categorySerialiser;
