import { useProductGrade } from '~/domains/product';

interface ProductForceResetTableReturn {
  handleNeedResetTable: () => void;
  getNeedResetTableFromLocalStorage: () => string | null;
  setValueOfNeedResetTableFromLocalStorage: (value: boolean) => void;
}

export default function (): ProductForceResetTableReturn {
  const { showGradeColumns } = useProductGrade();

  const getNeedResetTableFromLocalStorage = (): string | null => {
    return localStorage.getItem('needToResetCatalogTable');
  };

  const setValueOfNeedResetTableFromLocalStorage = (value: boolean): void => {
    localStorage.setItem('needToResetCatalogTable', String(value));
  };

  const handleNeedResetTable = (): void => {
    const needResetTable = getNeedResetTableFromLocalStorage();

    if (needResetTable == null && showGradeColumns.value === true) {
      setValueOfNeedResetTableFromLocalStorage(true);
    }
  };

  return {
    handleNeedResetTable,
    getNeedResetTableFromLocalStorage,
    setValueOfNeedResetTableFromLocalStorage,
  };
}
