import {
  type ProductFilterResponse,
  type ProductFilterType,
  type ProductNodeResponse,
  type ProductNode,
  type ProductSubnodeResponse,
  type ProductSubnode,
  type ProductActionFilter,
  type ProductFilterActionResponse,
  type ProductFilterVarious,
  type ProductFilterVariousResponse,
} from '../types.d';
import { type BrandResponse, type Brand } from '~/domains/category';
import productTypeSerializer from './product-type.serializer';
import productKavsSerializer from '../infrastructure/serializers/product-kavs.serializer';

const nodeSerializer = function (data: ProductNodeResponse): ProductNode {
  return {
    id: data.id?.toString(),
    name: data.name,
    quantity: data.qty,
    selected: data.selected,
    children: data.children?.map((child) => nodeSerializer(child)) ?? [],
  };
};

const subnodeSerializer = function (
  data: ProductSubnodeResponse,
): ProductSubnode {
  return {
    id: data.id?.toString(),
    name: data.name,
    quantity: data.qty,
    selected: data.selected,
  };
};

const brandSerializer = function (data: BrandResponse): Brand {
  return {
    id: data.id,
    name: data.name,
    slug: data.slug,
    quantity: data.qty,
    selected: data.selected,
    children: data.children?.map((child) => brandSerializer(child)) ?? [],
    root: data.root !== undefined ? brandSerializer(data.root) : undefined,
  };
};

const actionSerializer = function (
  data: ProductFilterActionResponse,
): ProductActionFilter {
  return {
    type: data.type,
    quantity: data.qty,
    selected: data.selected,
  };
};

const variousSerializer = function (
  data: ProductFilterVariousResponse,
): ProductFilterVarious {
  return {
    type: data.type,
    values: data.values.map((value) => ({
      value: value.value,
      quantity: value.qty,
      selected: value.selected,
    })),
  };
};

export default function productFilterSerializer(
  data: ProductFilterResponse,
): ProductFilterType {
  return {
    categoryId: data.nid,
    brandId: data.brand_id ?? null,
    stock:
      data.stock?.map((item) => {
        return {
          kind: item.kind,
          quantity: item.qty,
          selected: item.selected,
        };
      }) ?? [],
    nodes: data.nodes?.map((node) => nodeSerializer(node)) ?? [],
    subnodes: data.subnodes?.map((subnode) => subnodeSerializer(subnode)) ?? [],
    brands: data.brands?.map((brand) => brandSerializer(brand)) ?? [],
    productTypes:
      data.pts?.map((productType) => productTypeSerializer(productType)) ?? [],
    keyAttributes: data.kavs?.map((kav) => productKavsSerializer(kav)) ?? [],
    price: {
      min: data.price?.min ?? '0',
      max: data.price?.max ?? '1000',
    },
    action: data.action,
    actions: data.actions?.map((action) => actionSerializer(action)) ?? [],
    various: data.various?.map((various) => variousSerializer(various)) ?? [],
  };
}
