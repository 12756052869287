export const LOCALE_FORMAT_MAP: Record<string, string> = {
  'aa-DJ': 'DD/MM/YYYY',
  'aa-ER': 'DD/MM/YYYY',
  'aa-ET': 'DD/MM/YYYY',
  af: 'YYYY-MM-DD',
  'af-NA': 'YYYY-MM-DD',
  'af-ZA': 'YYYY-MM-DD',
  'agq-CM': 'DD/MM/YYYY',
  'ak-GH': 'YYYY/MM/DD',
  am: 'DD/MM/YYYY',
  'am-ET': 'DD/MM/YYYY',
  ar: 'DD/MM/YYYY',
  'ar-001': 'DD/MM/YYYY',
  'ar-AE': 'DD/MM/YYYY',
  'ar-BH': 'DD/MM/YYYY',
  'ar-DJ': 'DD/MM/YYYY',
  'ar-DZ': 'DD-MM-YYYY',
  'ar-EG': 'DD/MM/YYYY',
  'ar-ER': 'DD/MM/YYYY',
  'ar-IL': 'DD/MM/YYYY',
  'ar-IQ': 'DD/MM/YYYY',
  'ar-JO': 'DD/MM/YYYY',
  'ar-KM': 'DD/MM/YYYY',
  'ar-KW': 'DD/MM/YYYY',
  'ar-LB': 'DD/MM/YYYY',
  'ar-LY': 'DD/MM/YYYY',
  'ar-MA': 'DD-MM-YYYY',
  'ar-MR': 'DD/MM/YYYY',
  'ar-OM': 'DD/MM/YYYY',
  'ar-PS': 'DD/MM/YYYY',
  'ar-QA': 'DD/MM/YYYY',
  'ar-SA': 'DD/MM/YYYY',
  'ar-SD': 'DD/MM/YYYY',
  'ar-SO': 'DD/MM/YYYY',
  'ar-SS': 'DD/MM/YYYY',
  'ar-SY': 'DD/MM/YYYY',
  'ar-TD': 'DD/MM/YYYY',
  'ar-TN': 'DD-MM-YYYY',
  'ar-YE': 'DD/MM/YYYY',
  'arn-CL': 'DD-MM-YYYY',
  as: 'DD-MM-YYYY',
  'as-IN': 'DD-MM-YYYY',
  'asa-TZ': 'DD/MM/YYYY',
  'ast-ES': 'DD/MM/YYYY',
  az: 'DD.MM.YYYY',
  'az-Cyrl-AZ': 'DD.MM.YYYY',
  'az-Latn-AZ': 'DD.MM.YYYY',
  ba: 'DD.MM.YYYY',
  'ba-RU': 'DD.MM.YYYY',
  'bas-CM': 'DD/MM/YYYY',
  be: 'DD.MM.YYYY',
  'be-BY': 'DD.MM.YYYY',
  'bem-ZM': 'DD/MM/YYYY',
  'bez-TZ': 'DD/MM/YYYY',
  bg: 'DD.MM.YYYY',
  'bg-BG': 'DD.MM.YYYY',
  'bin-NG': 'DD/MM/YYYY',
  bm: 'DD/MM/YYYY',
  'bm-Latn-ML': 'DD/MM/YYYY',
  bn: 'DD/MM/YYYY',
  'bn-BD': 'DD/MM/YYYY',
  'bn-IN': 'DD-MM-YYYY',
  bo: 'YYYY/MM/DD',
  'bo-CN': 'YYYY/MM/DD',
  'bo-IN': 'YYYY-MM-DD',
  br: 'DD/MM/YYYY',
  'br-FR': 'DD/MM/YYYY',
  'brx-IN': 'MM/DD/YYYY',
  bs: 'DD.MM.YYYY',
  'bs-Cyrl-BA': 'DD.MM.YYYY',
  'bs-Latn-BA': 'DD.MM.YYYY',
  'byn-ER': 'DD/MM/YYYY',
  ca: 'DD/MM/YYYY',
  'ca-AD': 'DD/MM/YYYY',
  'ca-ES': 'DD/MM/YYYY',
  'ca-ES-valencia': 'DD/MM/YYYY',
  'ca-FR': 'DD/MM/YYYY',
  'ca-IT': 'DD/MM/YYYY',
  'ce-RU': 'YYYY-MM-DD',
  'cgg-UG': 'DD/MM/YYYY',
  'chr-Cher-US': 'MM/DD/YYYY',
  co: 'DD/MM/YYYY',
  'co-FR': 'DD/MM/YYYY',
  'cs-CZ': 'DD.MM.YYYY',
  cu: 'YYYY.MM.DD',
  'cu-RU': 'YYYY.MM.DD',
  cy: 'DD/MM/YYYY',
  'cy-GB': 'DD/MM/YYYY',
  'da-DK': 'DD-MM-YYYY',
  'da-GL': 'DD/MM/YYYY',
  'dav-KE': 'DD/MM/YYYY',
  de: 'DD.MM.YYYY',
  'de-AT': 'DD.MM.YYYY',
  'de-BE': 'DD.MM.YYYY',
  'de-CH': 'DD.MM.YYYY',
  'de-DE': 'DD.MM.YYYY',
  'de-IT': 'DD.MM.YYYY',
  'de-LI': 'DD.MM.YYYY',
  'de-LU': 'DD.MM.YYYY',
  'dje-NE': 'DD/MM/YYYY',
  'dsb-DE': 'DD.MM.YYYY',
  'dua-CM': 'DD/MM/YYYY',
  'dv-MV': 'DD/MM/YYYY',
  'dyo-SN': 'DD/MM/YYYY',
  dz: 'YYYY-MM-DD',
  'dz-BT': 'YYYY-MM-DD',
  'ebu-KE': 'DD/MM/YYYY',
  ee: 'MM/DD/YYYY',
  'ee-GH': 'MM/DD/YYYY',
  'ee-TG': 'MM/DD/YYYY',
  'el-CY': 'DD/MM/YYYY',
  'el-GR': 'DD/MM/YYYY',
  'en-001': 'DD/MM/YYYY',
  'en-029': 'DD/MM/YYYY',
  'en-150': 'DD/MM/YYYY',
  'en-AG': 'DD/MM/YYYY',
  'en-AI': 'DD/MM/YYYY',
  'en-AS': 'MM/DD/YYYY',
  'en-AT': 'DD/MM/YYYY',
  'en-AU': 'DD/MM/YYYY',
  'en-BB': 'DD/MM/YYYY',
  'en-BE': 'DD/MM/YYYY',
  'en-BI': 'MM/DD/YYYY',
  'en-BM': 'DD/MM/YYYY',
  'en-BS': 'DD/MM/YYYY',
  'en-BW': 'DD/MM/YYYY',
  'en-BZ': 'DD/MM/YYYY',
  'en-CA': 'YYYY-MM-DD',
  'en-CC': 'DD/MM/YYYY',
  'en-CH': 'DD/MM/YYYY',
  'en-CK': 'DD/MM/YYYY',
  'en-CM': 'DD/MM/YYYY',
  'en-CX': 'DD/MM/YYYY',
  'en-CY': 'DD/MM/YYYY',
  'en-DE': 'DD/MM/YYYY',
  'en-DK': 'DD/MM/YYYY',
  'en-DM': 'DD/MM/YYYY',
  'en-ER': 'DD/MM/YYYY',
  'en-FI': 'DD/MM/YYYY',
  'en-FJ': 'DD/MM/YYYY',
  'en-FK': 'DD/MM/YYYY',
  'en-FM': 'DD/MM/YYYY',
  'en-GB': 'DD/MM/YYYY',
  'en-GD': 'DD/MM/YYYY',
  'en-GG': 'DD/MM/YYYY',
  'en-GH': 'DD/MM/YYYY',
  'en-GI': 'DD/MM/YYYY',
  'en-GM': 'DD/MM/YYYY',
  'en-GU': 'MM/DD/YYYY',
  'en-GY': 'DD/MM/YYYY',
  'en-HK': 'DD/MM/YYYY',
  'en-ID': 'DD/MM/YYYY',
  'en-IE': 'DD/MM/YYYY',
  'en-IL': 'DD/MM/YYYY',
  'en-IM': 'DD/MM/YYYY',
  'en-IN': 'DD-MM-YYYY',
  'en-IO': 'DD/MM/YYYY',
  'en-JE': 'DD/MM/YYYY',
  'en-JM': 'DD/MM/YYYY',
  'en-KE': 'DD/MM/YYYY',
  'en-KI': 'DD/MM/YYYY',
  'en-KN': 'DD/MM/YYYY',
  'en-KY': 'DD/MM/YYYY',
  'en-LC': 'DD/MM/YYYY',
  'en-LR': 'DD/MM/YYYY',
  'en-LS': 'DD/MM/YYYY',
  'en-MG': 'DD/MM/YYYY',
  'en-MH': 'MM/DD/YYYY',
  'en-MO': 'DD/MM/YYYY',
  'en-MP': 'MM/DD/YYYY',
  'en-MS': 'DD/MM/YYYY',
  'en-MT': 'DD/MM/YYYY',
  'en-MU': 'DD/MM/YYYY',
  'en-MW': 'DD/MM/YYYY',
  'en-MY': 'DD/MM/YYYY',
  'en-NA': 'DD/MM/YYYY',
  'en-NF': 'DD/MM/YYYY',
  'en-NG': 'DD/MM/YYYY',
  'en-NL': 'DD/MM/YYYY',
  'en-NR': 'DD/MM/YYYY',
  'en-NU': 'DD/MM/YYYY',
  'en-NZ': 'DD/MM/YYYY',
  'en-PG': 'DD/MM/YYYY',
  'en-PH': 'DD/MM/YYYY',
  'en-PK': 'DD/MM/YYYY',
  'en-PN': 'DD/MM/YYYY',
  'en-PR': 'MM/DD/YYYY',
  'en-PW': 'DD/MM/YYYY',
  'en-RW': 'DD/MM/YYYY',
  'en-SB': 'DD/MM/YYYY',
  'en-SC': 'DD/MM/YYYY',
  'en-SD': 'DD/MM/YYYY',
  'en-SE': 'YYYY-MM-DD',
  'en-SG': 'DD/MM/YYYY',
  'en-SH': 'DD/MM/YYYY',
  'en-SI': 'DD/MM/YYYY',
  'en-SL': 'DD/MM/YYYY',
  'en-SS': 'DD/MM/YYYY',
  'en-SX': 'DD/MM/YYYY',
  'en-SZ': 'DD/MM/YYYY',
  'en-TC': 'DD/MM/YYYY',
  'en-TK': 'DD/MM/YYYY',
  'en-TO': 'DD/MM/YYYY',
  'en-TT': 'DD/MM/YYYY',
  'en-TV': 'DD/MM/YYYY',
  'en-TZ': 'DD/MM/YYYY',
  'en-UG': 'DD/MM/YYYY',
  'en-UM': 'MM/DD/YYYY',
  'en-US': 'MM/DD/YYYY',
  'en-VC': 'DD/MM/YYYY',
  'en-VG': 'DD/MM/YYYY',
  'en-VI': 'MM/DD/YYYY',
  'en-VU': 'DD/MM/YYYY',
  'en-WS': 'DD/MM/YYYY',
  'en-ZA': 'YYYY/MM/DD',
  'en-ZM': 'DD/MM/YYYY',
  'en-ZW': 'DD/MM/YYYY',
  'eo-001': 'YYYY-MM-DD',
  es: 'DD/MM/YYYY',
  'es-419': 'DD/MM/YYYY',
  'es-AR': 'DD/MM/YYYY',
  'es-BO': 'DD/MM/YYYY',
  'es-BR': 'DD/MM/YYYY',
  'es-BZ': 'DD/MM/YYYY',
  'es-CL': 'DD-MM-YYYY',
  'es-CO': 'DD/MM/YYYY',
  'es-CR': 'DD/MM/YYYY',
  'es-CU': 'DD/MM/YYYY',
  'es-DO': 'DD/MM/YYYY',
  'es-EC': 'DD/MM/YYYY',
  'es-ES': 'DD/MM/YYYY',
  'es-GQ': 'DD/MM/YYYY',
  'es-GT': 'DD/MM/YYYY',
  'es-HN': 'DD/MM/YYYY',
  'es-MX': 'DD/MM/YYYY',
  'es-NI': 'DD/MM/YYYY',
  'es-PA': 'MM/dd/yyyy',
  'es-PE': 'DD/MM/YYYY',
  'es-PH': 'DD/MM/YYYY',
  'es-PR': 'MM/dd/yyyy',
  'es-PY': 'DD/MM/YYYY',
  'es-SV': 'DD/MM/YYYY',
  'es-US': 'MM/DD/YYYY',
  'es-UY': 'DD/MM/YYYY',
  'es-VE': 'DD/MM/YYYY',
  et: 'DD.MM.YYYY',
  'et-EE': 'DD.MM.YYYY',
  'eu-ES': 'YYYY/MM/DD',
  'ewo-CM': 'DD/MM/YYYY',
  'fa-IR': 'DD/MM/YYYY',
  'ff-CM': 'DD/MM/YYYY',
  'ff-GN': 'DD/MM/YYYY',
  'ff-Latn-SN': 'DD/MM/YYYY',
  'ff-MR': 'DD/MM/YYYY',
  'ff-NG': 'DD/MM/YYYY',
  fi: 'DD.MM.YYYY',
  'fi-FI': 'DD.MM.YYYY',
  'fil-PH': 'MM/DD/YYYY',
  fo: 'DD.MM.YYYY',
  'fo-DK': 'DD.MM.YYYY',
  'fo-FO': 'DD.MM.YYYY',
  fr: 'DD/MM/YYYY',
  'fr-029': 'DD/MM/YYYY',
  'fr-BE': 'DD-MM-YYYY',
  'fr-BF': 'DD/MM/YYYY',
  'fr-BI': 'DD/MM/YYYY',
  'fr-BJ': 'DD/MM/YYYY',
  'fr-BL': 'DD/MM/YYYY',
  'fr-CA': 'YYYY-MM-DD',
  'fr-CD': 'DD/MM/YYYY',
  'fr-CF': 'DD/MM/YYYY',
  'fr-CG': 'DD/MM/YYYY',
  'fr-CH': 'DD.MM.YYYY',
  'fr-CI': 'DD/MM/YYYY',
  'fr-CM': 'DD/MM/YYYY',
  'fr-DJ': 'DD/MM/YYYY',
  'fr-DZ': 'DD/MM/YYYY',
  'fr-FR': 'DD/MM/YYYY',
  'fr-GA': 'DD/MM/YYYY',
  'fr-GF': 'DD/MM/YYYY',
  'fr-GN': 'DD/MM/YYYY',
  'fr-GP': 'DD/MM/YYYY',
  'fr-GQ': 'DD/MM/YYYY',
  'fr-HT': 'DD/MM/YYYY',
  'fr-KM': 'DD/MM/YYYY',
  'fr-LU': 'DD/MM/YYYY',
  'fr-MA': 'DD/MM/YYYY',
  'fr-MC': 'DD/MM/YYYY',
  'fr-MF': 'DD/MM/YYYY',
  'fr-MG': 'DD/MM/YYYY',
  'fr-ML': 'DD/MM/YYYY',
  'fr-MQ': 'DD/MM/YYYY',
  'fr-MR': 'DD/MM/YYYY',
  'fr-MU': 'DD/MM/YYYY',
  'fr-NC': 'DD/MM/YYYY',
  'fr-NE': 'DD/MM/YYYY',
  'fr-PF': 'DD/MM/YYYY',
  'fr-PM': 'DD/MM/YYYY',
  'fr-RE': 'DD/MM/YYYY',
  'fr-RW': 'DD/MM/YYYY',
  'fr-SC': 'DD/MM/YYYY',
  'fr-SN': 'DD/MM/YYYY',
  'fr-SY': 'DD/MM/YYYY',
  'fr-TD': 'DD/MM/YYYY',
  'fr-TG': 'DD/MM/YYYY',
  'fr-TN': 'DD/MM/YYYY',
  'fr-VU': 'DD/MM/YYYY',
  'fr-WF': 'DD/MM/YYYY',
  'fr-YT': 'DD/MM/YYYY',
  'fur-IT': 'DD/MM/YYYY',
  'fy-NL': 'DD-MM-YYYY',
  ga: 'DD/MM/YYYY',
  'ga-IE': 'DD/MM/YYYY',
  gd: 'DD/MM/YYYY',
  'gd-GB': 'DD/MM/YYYY',
  gl: 'DD/MM/YYYY',
  'gl-ES': 'DD/MM/YYYY',
  gn: 'DD/MM/YYYY',
  'gn-PY': 'DD/MM/YYYY',
  'gsw-CH': 'DD.MM.YYYY',
  'gsw-FR': 'DD/MM/YYYY',
  'gsw-LI': 'DD.MM.YYYY',
  gu: 'DD-MM-YYYY',
  'gu-IN': 'DD-MM-YYYY',
  'guz-KE': 'DD/MM/YYYY',
  'gv-IM': 'DD/MM/YYYY',
  'ha-Latn-GH': 'DD/MM/YYYY',
  'ha-Latn-NE': 'DD/MM/YYYY',
  'ha-Latn-NG': 'DD/MM/YYYY',
  'haw-US': 'DD/MM/YYYY',
  'he-IL': 'DD/MM/YYYY',
  'hi-IN': 'DD-MM-YYYY',
  hr: 'DD.MM.YYYY',
  'hr-BA': 'DD.MM.YYYY',
  'hr-HR': 'DD.MM.YYYY',
  'hsb-DE': 'DD.MM.YYYY',
  hu: 'YYYY-MM-DD',
  'hu-HU': 'YYYY-MM-DD',
  'hy-AM': 'DD.MM.YYYY',
  'ia-001': 'YYYY/MM/DD',
  'ia-FR': 'YYYY/MM/DD',
  'ibb-NG': 'DD/MM/YYYY',
  id: 'DD/MM/YYYY',
  'id-ID': 'DD/MM/YYYY',
  'ig-NG': 'DD/MM/YYYY',
  'ii-CN': 'YYYY/MM/DD',
  is: 'DD.MM.YYYY',
  'is-IS': 'DD.MM.YYYY',
  it: 'DD/MM/YYYY',
  'it-CH': 'DD.MM.YYYY',
  'it-IT': 'DD/MM/YYYY',
  'it-SM': 'DD/MM/YYYY',
  'it-VA': 'DD/MM/YYYY',
  'iu-Cans-CA': 'DD/MM/YYYY',
  'iu-Latn-CA': 'DD/MM/YYYY',
  'ja-JP': 'YYYY/MM/DD',
  'jgo-CM': 'YYYY-MM-DD',
  'jmc-TZ': 'DD/MM/YYYY',
  'jv-Java-ID': 'DD/MM/YYYY',
  'jv-Latn-ID': 'DD/MM/YYYY',
  'ka-GE': 'DD.MM.YYYY',
  'kab-DZ': 'DD/MM/YYYY',
  'kam-KE': 'DD/MM/YYYY',
  'kde-TZ': 'DD/MM/YYYY',
  'kea-CV': 'DD/MM/YYYY',
  'khq-ML': 'DD/MM/YYYY',
  ki: 'DD/MM/YYYY',
  'ki-KE': 'DD/MM/YYYY',
  'kk-KZ': 'DD.MM.YYYY',
  'kkj-CM': 'DD/MM/YYYY',
  'kl-GL': 'DD-MM-YYYY',
  'kln-KE': 'DD/MM/YYYY',
  km: 'DD/MM/YYYY',
  'km-KH': 'DD/MM/YYYY',
  kn: 'DD-MM-YYYY',
  'kn-IN': 'DD-MM-YYYY',
  'ko-KR': 'YYYY-MM-DD',
  'kok-IN': 'DD-MM-YYYY',
  kr: 'DD/MM/YYYY',
  'kr-NG': 'DD/MM/YYYY',
  'ks-Arab-IN': 'MM/DD/YYYY',
  'ks-Deva-IN': 'DD-MM-YYYY',
  'ksb-TZ': 'DD/MM/YYYY',
  'ksf-CM': 'DD/MM/YYYY',
  'ksh-DE': 'DD.MM.YYYY',
  'ku-Arab-IQ': 'YYYY/MM/DD',
  'ku-Arab-IR': 'DD/MM/YYYY',
  kw: 'DD/MM/YYYY',
  'kw-GB': 'DD/MM/YYYY',
  la: 'DD/MM/YYYY',
  'la-001': 'DD/MM/YYYY',
  'lag-TZ': 'DD/MM/YYYY',
  lb: 'DD.MM.YYYY',
  'lb-LU': 'DD.MM.YYYY',
  'lg-UG': 'DD/MM/YYYY',
  'lkt-US': 'MM/DD/YYYY',
  'ln-AO': 'DD/MM/YYYY',
  'ln-CD': 'DD/MM/YYYY',
  'ln-CF': 'DD/MM/YYYY',
  'ln-CG': 'DD/MM/YYYY',
  'lo-LA': 'DD/MM/YYYY',
  'lrc-IQ': 'YYYY-MM-DD',
  'lrc-IR': 'DD/MM/YYYY',
  lt: 'YYYY-MM-DD',
  'lt-LT': 'YYYY-MM-DD',
  lu: 'DD/MM/YYYY',
  'lu-CD': 'DD/MM/YYYY',
  'luo-KE': 'DD/MM/YYYY',
  'luy-KE': 'DD/MM/YYYY',
  lv: 'DD.MM.YYYY',
  'lv-LV': 'DD.MM.YYYY',
  'mas-KE': 'DD/MM/YYYY',
  'mas-TZ': 'DD/MM/YYYY',
  'mer-KE': 'DD/MM/YYYY',
  'mfe-MU': 'DD/MM/YYYY',
  mg: 'YYYY-MM-DD',
  'mg-MG': 'YYYY-MM-DD',
  'mgh-MZ': 'DD/MM/YYYY',
  'mgo-CM': 'YYYY-MM-DD',
  'mi-NZ': 'DD/MM/YYYY',
  mk: 'DD.MM.YYYY',
  'mk-MK': 'DD.MM.YYYY',
  ml: 'DD/MM/YYYY',
  'ml-IN': 'DD/MM/YYYY',
  mn: 'YYYY.MM.DD',
  'mn-MN': 'YYYY.MM.DD',
  'mn-Mong-CN': 'YYYY/MM/DD',
  'mn-Mong-MN': 'YYYY/MM/DD',
  'mni-IN': 'DD/MM/YYYY',
  'moh-CA': 'MM/DD/YYYY',
  mr: 'DD-MM-YYYY',
  'mr-IN': 'DD-MM-YYYY',
  ms: 'DD/MM/YYYY',
  'ms-BN': 'DD/MM/YYYY',
  'ms-MY': 'DD/MM/YYYY',
  'ms-SG': 'DD/MM/YYYY',
  mt: 'DD/MM/YYYY',
  'mt-MT': 'DD/MM/YYYY',
  'mua-CM': 'DD/MM/YYYY',
  my: 'DD-MM-YYYY',
  'my-MM': 'DD-MM-YYYY',
  'mzn-IR': 'DD/MM/YYYY',
  'naq-NA': 'DD/MM/YYYY',
  'nb-NO': 'DD.MM.YYYY',
  'nb-SJ': 'DD.MM.YYYY',
  'nd-ZW': 'DD/MM/YYYY',
  'nds-DE': 'DD.MM.YYYY',
  'nds-NL': 'DD.MM.YYYY',
  ne: 'MM/DD/YYYY',
  'ne-IN': 'YYYY/MM/DD',
  'ne-NP': 'MM/DD/YYYY',
  nl: 'DD-MM-YYYY',
  'nl-AW': 'DD-MM-YYYY',
  'nl-BE': 'DD/MM/YYYY',
  'nl-BQ': 'DD-MM-YYYY',
  'nl-CW': 'DD-MM-YYYY',
  'nl-NL': 'DD-MM-YYYY',
  'nl-SR': 'DD-MM-YYYY',
  'nl-SX': 'DD-MM-YYYY',
  'nmg-CM': 'DD/MM/YYYY',
  'nn-NO': 'DD.MM.YYYY',
  'nnh-CM': 'DD/MM/YYYY',
  no: 'DD.MM.YYYY',
  'nqo-GN': 'DD/MM/YYYY',
  nr: 'YYYY-MM-DD',
  'nr-ZA': 'YYYY-MM-DD',
  'nso-ZA': 'YYYY-MM-DD',
  'nus-SS': 'DD/MM/YYYY',
  'nyn-UG': 'DD/MM/YYYY',
  'oc-FR': 'DD/MM/YYYY',
  om: 'DD/MM/YYYY',
  'om-ET': 'DD/MM/YYYY',
  'om-KE': 'DD/MM/YYYY',
  'or-IN': 'DD-MM-YYYY',
  'os-GE': 'DD.MM.YYYY',
  'os-RU': 'DD.MM.YYYY',
  pa: 'DD-MM-YYYY',
  'pa-Arab-PK': 'DD-MM-YYYY',
  'pa-IN': 'DD-MM-YYYY',
  'pap-029': 'DD-MM-YYYY',
  pl: 'DD.MM.YYYY',
  'pl-PL': 'DD.MM.YYYY',
  'prg-001': 'DD.MM.YYYY',
  'prs-AF': 'YYYY/MM/DD',
  ps: 'YYYY/MM/DD',
  'ps-AF': 'YYYY/MM/DD',
  pt: 'DD/MM/YYYY',
  'pt-AO': 'DD/MM/YYYY',
  'pt-BR': 'DD/MM/YYYY',
  'pt-CH': 'DD/MM/YYYY',
  'pt-CV': 'DD/MM/YYYY',
  'pt-GQ': 'DD/MM/YYYY',
  'pt-GW': 'DD/MM/YYYY',
  'pt-LU': 'DD/MM/YYYY',
  'pt-MO': 'DD/MM/YYYY',
  'pt-MZ': 'DD/MM/YYYY',
  'pt-PT': 'DD/MM/YYYY',
  'pt-ST': 'DD/MM/YYYY',
  'pt-TL': 'DD/MM/YYYY',
  'quc-Latn-GT': 'DD/MM/YYYY',
  'quz-BO': 'DD/MM/YYYY',
  'quz-EC': 'DD/MM/YYYY',
  'quz-PE': 'DD/MM/YYYY',
  'rm-CH': 'DD-MM-YYYY',
  'rn-BI': 'DD/MM/YYYY',
  ro: 'DD.MM.YYYY',
  'ro-MD': 'DD.MM.YYYY',
  'ro-RO': 'DD.MM.YYYY',
  'rof-TZ': 'DD/MM/YYYY',
  ru: 'DD.MM.YYYY',
  'ru-BY': 'DD.MM.YYYY',
  'ru-KG': 'DD.MM.YYYY',
  'ru-KZ': 'DD.MM.YYYY',
  'ru-MD': 'DD.MM.YYYY',
  'ru-RU': 'DD.MM.YYYY',
  'ru-UA': 'DD.MM.YYYY',
  rw: 'YYYY-MM-DD',
  'rw-RW': 'YYYY-MM-DD',
  'rwk-TZ': 'DD/MM/YYYY',
  sa: 'DD-MM-YYYY',
  'sa-IN': 'DD-MM-YYYY',
  'sah-RU': 'DD.MM.YYYY',
  'saq-KE': 'DD/MM/YYYY',
  'sbp-TZ': 'DD/MM/YYYY',
  sd: 'DD/MM/YYYY',
  'sd-Arab-PK': 'DD/MM/YYYY',
  'sd-Deva-IN': 'DD/MM/YYYY',
  se: 'YYYY-MM-DD',
  'se-FI': 'DD.MM.YYYY',
  'se-NO': 'YYYY-MM-DD',
  'se-SE': 'YYYY-MM-DD',
  'seh-MZ': 'DD/MM/YYYY',
  'ses-ML': 'DD/MM/YYYY',
  sg: 'DD/MM/YYYY',
  'sg-CF': 'DD/MM/YYYY',
  'shi-Latn-MA': 'DD/MM/YYYY',
  'shi-Tfng-MA': 'DD/MM/YYYY',
  si: 'YYYY-MM-DD',
  'si-LK': 'YYYY-MM-DD',
  sk: 'DD.MM.YYYY',
  'sk-SK': 'DD.MM.YYYY',
  sl: 'DD.MM.YYYY',
  'sl-SI': 'DD.MM.YYYY',
  'sma-NO': 'DD.MM.YYYY',
  'sma-SE': 'YYYY-MM-DD',
  'smj-NO': 'DD.MM.YYYY',
  'smj-SE': 'YYYY-MM-DD',
  'smn-FI': 'DD.MM.YYYY',
  'sms-FI': 'DD.MM.YYYY',
  sn: 'YYYY-MM-DD',
  'sn-Latn-ZW': 'YYYY-MM-DD',
  so: 'DD/MM/YYYY',
  'so-DJ': 'DD/MM/YYYY',
  'so-ET': 'DD/MM/YYYY',
  'so-KE': 'DD/MM/YYYY',
  'so-SO': 'DD/MM/YYYY',
  'sq-AL': 'DD.MM.YYYY',
  'sq-MK': 'DD.MM.YYYY',
  'sq-XK': 'DD.MM.YYYY',
  sr: 'DD.MM.YYYY',
  'sr-RS': 'DD.MM.YYYY',
  'sr-Cyrl-BA': 'DD.MM.YYYY',
  'sr-Cyrl-ME': 'DD.MM.YYYY',
  'sr-Cyrl-RS': 'DD.MM.YYYY',
  'sr-Cyrl-XK': 'DD.MM.YYYY',
  'sr-Latn-BA': 'DD.MM.YYYY',
  'sr-Latn-ME': 'DD.MM.YYYY',
  'sr-Latn-RS': 'DD.MM.YYYY',
  'sr-Latn-XK': 'DD.MM.YYYY',
  ss: 'YYYY-MM-DD',
  'ss-SZ': 'YYYY-MM-DD',
  'ss-ZA': 'YYYY-MM-DD',
  'ssy-ER': 'DD/MM/YYYY',
  st: 'YYYY-MM-DD',
  'st-LS': 'YYYY-MM-DD',
  'st-ZA': 'YYYY-MM-DD',
  sv: 'YYYY-MM-DD',
  'sv-AX': 'YYYY-MM-DD',
  'sv-FI': 'DD-MM-YYYY',
  'sv-SE': 'YYYY-MM-DD',
  'sw-CD': 'DD/MM/YYYY',
  'sw-KE': 'DD/MM/YYYY',
  'sw-TZ': 'DD/MM/YYYY',
  'sw-UG': 'DD/MM/YYYY',
  'syr-SY': 'DD/MM/YYYY',
  'ta-IN': 'DD-MM-YYYY',
  'ta-LK': 'DD/MM/YYYY',
  'ta-MY': 'DD/MM/YYYY',
  'ta-SG': 'DD/MM/YYYY',
  'te-IN': 'DD-MM-YYYY',
  'teo-KE': 'DD/MM/YYYY',
  'teo-UG': 'DD/MM/YYYY',
  tg: 'DD.MM.YYYY',
  'tg-Cyrl-TJ': 'DD.MM.YYYY',
  th: 'DD/MM/YYYY',
  'th-TH': 'DD/MM/YYYY',
  'ti-ER': 'DD/MM/YYYY',
  'ti-ET': 'DD/MM/YYYY',
  'tig-ER': 'DD/MM/YYYY',
  tn: 'YYYY-MM-DD',
  'tn-BW': 'YYYY-MM-DD',
  'tn-ZA': 'YYYY-MM-DD',
  to: 'DD/MM/YYYY',
  'to-TO': 'DD/MM/YYYY',
  tr: 'DD.MM.YYYY',
  'tr-CY': 'DD.MM.YYYY',
  'tr-TR': 'DD.MM.YYYY',
  'ts-ZA': 'YYYY-MM-DD',
  tt: 'DD.MM.YYYY',
  'tt-RU': 'DD.MM.YYYY',
  'twq-NE': 'DD/MM/YYYY',
  'tzm-Arab-MA': 'DD/MM/YYYY',
  'tzm-Latn-DZ': 'DD-MM-YYYY',
  'tzm-Latn-MA': 'DD/MM/YYYY',
  'tzm-Tfng-MA': 'DD-MM-YYYY',
  ug: 'YYYY-MM-DD',
  'ug-CN': 'YYYY-MM-DD',
  'uk-UA': 'DD.MM.YYYY',
  'ur-IN': 'DD/MM/YYYY',
  'ur-PK': 'DD/MM/YYYY',
  uz: 'DD/MM/YYYY',
  'uz-Arab-AF': 'DD/MM/YYYY',
  'uz-Cyrl-UZ': 'DD/MM/YYYY',
  'uz-Latn-UZ': 'DD/MM/YYYY',
  'vai-Latn-LR': 'DD/MM/YYYY',
  'vai-Vaii-LR': 'DD/MM/YYYY',
  ve: 'YYYY-MM-DD',
  've-ZA': 'YYYY-MM-DD',
  vi: 'DD/MM/YYYY',
  'vi-VN': 'DD/MM/YYYY',
  'vo-001': 'YYYY-MM-DD',
  'vun-TZ': 'DD/MM/YYYY',
  'wae-CH': 'YYYY-MM-DD',
  'wal-ET': 'DD/MM/YYYY',
  'wo-SN': 'DD/MM/YYYY',
  'xh-ZA': 'YYYY-MM-DD',
  'xog-UG': 'DD/MM/YYYY',
  'yav-CM': 'DD/MM/YYYY',
  'yi-001': 'DD/MM/YYYY',
  'yo-BJ': 'DD/MM/YYYY',
  'yo-NG': 'DD/MM/YYYY',
  'zgh-Tfng-MA': 'DD/MM/YYYY',
  'zh-CN': 'YYYY/MM/DD',
  'zh-Hans-HK': 'DD/MM/YYYY',
  'zh-Hans-MO': 'DD/MM/YYYY',
  'zh-HK': 'DD/MM/YYYY',
  'zh-MO': 'DD/MM/YYYY',
  'zh-SG': 'DD/MM/YYYY',
  'zh-TW': 'YYYY/MM/DD',
  'zu-ZA': 'MM/DD/YYYY',
};

export const DEFAULT_DATE_FORMAT_PATTERN = 'DD/MM/YYYY';
