import { type ProductType, type ProductInfoResponse } from '~/domains/product';

export interface CompareResponse {
  groups: Array<{
    type: ProductType;
    products: ProductInfoResponse[];
  }>;
}

export enum CompareModes {
  ADD = 'ADD',
  DELETE = 'DELETE',
}

export interface CompareRequest {
  lines: Array<{
    article: string;
    mode: `${CompareModes}`;
  }>;
}

export type Specification<T> = Record<string, T>;
