<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9448 2H4.38921C3.16699 2 2.16699 3 2.16699 4.22222V19.7778C2.16699 21.0011 3.16699 22 4.38921 22H12.167V14.2222H9.94477V11.4722H12.167V9.19444C12.167 6.79 13.5137 5.10111 16.3514 5.10111L18.3548 5.10333V7.99778H17.0248C15.9203 7.99778 15.5003 8.82667 15.5003 9.59556V11.4733H18.3537L17.7225 14.2222H15.5003V22H19.9448C21.167 22 22.167 21.0011 22.167 19.7778V4.22222C22.167 3 21.167 2 19.9448 2Z"
      fill="white"
    />
  </svg>
</template>
