import { createApp, type Component } from 'vue';
import App from '~/app/app.vue';
import '~/app/assets/css/index.css';
import { registerPlugins } from './plugins';
import { registerDirectives } from './directives/registerDirectives.ts';

const app = createApp(App as Component);

registerPlugins(app);
registerDirectives(app);

app.mount('#app');
