import { ref, computed, type Ref, type ComputedRef } from 'vue';
import { type Props } from './UiSidebarMenuItem.vue';
import { type SidebarItem } from '~/app/layouts/sidebar';
import { useRouter } from 'vue-router';

interface SortedItem {
  capitalLetter: string;
  item: SidebarItem[];
}

export interface ReturnUseSidebarSearch {
  searchValue: Ref<string>;
  searchByDefaultItem: ComputedRef<SidebarItem[]>;
  searchBySortedItem: ComputedRef<SortedItem[]>;
}

export default (props: Props): ReturnUseSidebarSearch => {
  const searchValue = ref('');
  const router = useRouter();

  const searchByDefaultItem = computed(() => {
    if (searchValue.value === '' || props.item.children === undefined) {
      return props.item.children ?? [];
    }

    return props.item.children?.filter((child) =>
      child.label?.toLowerCase().includes(searchValue.value.toLowerCase()),
    );
  });

  const searchBySortedItem = computed(() => {
    if (searchValue.value === '' || props.item.children === undefined) {
      return sortedItemChildren.value;
    }

    return sortedItemChildren.value?.map((child) => ({
      capitalLetter: child.capitalLetter,
      item: child.item.filter((child) =>
        child.label.toLowerCase().includes(searchValue.value.toLowerCase()),
      ),
    }));
  });

  const sortedItemChildren = computed(() => {
    if (props.item.children === undefined) return [];

    const sortedItemsByDesc: SortedItem[] = [];
    let currentCapitalLetter: string | null = null;

    for (let index = 0; index < props.item.children.length; index++) {
      const capitalLetter = props.item.children[index].label[0].toUpperCase();

      if (currentCapitalLetter === capitalLetter) {
        // TODO CHECK THIS ERROR AND DELETE
        /* eslint-disable @typescript-eslint/no-unsafe-argument */
        sortedItemsByDesc[sortedItemsByDesc.length - 1].item.push(
          props.item.children[index],
        );
        /* eslint-enable @typescript-eslint/no-unsafe-argument */
      } else {
        sortedItemsByDesc.push({
          capitalLetter,
          item: [props.item.children[index]],
        });

        currentCapitalLetter = capitalLetter;
      }
    }

    return sortedItemsByDesc;
  });

  router.beforeEach(() => {
    searchValue.value = '';
  });

  return {
    searchValue,
    searchByDefaultItem,
    searchBySortedItem,
  };
};
