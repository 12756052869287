<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.0001 1.34998C7.35908 1.34998 7.6501 1.64099 7.6501 1.99998V3.34998H16.3501V1.99998C16.3501 1.64099 16.6411 1.34998 17.0001 1.34998C17.3591 1.34998 17.6501 1.64099 17.6501 1.99998V3.34998H18.0001C19.4637 3.34998 20.6501 4.53642 20.6501 5.99998V7.99998V18C20.6501 19.4635 19.4637 20.65 18.0001 20.65H6.0001C4.53654 20.65 3.3501 19.4635 3.3501 18V7.99998V5.99998C3.3501 4.53642 4.53654 3.34998 6.0001 3.34998H6.3501V1.99998C6.3501 1.64099 6.64111 1.34998 7.0001 1.34998ZM19.3501 5.99998V7.34998H4.6501V5.99998C4.6501 5.25439 5.25451 4.64998 6.0001 4.64998H7.0001H17.0001H18.0001C18.7457 4.64998 19.3501 5.25439 19.3501 5.99998ZM19.3501 8.64998H4.6501V18C4.6501 18.7456 5.25451 19.35 6.0001 19.35H18.0001C18.7457 19.35 19.3501 18.7456 19.3501 18V8.64998ZM15.0001 18.15C14.365 18.15 13.8501 17.6351 13.8501 17V15C13.8501 14.3648 14.365 13.85 15.0001 13.85H17.0001C17.6352 13.85 18.1501 14.3648 18.1501 15V17C18.1501 17.6351 17.6352 18.15 17.0001 18.15H15.0001ZM15.1501 15.15V16.85H16.8501V15.15H15.1501Z"
      fill="currentColor"
    />
  </svg>
</template>
