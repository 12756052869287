import { ref, type Ref, watchEffect } from 'vue';
import { type UseFetchReturn } from '../types';

export default function useFetch<T>(
  callback: () => Promise<T>,
): UseFetchReturn<T> {
  const data = ref(null) as Ref<T | null>;
  const loading = ref<boolean>(false);
  const error = ref<Error | null>(null);

  data.value = null;
  error.value = null;

  const fetch = (): void => {
    loading.value = true;
    callback()
      .then((res) => {
        data.value = res;
      })
      .catch((e) => {
        error.value = e;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  watchEffect(fetch);

  return {
    data,
    loading,
    error,
    fetch,
  };
}
