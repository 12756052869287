import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { CategoryTree } from '../types';

export default defineStore('categoriesTree', () => {
  const categoriesTree = ref<CategoryTree | undefined>();

  const updateCategoriesTree = (payload: CategoryTree): void => {
    categoriesTree.value = payload;
  };

  return {
    categoriesTree,
    updateCategoriesTree,
  };
});
