import { type CompareRepository } from '../ports';

interface Dependencies {
  notifier?: {
    notifySuccess: () => void;
    notifyError: () => void;
  };
  storage?: {
    clearCompare: () => void;
  };
  compareRepository: CompareRepository;
}

export async function removeAllProductsFromCompareUseCase({
  notifier,
  storage,
  compareRepository,
}: Dependencies): Promise<void> {
  try {
    await compareRepository.deleteAll();

    notifier?.notifySuccess();
    storage?.clearCompare();
  } catch (error) {
    notifier?.notifyError();
  }
}
