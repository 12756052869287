<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.85999 4.50001C1.85999 4.14102 2.151 3.85001 2.50999 3.85001C2.82887 3.85001 3.30988 3.91103 3.78213 4.09384C4.25158 4.27556 4.79171 4.61001 5.09136 5.20932C5.1756 5.37779 5.28194 5.66843 5.39623 6.00493C5.47813 6.24609 5.57083 6.53131 5.67153 6.85001H19.067C20.219 6.85001 21.0164 8.00067 20.6119 9.07936L18.6053 14.4305C18.2174 15.4648 17.2286 16.15 16.124 16.15H9.03016C7.83958 16.15 6.79709 15.3569 6.47486 14.213C5.89439 12.1522 5.13239 9.50038 4.57274 7.69219C4.41764 7.19108 4.27924 6.75852 4.16529 6.423C4.04621 6.07239 3.96657 5.86663 3.92861 5.7907C3.82826 5.59 3.61839 5.42445 3.31284 5.30618C3.01009 5.18898 2.6911 5.15001 2.50999 5.15001C2.151 5.15001 1.85999 4.85899 1.85999 4.50001ZM6.07174 8.15001C6.59762 9.89293 7.22949 12.0972 7.72617 13.8605C7.89111 14.4461 8.4239 14.85 9.03016 14.85H16.124C16.6867 14.85 17.1904 14.5009 17.388 13.974L19.3947 8.6229C19.4805 8.39409 19.3114 8.15001 19.067 8.15001H6.07174ZM11.01 19.5C11.01 20.3284 10.3384 21 9.50999 21C8.68156 21 8.00999 20.3284 8.00999 19.5C8.00999 18.6716 8.68156 18 9.50999 18C10.3384 18 11.01 18.6716 11.01 19.5ZM15.51 21C16.3384 21 17.01 20.3284 17.01 19.5C17.01 18.6716 16.3384 18 15.51 18C14.6816 18 14.01 18.6716 14.01 19.5C14.01 20.3284 14.6816 21 15.51 21Z"
      fill="currentColor"
    />
  </svg>
</template>
