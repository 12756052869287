<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.00004 3.3501H1.68958L2.48255 4.39342L9.53965 13.6785L4.38277 19.5721L3.4395 20.6501H4.87195H4.99992H5.29487L5.4891 20.4281L10.4021 14.8132L14.6433 20.3934L14.8384 20.6501H15.1608H19H20.3L19.52 19.6101L13.3745 11.4162L19.4891 4.42812L20.4323 3.3501H18.9999H18.8719H18.577L18.3827 3.57207L12.5182 10.2744L7.51992 3.6101L7.32492 3.3501H6.99992H3.00004ZM15.4832 19.3501L4.31051 4.6501H6.67492L17.7 19.3501H15.4832Z"
      fill="#000000"
    />
  </svg>
</template>
