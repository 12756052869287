<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.45972 5.45959C8.71356 5.20575 8.71356 4.7942 8.45972 4.54036C8.20588 4.28652 7.79432 4.28652 7.54048 4.54036L5.5001 6.58074L4.45972 5.54036C4.20588 5.28652 3.79432 5.28652 3.54048 5.54036C3.28664 5.7942 3.28664 6.20575 3.54048 6.45959L5.04048 7.95959C5.29432 8.21344 5.70588 8.21344 5.95972 7.95959L8.45972 5.45959ZM8.45972 11.4596C8.71356 11.2058 8.71356 10.7942 8.45972 10.5404C8.20588 10.2865 7.79432 10.2865 7.54048 10.5404L5.5001 12.5807L4.45972 11.5404C4.20588 11.2865 3.79432 11.2865 3.54048 11.5404C3.28664 11.7942 3.28664 12.2058 3.54048 12.4596L5.04048 13.9596C5.29432 14.2134 5.70588 14.2134 5.95972 13.9596L8.45972 11.4596ZM8.45972 16.5404C8.71356 16.7942 8.71356 17.2058 8.45972 17.4596L5.95972 19.9596C5.70588 20.2134 5.29432 20.2134 5.04048 19.9596L3.54048 18.4596C3.28664 18.2058 3.28664 17.7942 3.54048 17.5404C3.79432 17.2865 4.20588 17.2865 4.45972 17.5404L5.5001 18.5807L7.54048 16.5404C7.79432 16.2865 8.20588 16.2865 8.45972 16.5404ZM11.0001 5.34998C10.6411 5.34998 10.3501 5.64099 10.3501 5.99998C10.3501 6.35896 10.6411 6.64998 11.0001 6.64998H20.0001C20.3591 6.64998 20.6501 6.35896 20.6501 5.99998C20.6501 5.64099 20.3591 5.34998 20.0001 5.34998H11.0001ZM11.0001 11.35C10.6411 11.35 10.3501 11.641 10.3501 12C10.3501 12.359 10.6411 12.65 11.0001 12.65H20.0001C20.3591 12.65 20.6501 12.359 20.6501 12C20.6501 11.641 20.3591 11.35 20.0001 11.35H11.0001ZM11.0001 17.35C10.6411 17.35 10.3501 17.641 10.3501 18C10.3501 18.359 10.6411 18.65 11.0001 18.65H20.0001C20.3591 18.65 20.6501 18.359 20.6501 18C20.6501 17.641 20.3591 17.35 20.0001 17.35H11.0001Z"
      fill="currentColor"
    />
  </svg>
</template>
