import { type SidebarItemInner } from '~/ui/UiSidebar/UiSidebarMenuItemInner.vue';

const sidebarService = {
  sortItemsInnerByLabel(
    items: SidebarItemInner[] | undefined,
  ): SidebarItemInner[] | undefined {
    if (items === undefined) return undefined;

    return items.sort((a, b) =>
      a.label.localeCompare(b.label, 'en', { sensitivity: 'base' }),
    );
  },
};

export default sidebarService;
