import {
  type ProductKavResponse,
  type ProductKav,
  type ProductKavValueResponse,
  type ProductKavValue,
} from '../../types';

const kavValueSerializer = (data: ProductKavValueResponse): ProductKavValue => {
  return {
    id: data.kav_id,
    quantity: data.qty,
    realValue: data.rv,
    value: data.value,
    productArticle: data.articles?.[0] ?? null,
  };
};

export default function productKavSerializer(
  data: ProductKavResponse,
): ProductKav {
  return {
    id: data.ka_id,
    name: data.name,
    values: data.values?.map((value) => kavValueSerializer(value)),
  };
}
