import { type ProductStock, type ProductStockResponse } from '../types';
import productWarehouseSerializer from './product-warehouse.serializer';
import { dateSerializer } from '~/domains/core';

export default function productStockSerializer(
  data: ProductStockResponse,
): ProductStock {
  return {
    type: data.type,
    available: data.available,
    stock: data.stock,
    deliveryHours: data.delivery_hours,
    shippedDate: dateSerializer(data.shipped_date),
    estimatedDeliveryDate: dateSerializer(data.estimated_delivery_date),
    delivery: data.delivery,
    details: data.details?.map((item) => productStockSerializer(item)),
    warehouse: productWarehouseSerializer(data?.warehouse),
  };
}
