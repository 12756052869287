<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.6501 3.0001C7.6501 2.64111 7.35908 2.3501 7.0001 2.3501C6.64111 2.3501 6.3501 2.64111 6.3501 3.0001V4.3501H6.0001C4.53654 4.3501 3.3501 5.53654 3.3501 7.0001V9.0001V19.0001C3.3501 20.4637 4.53654 21.6501 6.0001 21.6501H18.0001C19.4637 21.6501 20.6501 20.4637 20.6501 19.0001V9.0001V7.0001C20.6501 5.53654 19.4637 4.3501 18.0001 4.3501H17.6501V3.0001C17.6501 2.64111 17.3591 2.3501 17.0001 2.3501C16.6411 2.3501 16.3501 2.64111 16.3501 3.0001V4.3501H7.6501V3.0001ZM19.3501 8.3501V7.0001C19.3501 6.25451 18.7457 5.6501 18.0001 5.6501H17.0001H7.0001H6.0001C5.25451 5.6501 4.6501 6.25451 4.6501 7.0001V8.3501H19.3501ZM4.6501 9.6501H19.3501V19.0001C19.3501 19.7457 18.7457 20.3501 18.0001 20.3501H6.0001C5.25451 20.3501 4.6501 19.7457 4.6501 19.0001V9.6501ZM5.8501 14.0001C5.8501 14.6352 6.36497 15.1501 7.0001 15.1501H9.0001C9.63523 15.1501 10.1501 14.6352 10.1501 14.0001V12.0001C10.1501 11.365 9.63523 10.8501 9.0001 10.8501H7.0001C6.36497 10.8501 5.8501 11.365 5.8501 12.0001V14.0001ZM7.1501 13.8501V12.1501H8.8501V13.8501H7.1501Z"
      fill="currentColor"
    />
  </svg>
</template>
