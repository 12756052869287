<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.00024 4.3501C3.53669 4.3501 2.35024 5.53654 2.35024 7.0001V8.98602C2.35005 8.99508 2.35005 9.00414 2.35024 9.01317V17.0001C2.35024 18.4637 3.53669 19.6501 5.00024 19.6501H19.0002C20.4638 19.6501 21.6502 18.4637 21.6502 17.0001V9.01317C21.6504 9.00414 21.6504 8.99508 21.6502 8.98602V7.0001C21.6502 5.53654 20.4638 4.3501 19.0002 4.3501H5.00024ZM20.3502 8.59838V7.0001C20.3502 6.25451 19.7458 5.6501 19.0002 5.6501H5.00024C4.25466 5.6501 3.65024 6.25451 3.65024 7.0001V8.59838L12.0002 12.7734L20.3502 8.59838ZM3.65024 10.0518V17.0001C3.65024 17.7457 4.25466 18.3501 5.00024 18.3501H19.0002C19.7458 18.3501 20.3502 17.7457 20.3502 17.0001V10.0518L12.2909 14.0815L12.0002 14.2268L11.7096 14.0815L3.65024 10.0518Z"
      fill="currentColor"
    />
  </svg>
</template>
