<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.3499 19.4999C11.3499 19.8589 11.6409 20.15 11.9999 20.15C12.3589 20.15 12.6499 19.859 12.6499 19.5L12.65 12.6499L19.5 12.65C19.859 12.65 20.15 12.359 20.15 12C20.15 11.641 19.859 11.35 19.5 11.35L12.6501 11.3499L12.6502 4.50011C12.6502 4.14112 12.3591 3.8501 12.0002 3.8501C11.6412 3.85009 11.3502 4.1411 11.3502 4.50009L11.3501 11.3499L4.49988 11.3499C4.1409 11.3499 3.84988 11.6409 3.84988 11.9999C3.84988 12.3589 4.14089 12.6499 4.49988 12.6499L11.35 12.6499L11.3499 19.4999Z"
      fill="currentColor"
    />
  </svg>
</template>
