import { createVuetify, type ThemeDefinition } from 'vuetify';
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import { icons } from './icons';
import colors from './colors';

const customTheme: ThemeDefinition = {
  dark: false,
  colors,
};

const vuetify = createVuetify({
  display: {
    thresholds: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  theme: {
    defaultTheme: 'customTheme',
    themes: {
      customTheme,
    },
  },
  icons,
});

export default vuetify;
