<template>
  <UiListItem
    v-if="!item.children || item.children.length === 0"
    :value="item.id"
    class="ui-list-item"
    active-class="ui-list-item-active ui-list-item-active_inner-menu"
    :min-height="32"
    v-bind="item.link && { to: item.link }"
    :disabled="item.disabled"
  >
    <template v-if="false" #prepend>
      <UiIcon :icon="item.icon" />
    </template>
    <UiListItemTitle class="text-body-3">
      {{ item.label }}
    </UiListItemTitle>
  </UiListItem>
  <UiMenu
    v-else
    ref="sidebarInnerMenu"
    location="end"
    :open-delay="100"
    :close-delay="100"
    transition="slide-x-transition"
    class="sidebar-menu"
    :offset="menuOffset"
    open-on-hover
    :content-class="isScrolling ? '' : 'offset-top'"
    :max-width="800"
    @update:model-value="handleUpdate"
  >
    <template #activator="{ props: activatorProps, isActive }">
      <UiListItem
        v-bind="activatorProps"
        :value="item.id"
        :min-height="32"
        class="ui-list-item"
        :class="{
          'ui-list-item-active ui-list-item-active_inner-menu': isActive,
        }"
        :to="item.link"
        @click="sidebarInnerMenu?.close()"
      >
        <template v-if="false" #prepend>
          <UiIcon :icon="item.icon" />
        </template>
        <UiListItemTitle class="text-body-3">
          {{ item.label }}
        </UiListItemTitle>
      </UiListItem>
    </template>
    <UiList v-if="item.children" class="ui-list" elevation="0" :rounded="0">
      <UiSidebarMenuGrid :items="item.children" :additional="item.additional" />
    </UiList>
  </UiMenu>
</template>

<script setup lang="ts">
import {
  UiMenu,
  UiListItem,
  UiIcon,
  UiList,
  UiListItemTitle,
  UiSidebarMenuGrid,
} from '~/ui';
import { type SidebarItem } from '~/app/layouts/sidebar';
import { ref } from 'vue';

export interface SidebarItemInner {
  id: string | number;
  label: string;
  icon?: string;
  children?: SidebarItem[][];
  layout?: 'grid';
  link?: string;
  disabled?: boolean;
}

export interface Props {
  item: SidebarItem;
  menuOffset: string;
  isScrolling: boolean;
}

const props = defineProps<Props>();
const emit =
  defineEmits<(e: 'update', value: boolean, id: string | number) => void>();

const sidebarInnerMenu = ref();

const handleUpdate = (value: boolean): void => {
  // TODO: fix this
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  emit('update', value, props.item.id);
};
</script>

<style scoped lang="scss">
.ui-list {
  padding: 0;
  border-bottom: 0;
  border-top: 0;
  border-right: 1px solid rgb(var(--v-theme-grey-100));
  background-color: rgb(var(--v-theme-white)) !important;
  transition: top var(--layout-transition-duration)
    var(--layout-transition-timing);
}

.ui-list-item.ui-list-item-active_inner-menu {
  &::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: -20px;
    width: 20px;
    height: 20px;
    background: radial-gradient(
      circle at left bottom,
      transparent 20px,
      rgb(var(--v-theme-white)) 0
    );
  }

  &::after {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    top: -20px;
    right: 0;
    background: radial-gradient(
      circle at left top,
      transparent 20px,
      rgb(var(--v-theme-white)) 0
    );
    border: none;
    opacity: inherit;
    left: auto;
  }
}
</style>
