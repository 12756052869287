import { ref, type Ref } from 'vue';
import { useGetTranslates } from '~/domains/i18n';
import { useCompare } from '~/domains/compare';
import { useBasketStats } from '~/domains/basket';
import { useCategoriesTree } from '~/domains/category';
import { useUserPreferences } from '~/domains/user';

interface ComposableReturn {
  loading: Ref<boolean>;
  initRequiredRequests: () => Promise<void>;
}

export default function useDefaultLayout(): ComposableReturn {
  const loading = ref(false);
  const { getCompare } = useCompare();
  const { getBasketStats } = useBasketStats();
  const { fetchCategoriesTree } = useCategoriesTree();
  const { getUserPreferences } = useUserPreferences();
  const { getTranslates } = useGetTranslates();

  const initRequiredRequests = async (): Promise<void> => {
    loading.value = true;
    try {
      await Promise.all([
        getTranslates(),
        getBasketStats(),
        getCompare(),
        fetchCategoriesTree(),
        getUserPreferences(),
      ]);
    } catch (err) {
      console.log(err);
    } finally {
      loading.value = false;
    }
  };

  return {
    loading,
    initRequiredRequests,
  };
}
