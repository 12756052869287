import { type BasketTypeResponse } from '../../types.d';
import { type BasketLine } from '../../entities';
import { productSerializer } from '~/domains/product';
import { SEPARATOR_HEADER_ROW } from '~/domains/product/constants.d';
import { dateSerializer } from '~/domains/core';

const oneTypeBasketsSerializer = (
  basketsByOneType: BasketTypeResponse[],
): any => {
  const products: any[] = [];

  basketsByOneType.forEach((basket) => {
    if (basket.lines === undefined) return;

    products.push({
      section: SEPARATOR_HEADER_ROW,
      sku: basket.owner.full_name,
    });

    basket.lines.forEach((line) => {
      let reserved = {};
      const result: BasketLine = {
        ...productSerializer(line.product),
        quantity: {
          ordered: line.qty === undefined ? 1 : +line.qty,
        },
      };

      if (line.id !== undefined) {
        result.lineId = line.id;
      }

      if (line.arrival_date !== undefined) {
        result.lineArrivalDate = dateSerializer(line.arrival_date);
      }

      if (line.reserved_qty !== undefined) {
        reserved = {
          ...reserved,
          reservedCount: +line.reserved_qty,
        };
      }

      if (line.reserve_ttl_minutes !== undefined) {
        reserved = {
          ...reserved,
          reserveExpirationMinutes: +line.reserve_ttl_minutes,
        };
      }

      if (line.reserve_status !== undefined) {
        reserved = {
          ...reserved,
          reserveStatus: line.reserve_status,
        };
      }

      if (line.reserve_result !== undefined) {
        reserved = {
          ...reserved,
          reserveResult: line.reserve_result,
        };
      }

      products.push({
        ...result,
        ...(Object.keys(reserved).length !== 0 && { reserved }),
      });
    });
  });

  return products;
};

export default function basketProductsSerializer(
  baskets: BasketTypeResponse[] | undefined,
): { products: any[]; licences: any[] } {
  if (baskets === undefined) return { products: [], licences: [] };

  const productsResponse = baskets.filter(
    (basket) => basket.type === 'NOT_A_LICENSE',
  );

  const licencesResponse = baskets.filter(
    (basket) => basket.type !== 'NOT_A_LICENSE',
  );

  return {
    products: oneTypeBasketsSerializer(productsResponse),
    licences: oneTypeBasketsSerializer(licencesResponse),
  };
}
