<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.99985 3.34985C4.98401 3.34985 3.34985 4.98401 3.34985 6.99985V16.9999C3.34985 19.0157 4.98401 20.6499 6.99985 20.6499H10.9999C13.0157 20.6499 14.6499 19.0157 14.6499 16.9999V15.9999C14.6499 15.6409 14.3588 15.3499 13.9999 15.3499C13.6409 15.3499 13.3499 15.6409 13.3499 15.9999V16.9999C13.3499 18.2977 12.2977 19.3499 10.9999 19.3499H6.99985C5.70198 19.3499 4.64985 18.2977 4.64985 16.9999V6.99985C4.64985 5.70198 5.70198 4.64985 6.99985 4.64985H10.9999C12.2977 4.64985 13.3499 5.70198 13.3499 6.99985V7.99985C13.3499 8.35884 13.6409 8.64985 13.9999 8.64985C14.3588 8.64985 14.6499 8.35884 14.6499 7.99985V6.99985C14.6499 4.98401 13.0157 3.34985 10.9999 3.34985H6.99985ZM17.4596 8.54033C17.2057 8.28649 16.7942 8.28649 16.5403 8.54033C16.2865 8.79417 16.2865 9.20573 16.5403 9.45957L18.4307 11.35H8.99995C8.64097 11.35 8.34995 11.641 8.34995 12C8.34995 12.3589 8.64097 12.65 8.99995 12.65H18.4307L16.5403 14.5403C16.2865 14.7942 16.2865 15.2057 16.5403 15.4596C16.7942 15.7134 17.2057 15.7134 17.4596 15.4596L20.4596 12.4596C20.7134 12.2057 20.7134 11.7942 20.4596 11.5403L17.4596 8.54033Z"
      fill="currentColor"
    />
  </svg>
</template>
