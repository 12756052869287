import { type Logo, type LogoRepository } from '~/domains/core';

type ReturnUseCase = (dependencies: {
  logosRepository: LogoRepository;
}) => Promise<Logo | undefined>;

const getLogoUseCase: ReturnUseCase = async ({ logosRepository }) => {
  return await logosRepository.getLogo();
};

export default getLogoUseCase;
