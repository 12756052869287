<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00011 9.5001L11.5461 3.98372C11.7249 3.59643 12.2753 3.59643 12.4541 3.98372L15.0001 9.5001L20.4162 9.91672C20.8421 9.94948 21.0335 10.4667 20.7314 10.7688L17.0001 14.5001L17.8531 20.0444C17.9148 20.4455 17.4982 20.7492 17.1353 20.5677L12.0001 18.0001L6.86493 20.5677C6.50199 20.7492 6.08543 20.4455 6.14714 20.0444L7.00011 14.5001L3.26882 10.7688C2.96676 10.4667 3.1581 9.94948 3.58402 9.91672L9.00011 9.5001Z"
      fill="currentColor"
    />
  </svg>
</template>
