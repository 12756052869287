<template>
  <AppHeaderActionWrapper @click="router.push('/personal-settings')">
    <UiText variant="body-4" color="white" class="d-flex align-center">
      <UiIcon icon="$account" class="mr-2" />
      {{ userName }}
    </UiText>
  </AppHeaderActionWrapper>
</template>

<script setup lang="ts">
import { UiText, UiIcon } from '~/ui';
import { useRouter } from '~/infrastructure';
import AppHeaderActionWrapper from './AppHeaderActionWrapper.vue';

defineProps<Props>();

const router = useRouter();

export interface Props {
  userName: string;
}
</script>
