import {
  type BrandById,
  type CategoryActionType,
  type CategoryBySlug,
  type CategoryRequestQuery,
} from './types.d';
import categoryRepository from './category.repository';

const categoryService = {
  async getCategoriesTree() {
    return await categoryRepository.getCategoriesTree();
  },

  async getCategoryBySlug(
    slug: string,
    params: CategoryRequestQuery,
  ): Promise<CategoryBySlug> {
    return await categoryRepository.getCategoryBySlug(slug, params);
  },

  async getCategoryInBrandBySlug(
    brandId: string,
    categorySlug: string,
    params: CategoryRequestQuery,
  ): Promise<CategoryBySlug> {
    return await categoryRepository.getCategoryInBrandBySlug(
      brandId,
      categorySlug,
      params,
    );
  },

  async getBrandById(
    id: string,
    params: CategoryRequestQuery,
  ): Promise<BrandById> {
    return await categoryRepository.getBrandById(id, params);
  },

  async getActionByType(
    type: CategoryActionType,
    params: CategoryRequestQuery,
  ): Promise<CategoryBySlug> {
    return await categoryRepository.getActionByType(type, params);
  },

  async getActionInTypeBySlug(
    actionType: CategoryActionType,
    slug: string,
    params: CategoryRequestQuery,
  ): Promise<CategoryBySlug> {
    return await categoryRepository.getActionInTypeBySlug(
      actionType,
      slug,
      params,
    );
  },

  async getMacCTOCategoryBySlug(
    slug: string,
    params: CategoryRequestQuery,
  ): Promise<CategoryBySlug> {
    return await categoryRepository.getCategoryBySlug(slug, params, 'cto');
  },
};

export default categoryService;
