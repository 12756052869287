<template>
  <RouterLink v-if="to !== null" :to="to" :class="colorClass" :target="target">
    <UiIcon v-if="prependIcon" :icon="prependIcon" class="mb-1" />
    <slot />
  </RouterLink>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { UiIcon } from '~/ui';
import { RouterLink, type RouteLocation } from '~/infrastructure';
import { type TargetTypes } from '~/app/types.d';

export interface Props {
  to: string | RouteLocation | null;
  prependIcon?: string;
  color?: string;
  target?: TargetTypes;
}

const props = withDefaults(defineProps<Props>(), {
  to: null,
  prependIcon: undefined,
  color: 'blue-500',
  target: '_blank',
});

const colorClass = computed(() => `text-${props.color}`);
</script>
