import { type ComparedFeature } from '~/domains/compare';

export class ComparedSpecification {
  constructor(
    public caption: string,
    public mergedFeatures: Map<
      string,
      ComparedFeature
    > /** @returns (feature) code -> ComparedFeature[] */,
  ) {}

  withUnmatchedFeatures(): ComparedSpecification {
    const filteredMergedFeaturesEntries = [
      ...this.mergedFeatures.entries(),
    ].filter(
      ([_, comparedFeature]) =>
        comparedFeature.hasOneValue() || !comparedFeature.hasSameValues(),
    );

    return new ComparedSpecification(
      this.caption,
      new Map(filteredMergedFeaturesEntries),
    );
  }

  hasFeatures(): boolean {
    return this.mergedFeatures.size > 0;
  }
}
