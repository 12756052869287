import type {
  OrdersOverviewResponse,
  UserOrderResponse,
  UserOrdersOrder,
  UserOrdersOverview,
} from '~/domains/user';

const userOrderOverviewOrdersSerializer = (
  orders: UserOrderResponse[] | undefined,
): UserOrdersOrder[] | undefined => {
  return orders !== undefined
    ? orders.map(userOrderOverviewOrderSerializer)
    : undefined;
};
const userOrderOverviewOrderSerializer = (
  order: UserOrderResponse,
): UserOrdersOrder => {
  return {
    status: order.status,
    qty: order.qty,
    lines: order.lines,
    products: order.products,
  };
};

export default function userOrderOverviewSerializer(
  data: OrdersOverviewResponse | undefined,
): UserOrdersOverview | undefined {
  if (data === undefined) return undefined;

  return {
    orders: userOrderOverviewOrdersSerializer(data.orders),
    lines: data.lines,
  };
}
