<template>
  <UiDropdown class="header-currency-dropdown">
    <template #activator="{ props }">
      <AppHeaderActionWrapper
        v-if="selectedCurrency"
        v-bind="props"
        data-test="header-currency-button"
      >
        <div class="d-flex align-center">
          <UiText variant="body-4" color="white">
            {{ selectedCurrency }}
          </UiText>

          <UiIcon icon="$chevronDown" class="ml-2" />
        </div>
      </AppHeaderActionWrapper>
    </template>
    <UiDropdownItem
      v-for="currency in currencies"
      :key="currency.currency"
      :value="currency.currency"
      :is-active="currency.currency === selectedCurrency"
      data-test="header-currency-item"
      class="currency-item"
      @click="changeCurrency(currency.currency)"
    >
      {{ currency.currency }}
    </UiDropdownItem>
  </UiDropdown>
</template>

<script setup lang="ts">
import { UiDropdown, UiDropdownItem, UiText, UiIcon } from '~/ui';
import useChangeCurrency from '~/domains/user/composables/useChangeCurrency.ts';
import AppHeaderActionWrapper from './AppHeaderActionWrapper.vue';

const { currencies, selectedCurrency, changeCurrency } = useChangeCurrency();
</script>

<style lang="scss" scoped>
.currency-item {
  :deep(.v-list-item) {
    cursor: pointer;
    position: relative;
    z-index: 1;
  }
}
</style>
