import { type UserPreferencesRequest } from '../types.d';
import { type UserPreferences } from '~/domains/user';
import omit from 'lodash-es/omit';

export default function userPreferencesDeserializer(
  data: UserPreferences,
): UserPreferencesRequest {
  const actualTableState =
    data.tableState != null
      ? JSON.stringify(data.tableState?.map((item: any) => omit(item, 'width')))
      : null;

  return {
    preferences: {
      displayView:
        data.displayView === null ? 'gridWithoutPhotos' : data.displayView,
      stockFilter: data.stockFilter === null ? 'catalog' : data.stockFilter,
      sorting: data.sorting != null ? JSON.stringify(data.sorting) : null,
      tableState: actualTableState,
    },
  };
}
