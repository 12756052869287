<template>
  <UiList class="py-0 bg-transparent">
    <UiSidebarMenuItem
      v-for="(item, i) in items"
      :key="i"
      :item="item"
      :is-sidebar-open="isSidebarOpen"
      @update="handleUpdate"
    />
  </UiList>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import UiList from '../UiList/UiList.vue';
import UiSidebarMenuItem from './UiSidebarMenuItem.vue';
import { type SidebarItem } from '~/app/layouts/sidebar';

export interface Props {
  items: SidebarItem[];
  modelValue: string[];
  isSidebarOpen: boolean;
}
defineProps<Props>();
const emit = defineEmits<(e: 'update:modelValue', value: string[]) => void>();

const state = ref<string[]>([]);

const handleUpdate = (value: boolean, id: string | number): void => {
  if (value) {
    state.value.push(String(id));
  } else {
    state.value.splice(state.value.indexOf(String(id)), 1);
  }
  emit('update:modelValue', state.value);
};
</script>
