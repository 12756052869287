<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.6501 12.0001C3.6501 7.38852 7.38852 3.6501 12.0001 3.6501C16.6117 3.6501 20.3501 7.38852 20.3501 12.0001C20.3501 16.6117 16.6117 20.3501 12.0001 20.3501C7.38852 20.3501 3.6501 16.6117 3.6501 12.0001ZM12.0001 2.3501C6.67055 2.3501 2.3501 6.67055 2.3501 12.0001C2.3501 17.3296 6.67055 21.6501 12.0001 21.6501C17.3296 21.6501 21.6501 17.3296 21.6501 12.0001C21.6501 6.67055 17.3296 2.3501 12.0001 2.3501ZM12 7.35C11.641 7.35 11.35 7.64102 11.35 8C11.35 8.35899 11.641 8.65 12 8.65H12.01C12.369 8.65 12.66 8.35899 12.66 8C12.66 7.64102 12.369 7.35 12.01 7.35H12ZM12.65 11C12.65 10.641 12.359 10.35 12 10.35C11.641 10.35 11.35 10.641 11.35 11V16C11.35 16.359 11.641 16.65 12 16.65C12.359 16.65 12.65 16.359 12.65 16V11Z"
      fill="currentColor"
    />
  </svg>
</template>
