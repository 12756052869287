<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icon" clip-path="url(#clip0_19212_65023)">
      <circle
        id="Ellipse 707"
        cx="9.9987"
        cy="10.0002"
        r="8.41667"
        stroke="#1C4D88"
        stroke-width="1.5"
      />
      <circle id="Ellipse 708" cx="10" cy="10" r="5" fill="#1C4D88" />
    </g>
    <defs>
      <clipPath id="clip0_19212_65023">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
