import { type RouteLocationNormalized } from 'vue-router';
import { useAuthStore, PATH_DICTIONARY } from '~/domains/auth';
import { usePermissions } from '~/infrastructure';
import { getUserInfoViewModel, useUserStore } from '~/domains/user';
import { checkNeedToChangePassword } from '../utils';
import { storeToRefs } from 'pinia';
import { useProductForceResetTable } from '~/domains/product';

const authGuard = async (
  to: RouteLocationNormalized,
): Promise<string | boolean> => {
  const authStore = useAuthStore();
  const { user } = storeToRefs(useUserStore());
  const { checkAccess } = usePermissions();
  const { fetchUserInfo } = getUserInfoViewModel();
  const { handleNeedResetTable } = useProductForceResetTable();

  if (authStore.isAuth) {
    if (user.value === null) await fetchUserInfo();

    handleNeedResetTable();
    const needToChangePassword = checkNeedToChangePassword(to);
    if (needToChangePassword !== undefined) return needToChangePassword;

    if (!checkAccess(to)) {
      return PATH_DICTIONARY.home;
    }

    return to.meta.layout === 'auth' ? PATH_DICTIONARY.home : true;
  }

  if (to.path === PATH_DICTIONARY.changePassword) {
    return PATH_DICTIONARY.login;
  }

  if (to.meta.layout === 'auth') return true;

  authStore.setRedirectAfterLogin(to.fullPath);

  return PATH_DICTIONARY.login;
};

export default authGuard;
