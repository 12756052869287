import CurrencyWithFlagJSON from '../data/curranciesWithFlags.json';
import { type CurrencyWithFlagRepository } from '../../ports';
import { type CurrencyWithFlag } from '~/domains/core';

const currencyWithFlagLocalRepository: CurrencyWithFlagRepository = {
  async getCurrencyWithFlag(): Promise<CurrencyWithFlag[]> {
    return CurrencyWithFlagJSON;
  },
};

export default currencyWithFlagLocalRepository;
