<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.54036 15.4598C6.28652 15.2059 6.28652 14.7944 6.54036 14.5405L11.5404 9.54053C11.7942 9.28669 12.2058 9.28669 12.4596 9.54053L17.4596 14.5405C17.7134 14.7944 17.7134 15.2059 17.4596 15.4598C17.2058 15.7136 16.7942 15.7136 16.5404 15.4598L12 10.9194L7.45959 15.4598C7.20575 15.7136 6.7942 15.7136 6.54036 15.4598Z"
      fill="currentColor"
    />
  </svg>
</template>
