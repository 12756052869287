import { type SplittedSortType } from '~/infrastructure';

export default <T>(sortString: string): SplittedSortType<T> => {
  const dividedArray: string[] = sortString.split('_');

  const sortBy: 'asc' | 'desc' = dividedArray[
    dividedArray.length - 1
  ].toLowerCase() as 'asc' | 'desc';
  dividedArray.pop();
  const field = dividedArray.join('_') as T;

  return {
    field,
    sort: sortBy,
  };
};
