<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.0002 2.34998C3.08893 2.34998 2.3502 3.08871 2.3502 3.99998V8.99998C2.3502 9.91125 3.08893 10.65 4.0002 10.65H20.0002C20.9115 10.65 21.6502 9.91125 21.6502 8.99998V3.99998C21.6502 3.08871 20.9115 2.34998 20.0002 2.34998H4.0002ZM3.6502 3.99998C3.6502 3.80668 3.8069 3.64998 4.0002 3.64998H20.0002C20.1935 3.64998 20.3502 3.80668 20.3502 3.99998V8.99998C20.3502 9.19328 20.1935 9.34998 20.0002 9.34998H4.0002C3.8069 9.34998 3.6502 9.19327 3.6502 8.99998V3.99998ZM6.0001 4.99988C5.44781 4.99988 5.0001 5.44759 5.0001 5.99988V6.99988C5.0001 7.55216 5.44781 7.99988 6.0001 7.99988H7.0001C7.55238 7.99988 8.0001 7.55216 8.0001 6.99988V5.99988C8.0001 5.44759 7.55238 4.99988 7.0001 4.99988H6.0001ZM5.0001 16.9999C5.0001 16.4476 5.44781 15.9999 6.0001 15.9999H7.0001C7.55238 15.9999 8.0001 16.4476 8.0001 16.9999V17.9999C8.0001 18.5522 7.55238 18.9999 7.0001 18.9999H6.0001C5.44781 18.9999 5.0001 18.5522 5.0001 17.9999V16.9999ZM4.0001 13.3499C3.08883 13.3499 2.3501 14.0886 2.3501 14.9999V19.9999C2.3501 20.9111 3.08883 21.6499 4.0001 21.6499H20.0001C20.9114 21.6499 21.6501 20.9111 21.6501 19.9999V14.9999C21.6501 14.0886 20.9114 13.3499 20.0001 13.3499H4.0001ZM3.6501 14.9999C3.6501 14.8066 3.8068 14.6499 4.0001 14.6499H20.0001C20.1934 14.6499 20.3501 14.8066 20.3501 14.9999V19.9999C20.3501 20.1932 20.1934 20.3499 20.0001 20.3499H4.0001C3.8068 20.3499 3.6501 20.1932 3.6501 19.9999V14.9999Z"
      fill="currentColor"
    />
  </svg>
</template>
