<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.5001 2.84998C3.58883 2.84998 2.8501 3.58871 2.8501 4.49998V9.49998C2.8501 10.4112 3.58883 11.15 4.5001 11.15H9.5001C10.4114 11.15 11.1501 10.4112 11.1501 9.49998V4.49998C11.1501 3.58871 10.4114 2.84998 9.5001 2.84998H4.5001ZM4.1501 4.49998C4.1501 4.30668 4.3068 4.14998 4.5001 4.14998H9.5001C9.6934 4.14998 9.8501 4.30668 9.8501 4.49998V9.49998C9.8501 9.69328 9.6934 9.84998 9.5001 9.84998H4.5001C4.3068 9.84998 4.1501 9.69328 4.1501 9.49998V4.49998ZM4.5001 12.85C3.58883 12.85 2.8501 13.5887 2.8501 14.5V19.5C2.8501 20.4112 3.58883 21.15 4.5001 21.15H9.5001C10.4114 21.15 11.1501 20.4112 11.1501 19.5V14.5C11.1501 13.5887 10.4114 12.85 9.5001 12.85H4.5001ZM4.1501 14.5C4.1501 14.3067 4.3068 14.15 4.5001 14.15H9.5001C9.6934 14.15 9.8501 14.3067 9.8501 14.5V19.5C9.8501 19.6933 9.6934 19.85 9.5001 19.85H4.5001C4.3068 19.85 4.1501 19.6933 4.1501 19.5V14.5ZM12.8501 4.49998C12.8501 3.58871 13.5888 2.84998 14.5001 2.84998H19.5001C20.4114 2.84998 21.1501 3.58871 21.1501 4.49998V9.49998C21.1501 10.4112 20.4114 11.15 19.5001 11.15H14.5001C13.5888 11.15 12.8501 10.4112 12.8501 9.49998V4.49998ZM14.5001 4.14998C14.3068 4.14998 14.1501 4.30668 14.1501 4.49998V9.49998C14.1501 9.69328 14.3068 9.84998 14.5001 9.84998H19.5001C19.6934 9.84998 19.8501 9.69328 19.8501 9.49998V4.49998C19.8501 4.30668 19.6934 4.14998 19.5001 4.14998H14.5001ZM14.5001 12.85C13.5888 12.85 12.8501 13.5887 12.8501 14.5V19.5C12.8501 20.4112 13.5888 21.15 14.5001 21.15H19.5001C20.4114 21.15 21.1501 20.4112 21.1501 19.5V14.5C21.1501 13.5887 20.4114 12.85 19.5001 12.85H14.5001ZM14.1501 14.5C14.1501 14.3067 14.3068 14.15 14.5001 14.15H19.5001C19.6934 14.15 19.8501 14.3067 19.8501 14.5V19.5C19.8501 19.6933 19.6934 19.85 19.5001 19.85H14.5001C14.3068 19.85 14.1501 19.6933 14.1501 19.5V14.5Z"
      fill="currentColor"
    />
  </svg>
</template>
