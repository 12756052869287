<template>
  <RouterLink to="/" class="d-block overflow-hidden">
    <UiImg
      v-if="currentLogo"
      :src="currentLogo"
      :width
      height="auto"
      alt="Company Logo"
      class="d-block"
    />
  </RouterLink>
</template>

<script setup lang="ts">
import { RouterLink } from '~/infrastructure';
import { computed } from 'vue';
import { useGetLogoViewModel } from '~/domains/core';
import { UiImg } from '~/ui';

export interface Props {
  variant?: 'light' | 'dark';
  width?: number | string;
}

const props = withDefaults(defineProps<Props>(), {
  variant: 'dark',
  width: 108,
});

const { logo } = useGetLogoViewModel();
const currentLogo = computed(() =>
  props.variant === 'dark' ? logo.value?.dark : logo.value?.light,
);
</script>
