import { type SocialRepository } from '../ports';
import { type Social } from '../entities';

type GetSocialUseCase = (dependencies: {
  socialRepository: SocialRepository;
}) => Promise<Social[] | undefined>;

const getSocialsUseCase: GetSocialUseCase = async ({ socialRepository }) => {
  return await socialRepository.getSocial();
};

export default getSocialsUseCase;
