import { type ProductSpecification } from '~/domains/product';

export class ComparedFeature {
  constructor(
    public name: string,
    public code: string,
    public values: Map<string, string> /** @returns sku -> feature value */,
  ) {}

  public static fromProductSpecification(
    sku: string,
    feature: ProductSpecification,
  ): ComparedFeature {
    const map = new Map([[sku, feature.value]]);
    return new ComparedFeature(feature.name, feature.code, map);
  }

  public addValue(sku: string, value: string): void {
    this.values.set(sku, value);
  }

  public hasOneValue(): boolean {
    return this.values.size === 1;
  }

  public hasSameValues(): boolean {
    const arr = [...this.values.values()];
    const first = arr[0];

    return arr.every((it) => it === first);
  }
}
