<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.6501 12C19.6501 12.359 19.3591 12.65 19.0001 12.65H6.56934L11.4597 17.5404C11.7135 17.7942 11.7135 18.2058 11.4597 18.4596C11.2059 18.7134 10.7943 18.7134 10.5405 18.4596L4.54048 12.4596C4.28664 12.2058 4.28664 11.7942 4.54048 11.5404L10.5405 5.54036C10.7943 5.28652 11.2059 5.28652 11.4597 5.54036C11.7135 5.7942 11.7135 6.20575 11.4597 6.45959L6.56933 11.35H19.0001C19.3591 11.35 19.6501 11.641 19.6501 12Z"
      fill="currentColor"
    />
  </svg>
</template>
