<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0001 2.34998C12.2536 2.34998 12.484 2.49739 12.5903 2.72759L15.4304 8.88115L21.55 9.35189C21.8031 9.37136 22.0217 9.53638 22.1097 9.77446C22.1978 10.0125 22.1392 10.2801 21.9597 10.4596L17.6926 14.7267L18.6426 20.9011C18.6795 21.1413 18.5794 21.3821 18.383 21.5252C18.1866 21.6684 17.9268 21.69 17.7094 21.5814L12.0001 18.7267L6.2908 21.5814C6.07344 21.69 5.81361 21.6684 5.61723 21.5252C5.42084 21.3821 5.32072 21.1413 5.35767 20.9011L6.30759 14.7267L2.0405 10.4596C1.861 10.2801 1.80242 10.0125 1.89049 9.77446C1.97856 9.53638 2.19717 9.37136 2.45026 9.35189L8.56984 8.88115L11.4099 2.72759C11.5162 2.49739 11.7466 2.34998 12.0001 2.34998ZM12.0001 4.55107L9.59029 9.77236C9.49157 9.98626 9.28485 10.13 9.04997 10.1481L3.95905 10.5397L7.45974 14.0404C7.60614 14.1868 7.67404 14.3942 7.64256 14.5988L6.83369 19.8565L11.7094 17.4186C11.8924 17.3271 12.1078 17.3271 12.2908 17.4186L17.1665 19.8565L16.3577 14.5988C16.3262 14.3942 16.3941 14.1868 16.5405 14.0404L20.0412 10.5397L14.9503 10.1481C14.7154 10.13 14.5087 9.98626 14.4099 9.77236L12.0001 4.55107Z"
      fill="currentColor"
    />
  </svg>
</template>
