<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
  >
    <path
      d="M6.63477 0.699951V4.84985H10.7847M2.48486 13.1497C2.11799 13.1497 1.76614 13.0039 1.50672 12.7445C1.2473 12.4851 1.10156 12.1332 1.10156 11.7664V0.699951H7.32642L10.7847 4.1582V11.7664C10.7847 12.1332 10.6389 12.4851 10.3795 12.7445C10.1201 13.0039 9.76824 13.1497 9.40137 13.1497H2.48486Z"
      stroke="currentColor"
      stroke-width="1.05"
      stroke-linejoin="round"
    />
  </svg>
</template>
