<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <rect
      x="2.65"
      y="2.65"
      width="15.6997"
      height="5.7"
      rx="1.35"
      stroke="currentColor"
      stroke-width="1.3"
    />
    <rect
      x="2.65"
      y="11.65"
      width="6.25541"
      height="5.7"
      rx="1.35"
      stroke="currentColor"
      stroke-width="1.3"
    />
    <rect
      x="12.0948"
      y="11.65"
      width="6.25541"
      height="5.7"
      rx="1.35"
      stroke="currentColor"
      stroke-width="1.3"
    />
  </svg>
</template>
