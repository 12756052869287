import { type UserSelectedResponse } from '../types.d';
import { type UserSelected } from '~/domains/user';

export default function userChangeRequestSerializer(
  data: UserSelected,
): UserSelectedResponse {
  return {
    show_prices_with_vat: data.showPricesWithVat,
    currency: data.currency,
    sid: data.supplierId,
    cid: data.companyId,
  };
}
