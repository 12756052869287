<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.6501 12.0001C3.6501 7.38852 7.38852 3.6501 12.0001 3.6501C16.6117 3.6501 20.3501 7.38852 20.3501 12.0001C20.3501 16.6117 16.6117 20.3501 12.0001 20.3501C7.38852 20.3501 3.6501 16.6117 3.6501 12.0001ZM12.0001 2.3501C6.67055 2.3501 2.3501 6.67055 2.3501 12.0001C2.3501 17.3296 6.67055 21.6501 12.0001 21.6501C17.3296 21.6501 21.6501 17.3296 21.6501 12.0001C21.6501 6.67055 17.3296 2.3501 12.0001 2.3501ZM12.65 7C12.65 6.64102 12.359 6.35 12 6.35C11.641 6.35 11.35 6.64102 11.35 7V13C11.35 13.2173 11.4586 13.4203 11.6394 13.5408L14.6394 15.5408C14.9381 15.74 15.3417 15.6592 15.5408 15.3606C15.74 15.0619 15.6592 14.6583 15.3606 14.4592L12.65 12.6521V7Z"
      fill="currentColor"
    />
  </svg>
</template>
