<template>
  <UiDropdown
    v-if="isShowManagerDropdown"
    :close-on-content-click="false"
    class="manager__dropdown"
  >
    <template #activator="{ props: activatorProps }">
      <AppHeaderActionWrapper v-bind="activatorProps">
        <UiRow no-gutters class="manager">
          <UiCol cols="auto">
            <UiText variant="body-4" color="grey-400" class="manager__title">
              <AppTranslate identifier="header.info.manager.label" />:
            </UiText>
          </UiCol>
          <UiCol cols="auto">
            <button class="manager__button" type="button">
              <UiText variant="body-4" color="white">
                {{ manager?.fullName }}
              </UiText>

              <UiIcon icon="$chevronDown" class="ml-2" />
            </button>
          </UiCol>
        </UiRow>
      </AppHeaderActionWrapper>
    </template>
    <AppHeaderManagerInfo
      :email="managerEmail"
      :phone="managerPhone"
      :messengers="managerMessengers"
      :avatar="manager?.avatarUrl"
      :manager-name="manager?.fullName"
    />
  </UiDropdown>

  <AppHeaderActionWrapper v-else :active="false">
    <UiRow no-gutters class="manager">
      <UiCol cols="auto">
        <UiText variant="body-4" color="grey-400" class="manager__title">
          <AppTranslate identifier="header.info.manager.label" />:
        </UiText>
      </UiCol>
      <UiCol cols="auto">
        <UiText variant="body-4" color="white">
          {{ manager?.fullName }}
        </UiText>
      </UiCol>
    </UiRow>
  </AppHeaderActionWrapper>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { UiCol, UiDropdown, UiIcon, UiRow, UiText } from '~/ui';
import { AppTranslate } from '~/infrastructure';
import { type UserInfoManager } from '~/domains/user';
import AppHeaderManagerInfo from './AppHeaderManagerInfo.vue';
import { type ManagerInfo } from '~/app/types.d';
import AppHeaderActionWrapper from './AppHeaderActionWrapper.vue';

export interface Props {
  manager: UserInfoManager;
}

const props = defineProps<Props>();

const managerMessengers = computed<ManagerInfo[]>(() => {
  const result = [];

  if (
    props.manager?.messengers !== undefined &&
    props.manager?.messengers.length > 0
  ) {
    props.manager?.messengers.forEach((messenger) => {
      result.push({
        icon: `$${messenger.kind}`,
        label: messenger.name,
        href: messenger.link,
      });
    });
  }

  if (props.manager?.email != null)
    result.unshift({
      icon: '$teams',
      label: props.manager?.email,
      href: `https://teams.microsoft.com/l/chat/0/0?users=${props.manager?.email}`,
    });

  return result;
});

const managerEmail = computed(() => {
  if (props.manager?.email != null) {
    return {
      icon: '$mail',
      label: props.manager?.email,
      href: `mailto:${props.manager?.email}`,
    };
  }

  return undefined;
});

const managerPhone = computed(() => {
  if (props.manager?.mobile != null)
    return {
      icon: '$phone',
      label: props.manager?.mobile,
      href: `tel:${props.manager?.mobile}`,
    };

  return undefined;
});

const isShowManagerDropdown = computed(() => {
  return (
    managerMessengers.value.length > 0 ||
    managerPhone.value !== undefined ||
    managerEmail.value !== undefined
  );
});
</script>

<style lang="scss" scoped>
.manager {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2px;

  &__button {
    display: flex;
    align-items: center;
  }

  &__dropdown {
    :deep(.ui-dropdown__list) {
      margin-top: 15px;
    }
  }

  &__title {
    line-height: 12px;
    margin-bottom: -3px;
  }

  @media (min-width: 1280px) {
    margin-top: 0;
    flex-direction: row;
    align-items: center;

    &__title {
      margin-right: 4px;
      line-height: inherit;
      margin-bottom: 0;
    }
  }
}
</style>
