<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.0001 5.6501C7.25451 5.6501 6.6501 6.25451 6.6501 7.0001V18.0001C6.6501 18.7457 7.25451 19.3501 8.0001 19.3501H10.0001C10.3591 19.3501 10.6501 19.6411 10.6501 20.0001C10.6501 20.3591 10.3591 20.6501 10.0001 20.6501H8.0001C6.53654 20.6501 5.3501 19.4637 5.3501 18.0001V7.0001C5.3501 5.53654 6.53654 4.3501 8.0001 4.3501H13.0001C13.1725 4.3501 13.3378 4.41858 13.4597 4.54048L18.4597 9.54048C18.5816 9.66238 18.6501 9.82771 18.6501 10.0001V14.0001C18.6501 14.3591 18.3591 14.6501 18.0001 14.6501C17.6411 14.6501 17.3501 14.3591 17.3501 14.0001V10.6501H14.0001C13.0888 10.6501 12.3501 9.91137 12.3501 9.0001V5.6501H8.0001ZM13.6501 6.56934L16.4309 9.3501H14.0001C13.8068 9.3501 13.6501 9.1934 13.6501 9.0001V6.56934ZM14.8901 15.9801C15.1773 16.1955 15.2355 16.6029 15.0201 16.8901L14.3001 17.8501H18.0001C18.3591 17.8501 18.6501 18.1411 18.6501 18.5001C18.6501 18.8591 18.3591 19.1501 18.0001 19.1501H14.3001L15.0201 20.1101C15.2355 20.3973 15.1773 20.8047 14.8901 21.0201C14.6029 21.2355 14.1955 21.1773 13.9801 20.8901L12.4801 18.8901C12.3068 18.659 12.3068 18.3412 12.4801 18.1101L13.9801 16.1101C14.1955 15.8229 14.6029 15.7647 14.8901 15.9801Z"
      fill="currentColor"
    />
  </svg>
</template>
