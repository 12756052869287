import { getCurrencyWithFlagUseCase } from '../../useCases';
import { currencyWithFlagLocalRepository } from '../repositories';
import { type CurrencyWithFlag } from '../../entities';

const useGetCurrencyWithFlagAdapter = (): (() => Promise<
  CurrencyWithFlag[] | undefined
>) => {
  const getCurrencyWithFlag = async (): Promise<
    CurrencyWithFlag[] | undefined
  > => {
    return await getCurrencyWithFlagUseCase({
      currencyWithFlagRepository: currencyWithFlagLocalRepository,
    });
  };

  return getCurrencyWithFlag;
};

export default useGetCurrencyWithFlagAdapter;
