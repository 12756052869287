<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.9596 7.04048C14.7058 6.78664 14.2942 6.78664 14.0404 7.04048L9.0404 12.0405C8.78656 12.2943 8.78656 12.7059 9.0404 12.9597L14.0404 17.9597C14.2942 18.2136 14.7058 18.2136 14.9596 17.9597C15.2135 17.7059 15.2135 17.2943 14.9596 17.0405L10.4193 12.5001L14.9596 7.95972C15.2135 7.70588 15.2135 7.29432 14.9596 7.04048Z"
      fill="currentColor"
    />
  </svg>
</template>
