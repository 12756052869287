<template>
  <UiTooltip v-model="isOpen" :open-on-hover="false" :open-on-click="true">
    <template #activator="slotProps">
      <UiButton
        v-bind="slotProps.props"
        :height="height"
        :width="width"
        :class="currentColor"
        :color="bgColor"
        rounded="md"
        icon="$copy"
        size="x-small"
        data-html2canvas-ignore
        class="clipboard"
        @click="copyToClipboard"
      >
      </UiButton>
    </template>
    Copied <UiIcon icon="$check" color="primary" size="18" class="mt-n1" />
  </UiTooltip>
</template>
<script setup lang="ts">
import { UiIcon, UiButton } from '~/ui';
import UiTooltip from '../UiTooltip/UiTooltip.vue';

import { ref, computed } from 'vue';

export interface Props {
  copy: string;
  color?: string;
  bgColor?: string;
  width?: number | string;
  height?: number | string;
}
const props = withDefaults(defineProps<Props>(), {
  color: 'grey-600',
  bgColor: 'transparent',
  width: '24',
  height: '24',
});

const isOpen = ref(false);
const tooltipDelay = 2000;
const currentColor = computed(() => `text-${props.color}`);

const closeTooltip = (): void => {
  setTimeout(() => {
    isOpen.value = !isOpen.value;
  }, tooltipDelay);
};

const copyToClipboard = (): void => {
  navigator.clipboard.writeText(props.copy.trim());
  closeTooltip();
};
</script>

<style lang="scss" scoped>
.clipboard {
  position: relative;
  top: -2px;
}
</style>
