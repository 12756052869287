<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 14.5L14.5 9.5M14.49 14.5H14.5M9.5 9.5H9.51M12.0001 3C13.2257 3 13.8534 3.8141 14.7937 4.44514C15.8966 5.188 17.3226 4.59434 18.3641 5.63583C19.2333 6.505 19.0923 7.52088 19.3172 8.63292C19.4855 9.48591 20.3496 9.97503 20.7131 10.7336C21.0956 11.5346 21.0956 12.4652 20.7131 13.2653C20.3518 14.0253 19.4855 14.5178 19.3172 15.3669C19.0949 16.4782 19.232 17.4964 18.3643 18.3645C17.5116 19.2176 16.4763 19.1442 15.367 19.317C14.0816 19.5711 13.4596 21 12.0005 21C10.7945 21 10.1138 20.2171 9.20733 19.5546C8.11925 18.8239 6.66838 19.3964 5.63557 18.3641C4.59424 17.3233 5.18549 15.8977 4.44535 14.7936C3.78547 13.8847 3 13.2075 3 12.0003C3 10.7945 3.78321 10.1136 4.44535 9.20713C5.1883 8.10413 4.59385 6.67678 5.63542 5.63521C6.67665 4.59398 8.10174 5.1841 9.20733 4.44514C10.1425 3.80925 10.7776 3 12.0001 3Z"
      stroke="currentColor"
      stroke-width="1.3"
      stroke-linecap="round"
    />
  </svg>
</template>
