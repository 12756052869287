<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.34985 5.99985C3.34985 5.64087 3.64087 5.34985 3.99985 5.34985H19.9999C20.3588 5.34985 20.6499 5.64087 20.6499 5.99985C20.6499 6.35884 20.3588 6.64985 19.9999 6.64985H3.99985C3.64087 6.64985 3.34985 6.35884 3.34985 5.99985ZM3.34985 11.9999C3.34985 11.6409 3.64087 11.3499 3.99985 11.3499H19.9999C20.3588 11.3499 20.6499 11.6409 20.6499 11.9999C20.6499 12.3588 20.3588 12.6499 19.9999 12.6499H3.99985C3.64087 12.6499 3.34985 12.3588 3.34985 11.9999ZM3.99985 17.3499C3.64087 17.3499 3.34985 17.6409 3.34985 17.9999C3.34985 18.3588 3.64087 18.6499 3.99985 18.6499H19.9999C20.3588 18.6499 20.6499 18.3588 20.6499 17.9999C20.6499 17.6409 20.3588 17.3499 19.9999 17.3499H3.99985Z"
      fill="currentColor"
    />
  </svg>
</template>
