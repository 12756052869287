<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.1255 7.45483C20.1255 7.28244 20.0571 7.11711 19.9352 6.99521L16.3996 3.45968C16.2777 3.33778 16.1124 3.2693 15.94 3.2693C15.7676 3.2693 15.6023 3.33778 15.4804 3.45968L13.3591 5.581L5.58089 13.3592C5.47337 13.4667 5.40694 13.6085 5.39318 13.7599L5.03962 17.649C5.02216 17.8411 5.09093 18.0311 5.22734 18.1675C5.36374 18.3039 5.55369 18.3727 5.7458 18.3552L9.63489 18.0017C9.78632 17.9879 9.92814 17.9215 10.0357 17.8139L17.8138 10.0358L19.9352 7.91445C20.0571 7.79255 20.1255 7.62722 20.1255 7.45483ZM18.5563 7.45483L17.3542 8.65692L14.7379 6.04062L15.94 4.83854L18.5563 7.45483ZM13.8187 6.95986L16.435 9.57615L9.28283 16.7283L6.4049 16.9899L6.66653 14.112L13.8187 6.95986Z"
      fill="currentColor"
    />
  </svg>
</template>
