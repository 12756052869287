import i18n from './i18n';
import router from './router';
import head from './head';
import vuetify from './vuetify';
import pinia from './pinia';
import snackbar from './snackbar';
import registerQuery from './query';
import { type App } from 'vue';
import * as Sentry from '@sentry/vue';

export const registerPlugins = (app: App): void => {
  if (import.meta.env.VITE_SENTRY_DNS !== undefined) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DNS,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.feedbackIntegration({
          colorScheme: 'system',
        }),
        Sentry.replayIntegration(),
      ],

      sendClientReports: false,
      tracesSampleRate: 1.0,
      tracePropagationTargets: ['localhost'],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      environment: import.meta.env.VITE_ENV,
    });
  }

  app.use(i18n);
  app.use(router);
  app.use(head);
  app.use(vuetify);
  app.use(pinia);
  app.use(snackbar);
  registerQuery(app);
};

export { i18n, router, head, vuetify, pinia, snackbar };
