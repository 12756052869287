import {
  type CurrencyWithFlagRepository,
  type NotificationService,
} from '../ports';
import { type CurrencyWithFlag } from '../entities';

type GetFlagsUseCaseReturn = (dependencies: {
  currencyWithFlagRepository: CurrencyWithFlagRepository;
  notifier?: NotificationService;
}) => Promise<CurrencyWithFlag[] | undefined>;

const getCurrencyWithFlagUseCase: GetFlagsUseCaseReturn = async ({
  currencyWithFlagRepository,
  notifier,
}) => {
  try {
    return await currencyWithFlagRepository.getCurrencyWithFlag();
  } catch (e) {
    notifier?.notifyError?.('Currency with flag list was not loaded');
    return undefined;
  }
};

export default getCurrencyWithFlagUseCase;
