import { type RouteLocationNormalized } from 'vue-router';

export default (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
): RouteLocationNormalized | boolean => {
  const isFromQueryEnableTranslateOff = from.query['translate-off'] === 'true';
  const isToQueryNotConsistTranslateOff =
    to.query['translate-off'] === undefined;

  if (isFromQueryEnableTranslateOff && isToQueryNotConsistTranslateOff) {
    to.query['translate-off'] = 'true';

    return { ...to };
  } else {
    return true;
  }
};
