import api from '~/infrastructure/api/api.service';
import authSerializer from './auth.serializer';
import {
  type LoginResponse,
  type PingResponse,
  type LogoutResponse,
  type LoginRequest,
  type LoginFormData,
  type PingStatus,
  type ForgotPasswordResponse,
  type ResetPasswordResponse,
  type Link,
} from './types.d';
import { windowService } from '~/domains/core';

const authRepository = {
  async login(loginForm: LoginFormData) {
    const body: LoginRequest = {
      username: loginForm.login.trim(),
      password: loginForm.password,
      include_info: false,
    };

    const response: LoginResponse = await api('/logon', {
      method: 'POST',
      body,
    });

    return authSerializer.login(response);
  },

  async ping(): Promise<{ status: PingStatus }> {
    const response: PingResponse = await api('/ping', {
      method: 'GET',
    });

    return {
      status: response.status,
    };
  },

  async logout(): Promise<LogoutResponse> {
    const response = await api('/logoff', {
      method: 'POST',
    });

    return response;
  },

  async forgotPassword(body: FormData): Promise<ForgotPasswordResponse> {
    const response = await api('/user/password/forgot', {
      method: 'POST',
      body,
    });

    return response;
  },

  async resetPassword(body: FormData): Promise<ResetPasswordResponse> {
    const response = await api('/user/password/reset', {
      method: 'POST',
      body,
    });

    return response;
  },

  async getBecomeACustomerLink(): Promise<Link | undefined> {
    const hostname = windowService.getLocation().hostname;

    try {
      const response = await api(`/public/${hostname}/become-our-partner.json`);

      return response;
    } catch (error) {
      console.error(error);
    }
  },
};

export default authRepository;
