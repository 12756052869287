<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.99961 8.34961C3.64062 8.34961 3.34961 8.64062 3.34961 8.99961C3.34961 9.35859 3.64062 9.64961 3.99961 9.64961H19.9996C20.3586 9.64961 20.6496 9.35859 20.6496 8.99961C20.6496 8.64062 20.3586 8.34961 19.9996 8.34961H3.99961ZM3.9998 14.3498C3.64082 14.3498 3.3498 14.6408 3.3498 14.9998C3.3498 15.3588 3.64082 15.6498 3.9998 15.6498H19.9998C20.3588 15.6498 20.6498 15.3588 20.6498 14.9998C20.6498 14.6408 20.3588 14.3498 19.9998 14.3498H3.9998Z"
      fill="currentColor"
    />
  </svg>
</template>
