function numberWithSpaces(number: string | number): string {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function priceWithoutSpaces(price: string | number): string {
  return price.toString().split(' ').join('');
}

export function formatPrice(price: string): string | null {
  if (price === '-') return null;

  const priceWithDecimal = Number(priceWithoutSpaces(price)).toFixed(2);
  return numberWithSpaces(priceWithDecimal);
}
