import { type Product } from '~/domains/product';
import { type CompareRepository } from '../ports';
import { type CompareGroup } from '~/domains/compare';

interface Dependencies {
  notifier?: {
    notifySuccess: (productArticle: string) => void;
    notifyError: () => void;
  };
  storage?: {
    updateCompare: (groups: CompareGroup[]) => void;
  };
  compareRepository: CompareRepository;
}

export async function removeProductFromCompareUseCase(
  product: Product,
  { notifier, storage, compareRepository }: Dependencies,
): Promise<void> {
  try {
    const result = await compareRepository.deleteProduct(product);

    notifier?.notifySuccess(product.sku);
    storage?.updateCompare(result);
  } catch (error) {
    notifier?.notifyError();
  }
}
