<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.6661 5.81178C23.5295 5.26731 23.2616 4.77078 22.8893 4.37189C22.517 3.973 22.0533 3.68573 21.5447 3.53885C19.6723 3 12.167 3 12.167 3C12.167 3 4.66163 3 2.78931 3.53599C2.28044 3.68239 1.81652 3.96949 1.44415 4.36846C1.07178 4.76742 0.804062 5.26421 0.667885 5.80892C0.166992 7.81529 0.166992 12 0.166992 12C0.166992 12 0.166992 16.1847 0.667885 18.1882C0.943778 19.2946 1.75806 20.1659 2.78931 20.4611C4.66163 21 12.167 21 12.167 21C12.167 21 19.6723 21 21.5447 20.4611C22.5786 20.1659 23.3902 19.2946 23.6661 18.1882C24.167 16.1847 24.167 12 24.167 12C24.167 12 24.167 7.81529 23.6661 5.81178ZM9.78306 15.8408V8.15924L15.9973 11.9713L9.78306 15.8408Z"
      fill="white"
    />
  </svg>
</template>
