<template>
  <VMenu
    :model-value="modelValue"
    :location="location"
    :open-on-hover="openOnHover"
    :open-on-focus="openOnFocus"
    :close-on-content-click="closeOnContentClick"
    :disabled="disabled"
    :offset="offset"
    @update:model-value="handleChange"
  >
    <slot />
    <template #activator="data">
      <slot name="activator" v-bind="data"></slot>
    </template>
  </VMenu>
</template>

<script setup lang="ts">
import { VMenu } from 'vuetify/components/VMenu';
import { ref, watch } from 'vue';
import { useRoute } from '~/infrastructure';

type Location = 'bottom' | 'end' | 'top left';

export interface Props {
  location?: Location;
  openOnHover?: boolean;
  closeOnContentClick?: boolean;
  openOnFocus?: boolean;
  disabled?: boolean;
  offset?: string | number | number[];
}

withDefaults(defineProps<Props>(), {
  openOnHover: false,
  location: 'bottom',
  closeOnContentClick: true,
  openOnFocus: false,
  disabled: false,
  offset: undefined,
});

const emit = defineEmits<(e: 'update:modelValue', value: boolean) => void>();

const modelValue = ref(false);

const handleChange = (value: boolean): void => {
  modelValue.value = value;
  emit('update:modelValue', value);
};
const open = (): void => {
  handleChange(true);
};
const close = (): void => {
  handleChange(false);
};

const route = useRoute();

watch(
  () => route.path,
  () => {
    close();
  },
);

defineExpose({
  open,
  close,
});
</script>
