import { type CompareRepository } from '../ports';
import { type CompareGroup } from '~/domains/compare';

interface Dependencies {
  notifier?: {
    notifySuccess: () => void;
    notifyError: () => void;
  };
  storage?: {
    updateCompare: (groups: CompareGroup[]) => void;
  };
  compareRepository: CompareRepository;
}

export async function removeAllFromProductTypeUseCase(
  productTypeId: string,
  { notifier, storage, compareRepository }: Dependencies,
): Promise<void> {
  try {
    const result =
      await compareRepository.deleteAllFromProductType(productTypeId);

    notifier?.notifySuccess();
    storage?.updateCompare(result);
  } catch (e) {
    console.error(e);
    notifier?.notifyError();
  }
}
