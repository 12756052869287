<template>
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.61585 5.53907L0.683373 0.820092C0.411987 0.494429 0.643565 0 1.06748 0H8.93244C9.35636 0 9.58794 0.494428 9.31655 0.820091L5.38407 5.53907C5.18418 5.77894 4.81575 5.77894 4.61585 5.53907Z"
      fill="currentColor"
    />
  </svg>
</template>
