interface DomainRedirectReturn {
  handleRedirectToAnotherDomain: (shopUrl: string, token: string) => void;
}

export default function useDomainRedirect(): DomainRedirectReturn {
  const redirectToAnotherDomain = (path: string): void => {
    window.location.href = path;
  };

  const redirectToAnotherDomainWithToken = (
    domain: string,
    token: string,
  ): void => {
    const path = `${domain}/login-by-token?token=${token}`;

    redirectToAnotherDomain(path);
  };

  const handleRedirectToAnotherDomain = (
    shopUrl: string,
    token: string,
  ): void => {
    const currentDomain = window.location.origin;
    const redirectDomain = shopUrl;

    if (currentDomain !== redirectDomain) {
      redirectToAnotherDomainWithToken(redirectDomain, token);
    }
  };

  return {
    handleRedirectToAnotherDomain,
  };
}
