<template>
  <div class="product-type-card-list">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.product-type-card-list {
  display: flex;
  gap: 8px;
}
</style>
