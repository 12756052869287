<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.65 7.99985C8.65 6.1497 10.1498 4.64985 12 4.64985C13.8502 4.64985 15.35 6.1497 15.35 7.99985C15.35 9.85001 13.8502 11.3499 12 11.3499C10.1498 11.3499 8.65 9.85001 8.65 7.99985ZM12 3.34985C9.43188 3.34985 7.35 5.43173 7.35 7.99985C7.35 9.55849 8.11685 10.938 9.29385 11.7817C5.84203 12.9177 3.3501 16.1678 3.3501 20C3.3501 20.3589 3.64111 20.65 4.0001 20.65C4.35908 20.65 4.6501 20.3589 4.6501 20C4.6501 15.9407 7.9408 12.65 12.0001 12.65C16.0594 12.65 19.3501 15.9407 19.3501 20C19.3501 20.3589 19.6411 20.65 20.0001 20.65C20.3591 20.65 20.6501 20.3589 20.6501 20C20.6501 16.1678 18.1581 12.9177 14.7062 11.7817C15.8832 10.938 16.65 9.55846 16.65 7.99985C16.65 5.43173 14.5681 3.34985 12 3.34985Z"
      fill="currentColor"
    />
  </svg>
</template>
