import {
  type Logo,
  type Social,
  windowService,
  type DefaultDomainConfig,
  DOMAIN_PATTERNS,
  DOMAINS,
  domainConfig,
} from '~/domains/core';
import has from 'lodash-es/has';
import { useDateFormatter } from '~/infrastructure';
import { computed, type ComputedRef } from 'vue';

interface ReturnInterface {
  getLogoData: () => Logo;
  getSocialData: () => Array<{ name: string; link: string; icon: string }>;
  footerCopyright: ComputedRef<string>;
  loginFormTitle: ComputedRef<string>;
  updateFavicon: () => void;
}

export default (): ReturnInterface => {
  const hostname = windowService.getLocation().hostname;
  const { currentYear } = useDateFormatter();
  const { config } = domainConfig();

  const defaultConfig = config[DOMAINS.ASBIS] as DefaultDomainConfig;
  const matchedConfig = Object.entries(config).find(([key]) =>
    hostname.includes(DOMAIN_PATTERNS[key as keyof typeof DOMAIN_PATTERNS]),
  )?.[1];

  const getLogoData = (): Logo => {
    return matchedConfig?.logo ?? defaultConfig.logo;
  };

  const getSocialData = (): Social[] => {
    if (
      matchedConfig?.socials !== undefined &&
      has(matchedConfig.socials, hostname)
    ) {
      return matchedConfig.socials[hostname] as Social[];
    }

    return defaultConfig.socials as Social[];
  };

  const updateFavicon = (): void => {
    const faviconPath = matchedConfig?.favicon ?? defaultConfig.favicon;

    let link: HTMLLinkElement | null =
      document.querySelector("link[rel~='icon']");
    if (link !== null) {
      link.href = faviconPath;
    } else {
      link = document.createElement('link');
      link.rel = 'icon';
      link.href = faviconPath;
      document.head.appendChild(link);
    }
  };

  const footerCopyright: ComputedRef<string> = computed(
    () =>
      matchedConfig?.footerCopyright?.(currentYear.value) ??
      defaultConfig.footerCopyright(currentYear.value),
  );

  const loginFormTitle: ComputedRef<string> = computed(
    () => matchedConfig?.loginFormTitle ?? defaultConfig.loginFormTitle,
  );

  return {
    getLogoData,
    getSocialData,
    updateFavicon,
    footerCopyright,
    loginFormTitle,
  };
};
