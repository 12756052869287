<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.54036 9.54048C6.28652 9.79432 6.28652 10.2059 6.54036 10.4597L11.5404 15.4597C11.7942 15.7136 12.2058 15.7136 12.4596 15.4597L17.4596 10.4597C17.7134 10.2059 17.7134 9.79432 17.4596 9.54048C17.2058 9.28664 16.7942 9.28664 16.5404 9.54048L12 14.0809L7.45959 9.54048C7.20575 9.28664 6.7942 9.28664 6.54036 9.54048Z"
      fill="currentColor"
    />
  </svg>
</template>
