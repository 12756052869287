<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.0001 2.3501C3.53654 2.3501 2.3501 3.53654 2.3501 5.0001V19.0001C2.3501 20.4637 3.53654 21.6501 5.0001 21.6501H19.0001C20.4637 21.6501 21.6501 20.4637 21.6501 19.0001V5.0001C21.6501 3.53654 20.4637 2.3501 19.0001 2.3501H5.0001ZM3.6501 5.0001C3.6501 4.25451 4.25451 3.6501 5.0001 3.6501H19.0001C19.7457 3.6501 20.3501 4.25451 20.3501 5.0001V19.0001C20.3501 19.7457 19.7457 20.3501 19.0001 20.3501H5.0001C4.25451 20.3501 3.6501 19.7457 3.6501 19.0001V5.0001ZM18.0001 18.0001V13.3576C18.0001 10.8712 16.7305 9.71337 15.0385 9.71337C13.6717 9.71337 13.0621 10.4995 12.7201 11.052V11.0771H12.7033L12.7201 11.0507V9.90424H10.1473C10.1821 10.664 10.1473 18.0001 10.1473 18.0001H12.7201V13.4781C12.7201 13.237 12.7381 12.9959 12.8053 12.8226C12.9913 12.3379 13.4149 11.8381 14.1253 11.8381C15.0565 11.8381 15.4285 12.5803 15.4285 13.6677V18.0001H18.0001ZM6.0001 7.39901C6.0001 6.60537 6.5761 6.0001 7.4557 6.0001C8.3365 6.0001 8.8777 6.60537 8.8945 7.39901C8.8945 8.17632 8.3365 8.79918 7.4389 8.79918H7.4221C6.5593 8.79918 6.0001 8.17758 6.0001 7.39901ZM8.7253 18.0001H6.1525V9.90424H8.7253V18.0001Z"
      fill="#0077B5"
    />
  </svg>
</template>
