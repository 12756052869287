import { useGetSocialAdapter } from '../../infrastructure/adapters';
import { useFetch } from '~/infrastructure/composables';
import { computed, type ComputedRef, type Ref } from 'vue';
import { type Social } from '../../entities';

interface ViewModelReturn {
  socials: ComputedRef<Social[] | undefined> | undefined;
  loading: Ref<boolean>;
}

export default function useGetSocialViewModel(): ViewModelReturn {
  const { data, loading } = useFetch(async () => await useGetSocialAdapter());

  const socials = computed(() => data.value);

  return {
    loading,
    socials,
  };
}
