<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.6499 12.0001C3.6499 7.38852 7.38832 3.6501 11.9999 3.6501C16.6115 3.6501 20.3499 7.38852 20.3499 12.0001C20.3499 16.6117 16.6115 20.3501 11.9999 20.3501C7.38832 20.3501 3.6499 16.6117 3.6499 12.0001ZM11.9999 2.3501C6.67035 2.3501 2.3499 6.67055 2.3499 12.0001C2.3499 17.3296 6.67035 21.6501 11.9999 21.6501C17.3295 21.6501 21.6499 17.3296 21.6499 12.0001C21.6499 6.67055 17.3295 2.3501 11.9999 2.3501ZM15.9596 10.4597C16.2134 10.2058 16.2134 9.79427 15.9596 9.54043C15.7057 9.28659 15.2942 9.28659 15.0403 9.54043L10.9995 13.5813L8.95908 11.5409C8.70524 11.2871 8.29368 11.2871 8.03984 11.5409C7.786 11.7948 7.786 12.2063 8.03984 12.4602L10.5398 14.9602C10.7937 15.214 11.2052 15.214 11.4591 14.9602L15.9596 10.4597Z"
      fill="currentColor"
    />
  </svg>
</template>
