<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.65 4C2.73873 4 2 4.73873 2 5.65V17.65C2 18.5613 2.73873 19.3 3.65 19.3H14.133C14.3853 19.8881 14.9695 20.3 15.65 20.3H19.65C20.5613 20.3 21.3 19.5613 21.3 18.65V9.65C21.3 8.73873 20.5613 8 19.65 8H18.3V5.65C18.3 4.73873 17.5613 4 16.65 4H3.65ZM17 8V5.65C17 5.4567 16.8433 5.3 16.65 5.3H3.65C3.4567 5.3 3.3 5.4567 3.3 5.65V17.65C3.3 17.8433 3.4567 18 3.65 18H14V9.65C14 8.73873 14.7387 8 15.65 8H17ZM15.3 9.65C15.3 9.4567 15.4567 9.3 15.65 9.3H19.65C19.8433 9.3 20 9.4567 20 9.65V18.65C20 18.8433 19.8433 19 19.65 19H15.65C15.4567 19 15.3 18.8433 15.3 18.65V9.65Z"
      fill="currentColor"
    />
  </svg>
</template>
