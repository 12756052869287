import { useFetch } from '~/infrastructure/composables';
import { computed, type ComputedRef, type Ref } from 'vue';
import { type Logo, useGetLogoAdapter } from '~/domains/core';

interface ViewModelReturn {
  logo: ComputedRef<Logo | undefined>;
  loading: Ref<boolean>;
}

export default function useGetLogoViewModel(): ViewModelReturn {
  const { data, loading } = useFetch(async () => await useGetLogoAdapter());

  const logo = computed(() => data.value);

  return {
    loading,
    logo,
  };
}
