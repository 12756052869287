import {
  removeAllFromProductTypeUseCase,
  compareRepository,
  useCompareStore,
} from '~/domains/compare';
import { useSnackbarNotificationService } from '~/domains/core';
import { useI18n } from '~/infrastructure';

export default function useRemoveAllFromProductType(): {
  removeAllFromProductType: (productTypeId: string) => Promise<void>;
} {
  const { notifyError, notifySuccess } = useSnackbarNotificationService();
  const compareStore = useCompareStore();
  const { t } = useI18n();

  const removeAllFromProductType = async (
    productTypeId: string,
  ): Promise<void> => {
    await removeAllFromProductTypeUseCase(productTypeId, {
      notifier: {
        notifySuccess: () => {
          notifySuccess(t('page.compare.allProductsTypeWereRemoved.success'));
        },
        notifyError: () => {
          notifyError(t('common.error.somethingWentWrong'));
        },
      },
      compareRepository,
      storage: compareStore,
    });
  };

  return {
    removeAllFromProductType,
  };
}
