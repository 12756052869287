<template>
  <Vue3Snackbar
    bottom
    right
    :duration="4000"
    :success="$vuetify.theme.themes.customTheme.colors['olive']"
    :info="$vuetify.theme.themes.customTheme.colors['blue-500']"
    :error="$vuetify.theme.themes.customTheme.colors['red-200']"
    :warning="$vuetify.theme.themes.customTheme.colors['orange']"
  >
    <template #message-icon="{ message }">
      <UiIcon v-if="message.type === 'success'" icon="$success" />
      <UiIcon v-if="message.type === 'info'" icon="$success" />
      <UiIcon v-if="message.type === 'error'" icon="$warning" />
      <UiIcon v-if="message.type === 'warning'" icon="$info" />
    </template>
    <template #message-content="{ message }">
      <UiText variant="body-3" v-html="message.text" />
    </template>

    <template #message-close-icon="{ isDismissible, dismiss }">
      <button v-if="!isDismissible" type="button" @click="dismiss">
        <UiIcon size="22" icon="$close" />
      </button>
    </template>
  </Vue3Snackbar>
</template>

<script setup lang="ts">
import { ref, computed, watchEffect, onMounted } from 'vue';
// @ts-expect-error: vue3-snackbar do not have types
import { Vue3Snackbar } from 'vue3-snackbar';
import 'vue3-snackbar/styles';
import { useScroll } from '~/infrastructure/composables';
import { UiText, UiIcon } from '~/ui';

const { isScrollingBottom } = useScroll();

const rootElement = ref();
const top = computed(() => (isScrollingBottom.value ? '54px' : '94px'));

watchEffect(() => {
  rootElement.value?.style.setProperty('--ui-snackbar-top', top.value);
});

onMounted(() => {
  rootElement.value = document.querySelector('#vue3-snackbar--container');
});
</script>

<style lang="scss">
#vue3-snackbar--container {
  --ui-snackbar-top: 0;
  transition: top var(--layout-transition-duration)
    var(--layout-transition-timing);

  &.is-top {
    top: var(--ui-snackbar-top);
  }

  .vue3-snackbar-message {
    border-radius: 16px;
  }

  .vue3-snackbar-message-wrapper {
    padding: 8px 8px;
    align-items: flex-start;
  }

  .vue3-snackbar-message-icon {
    margin-right: 8px;
  }

  .vue3-snackbar-message-title {
    font-weight: 400;
    font-size: 14px;
  }

  .vue3-snackbar-message-content {
    align-self: center;
    width: min(50vw, 288px);
  }

  .vue3-snackbar-message-close {
    margin-left: 0;
    min-width: auto;

    button {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
