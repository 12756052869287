import { useGetCurrencyWithFlagAdapter } from '../../infrastructure/adapters';
import { useFetch } from '~/infrastructure/composables';
import { type Ref } from 'vue';
import { type CurrencyWithFlag } from '../../entities';

interface GetCountriesViewModelReturn {
  currencyWithFlag: Ref<CurrencyWithFlag[] | undefined>;
  loading: Ref<boolean>;
}

export default function useGetCurrencyWithFlagViewModel(): GetCountriesViewModelReturn {
  const getCurrencyWithFlag = useGetCurrencyWithFlagAdapter();
  const { data: currencyWithFlag, loading } = useFetch(getCurrencyWithFlag);

  return {
    loading,
    currencyWithFlag,
  };
}
