import api from '~/infrastructure/api/api.service';
import {
  type CategoryBySlugResponse,
  type CategoryTree,
  type CategoryRequestQuery,
  type CategoryActionType,
} from './types.d';
import categorySerialiser from './category.serializer';
import categoryBySlugSerializer from './serializers/category-by-slug.serializer';
import brandByIdSerializer from './serializers/brand-by-id.serializer';

const categoryRepository = {
  async getCategoriesTree(): Promise<CategoryTree> {
    return await api('/tree', {
      method: 'GET',
    });
  },
  async getCategoryBySlug(
    slug: string,
    params: CategoryRequestQuery,
    type: 'tree' | 'cto' = 'tree',
  ) {
    try {
      const response = await api<CategoryBySlugResponse>(
        `/catalog/${type}/${slug}`,
        {
          method: 'GET',
          query: categorySerialiser.categoryBySlugQuery(params),
        },
      );

      return categoryBySlugSerializer(response);
    } catch (error) {
      console.error(error);
    }
  },

  async getCategoryInBrandBySlug(
    brandId: string,
    categorySlug: string,
    params: CategoryRequestQuery,
  ) {
    const response = await api<CategoryBySlugResponse>(
      `/catalog/brand/${brandId}/tree/${categorySlug}`,
      {
        method: 'GET',
        query: categorySerialiser.categoryBySlugQuery(params),
      },
    );

    return categoryBySlugSerializer(response);
  },

  async getBrandById(id: string, params: CategoryRequestQuery) {
    const response = await api<CategoryBySlugResponse>(`/catalog/brand/${id}`, {
      method: 'GET',
      query: categorySerialiser.categoryBySlugQuery(params),
    });

    return brandByIdSerializer(response);
  },

  async getActionByType(
    type: CategoryActionType,
    params: CategoryRequestQuery,
  ) {
    const response = await api<CategoryBySlugResponse>(
      `/catalog/action/${type}`,
      {
        method: 'GET',
        query: categorySerialiser.categoryBySlugQuery(params),
      },
    );

    return categoryBySlugSerializer(response);
  },

  async getActionInTypeBySlug(
    actionType: CategoryActionType,
    slug: string,
    params: CategoryRequestQuery,
  ) {
    const response = await api<CategoryBySlugResponse>(
      `/catalog/action/${actionType}/tree/${slug}`,
      {
        method: 'GET',
        query: categorySerialiser.categoryBySlugQuery(params),
      },
    );

    return categoryBySlugSerializer(response);
  },
};

export default categoryRepository;
