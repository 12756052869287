<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M17.4993 13.3333V5.99992C17.4993 5.0665 17.4993 4.59979 17.3177 4.24327C17.1579 3.92966 16.9029 3.6747 16.5893 3.51491C16.2328 3.33325 15.7661 3.33325 14.8327 3.33325H5.16602C4.23259 3.33325 3.76588 3.33325 3.40936 3.51491C3.09576 3.6747 2.84079 3.92966 2.681 4.24327C2.49935 4.59979 2.49935 5.0665 2.49935 5.99992V13.3333M1.66602 13.3333H18.3327V13.9999C18.3327 14.9333 18.3327 15.4001 18.151 15.7566C17.9912 16.0702 17.7363 16.3251 17.4227 16.4849C17.0661 16.6666 16.5994 16.6666 15.666 16.6666H4.33268C3.39926 16.6666 2.93255 16.6666 2.57603 16.4849C2.26243 16.3251 2.00746 16.0702 1.84767 15.7566C1.66602 15.4001 1.66602 14.9333 1.66602 13.9999V13.3333Z"
      stroke="currentColor"
      stroke-width="1.4"
      stroke-linejoin="round"
    />
  </svg>
</template>
