<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.6501 12.0001C3.6501 7.38852 7.38852 3.6501 12.0001 3.6501C16.6117 3.6501 20.3501 7.38852 20.3501 12.0001C20.3501 16.6117 16.6117 20.3501 12.0001 20.3501C7.38852 20.3501 3.6501 16.6117 3.6501 12.0001ZM12.0001 2.3501C6.67055 2.3501 2.3501 6.67055 2.3501 12.0001C2.3501 17.3296 6.67055 21.6501 12.0001 21.6501C17.3296 21.6501 21.6501 17.3296 21.6501 12.0001C21.6501 6.67055 17.3296 2.3501 12.0001 2.3501ZM12.0101 16.6501C12.3691 16.6501 12.6601 16.3591 12.6601 16.0001C12.6601 15.6411 12.3691 15.3501 12.0101 15.3501H12.0001C11.6411 15.3501 11.3501 15.6411 11.3501 16.0001C11.3501 16.3591 11.6411 16.6501 12.0001 16.6501H12.0101ZM11.3601 13.0001C11.3601 13.3591 11.6511 13.6501 12.0101 13.6501C12.3691 13.6501 12.6601 13.3591 12.6601 13.0001V8.0001C12.6601 7.64111 12.3691 7.3501 12.0101 7.3501C11.6511 7.3501 11.3601 7.64111 11.3601 8.0001V13.0001Z"
      fill="currentColor"
    />
  </svg>
</template>
