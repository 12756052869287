<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.35004 12C4.35004 12.359 4.64105 12.65 5.00004 12.65H17.4308L12.5404 17.5404C12.2866 17.7942 12.2866 18.2058 12.5404 18.4596C12.7943 18.7134 13.2058 18.7134 13.4597 18.4596L19.4596 12.4596C19.7135 12.2058 19.7135 11.7942 19.4596 11.5404L13.4597 5.54036C13.2058 5.28652 12.7943 5.28652 12.5404 5.54036C12.2866 5.7942 12.2866 6.20575 12.5404 6.45959L17.4308 11.35H5.00004C4.64105 11.35 4.35004 11.641 4.35004 12Z"
      fill="currentColor"
    />
  </svg>
</template>
