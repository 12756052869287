<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.64998 12.0001C3.64998 7.38852 7.3884 3.6501 12 3.6501C16.6116 3.6501 20.35 7.38852 20.35 12.0001C20.35 16.0468 17.4713 19.4212 13.65 20.1871V15.3063H15H15.5328L15.6374 14.7838L16.1374 12.2838L16.2928 11.5063H15.5H13.65V9.65632C13.65 9.35947 13.7183 9.20847 13.7763 9.12835C13.8351 9.04726 13.9288 8.97614 14.0897 8.91984C14.4221 8.80352 14.8473 8.80468 15.3373 8.80603C15.3908 8.80617 15.445 8.80632 15.5 8.80632L16.15 8.8063V8.15632V6.15632V5.61384L15.6163 5.5168C13.2928 5.09435 11.7026 5.51318 10.6853 6.35573C9.68351 7.18554 9.34998 8.33525 9.34998 9.15632V11.5063H7.99998H7.34998V12.1563V14.6563V15.3063H7.99998H9.34998V19.9208C6.0374 18.813 3.64998 15.6854 3.64998 12.0001ZM10.65 20.2415C11.0894 20.313 11.5404 20.3501 12 20.3501C12.1172 20.3501 12.2339 20.3477 12.35 20.3429V14.6563V14.0063H13H14.4671L14.7071 12.8063H13H12.35V12.1563V9.65632C12.35 9.15317 12.4692 8.71667 12.7236 8.36554C12.9774 8.01539 13.3211 7.81151 13.6603 7.69282C14.0412 7.55949 14.4716 7.51916 14.85 7.50814V6.71327C13.1046 6.49306 12.091 6.87951 11.5146 7.3569C10.8664 7.89376 10.65 8.64406 10.65 9.15632V12.1563V12.8063H9.99998H8.64998V14.0063H9.99998H10.65V14.6563V20.2415ZM12 2.3501C6.67043 2.3501 2.34998 6.67055 2.34998 12.0001C2.34998 17.3296 6.67043 21.6501 12 21.6501C17.3295 21.6501 21.65 17.3296 21.65 12.0001C21.65 6.67055 17.3295 2.3501 12 2.3501Z"
      fill="#1877f2"
    />
  </svg>
</template>
