<template>
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.61585 0.461158L0.683373 5.18013C0.411987 5.5058 0.643565 6.00023 1.06748 6.00023H8.93244C9.35636 6.00023 9.58794 5.5058 9.31655 5.18013L5.38407 0.461158C5.18418 0.221281 4.81575 0.221281 4.61585 0.461158Z"
      fill="currentColor"
    />
  </svg>
</template>
