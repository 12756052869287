<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6501 4C12.6501 3.64101 12.3591 3.35 12.0001 3.35C11.6411 3.35 11.3501 3.64101 11.3501 4V4.85H6.0001C5.7539 4.85 5.52883 4.9891 5.41872 5.20931L2.41872 11.2093C2.37359 11.2996 2.3501 11.3991 2.3501 11.5C2.3501 12.468 2.73465 13.3964 3.41916 14.0809C4.10367 14.7654 5.03206 15.15 6.0001 15.15C6.96814 15.15 7.89653 14.7654 8.58104 14.0809C9.26555 13.3964 9.6501 12.468 9.6501 11.5C9.6501 11.3991 9.6266 11.2996 9.58148 11.2093L7.05182 6.15H11.3501V18.85H7.0001C6.64111 18.85 6.3501 19.141 6.3501 19.5C6.3501 19.859 6.64111 20.15 7.0001 20.15H12.0001H17.0001C17.3591 20.15 17.6501 19.859 17.6501 19.5C17.6501 19.141 17.3591 18.85 17.0001 18.85H12.6501V6.15H16.9484L14.4187 11.2093C14.3736 11.2996 14.3501 11.3991 14.3501 11.5C14.3501 12.468 14.7347 13.3964 15.4192 14.0809C16.1037 14.7654 17.0321 15.15 18.0001 15.15C18.9681 15.15 19.8965 14.7654 20.581 14.0809C21.2655 13.3964 21.6501 12.468 21.6501 11.5C21.6501 11.3991 21.6266 11.2996 21.5815 11.2093L18.5815 5.20931C18.4714 4.9891 18.2463 4.85 18.0001 4.85H12.6501V4Z"
      fill="currentColor"
    />
  </svg>
</template>
