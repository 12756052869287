<template>
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Group">
      <path
        id="Vector"
        d="M3.66699 2.33366V9.33366C3.66699 9.64308 3.78991 9.93982 4.0087 10.1586C4.22749 10.3774 4.52424 10.5003 4.83366 10.5003H9.50032C9.80974 10.5003 10.1065 10.3774 10.3253 10.1586C10.5441 9.93982 10.667 9.64308 10.667 9.33366V4.22483C10.667 4.0694 10.6359 3.91555 10.5756 3.7723C10.5153 3.62905 10.427 3.4993 10.3158 3.39066L8.38207 1.49949C8.16412 1.28638 7.87141 1.16703 7.56658 1.16699H4.83366C4.52424 1.16699 4.22749 1.28991 4.0087 1.5087C3.78991 1.72749 3.66699 2.02424 3.66699 2.33366Z"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M8.33398 10.5007V11.6673C8.33398 11.9767 8.21107 12.2735 7.99228 12.4923C7.77348 12.7111 7.47674 12.834 7.16732 12.834H2.50065C2.19123 12.834 1.89449 12.7111 1.67569 12.4923C1.4569 12.2735 1.33398 11.9767 1.33398 11.6673V5.25065C1.33398 4.94123 1.4569 4.64449 1.67569 4.42569C1.89449 4.2069 2.19123 4.08398 2.50065 4.08398H3.66732"
        stroke="currentColor"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
