import { watch } from 'vue';

export default function useWatchAfterInit(
  value: any,
  callback: () => void,
): void {
  watch(value, (newValue, oldValue) => {
    if (newValue !== undefined && oldValue !== undefined) callback();
  });
}
