<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.16699 3.67091C2.16699 3.22776 2.34303 2.80276 2.65639 2.4894C2.96975 2.17604 3.39475 2 3.8379 2H20.4943C20.7139 1.99964 20.9314 2.0426 21.1344 2.12643C21.3374 2.21025 21.5218 2.33328 21.6772 2.48849C21.8326 2.6437 21.9558 2.82802 22.0399 3.03092C22.1239 3.23382 22.1671 3.4513 22.167 3.67091V20.3273C22.1672 20.5469 22.1242 20.7645 22.0402 20.9675C21.9563 21.1705 21.8332 21.3549 21.6779 21.5103C21.5226 21.6656 21.3382 21.7889 21.1353 21.8729C20.9324 21.9569 20.7148 22.0001 20.4952 22H3.8379C3.6184 22 3.40105 21.9568 3.19826 21.8727C2.99548 21.7887 2.81124 21.6655 2.65607 21.5103C2.5009 21.355 2.37784 21.1707 2.29392 20.9679C2.21 20.7651 2.16687 20.5477 2.16699 20.3282V3.67091ZM10.0834 9.62546H12.7915V10.9855C13.1824 10.2036 14.1824 9.5 15.6852 9.5C18.5661 9.5 19.2488 11.0573 19.2488 13.9145V19.2073H16.3334V14.5655C16.3334 12.9382 15.9424 12.02 14.9497 12.02C13.5724 12.02 12.9997 13.01 12.9997 14.5655V19.2073H10.0834V9.62546ZM5.08336 19.0827H7.99972V9.5H5.08336V19.0818V19.0827ZM8.41699 6.37455C8.42249 6.62425 8.37806 6.87254 8.2863 7.10483C8.19454 7.33713 8.05731 7.54876 7.88266 7.7273C7.708 7.90585 7.49944 8.0477 7.26922 8.14455C7.039 8.2414 6.79176 8.29129 6.54199 8.29129C6.29223 8.29129 6.04498 8.2414 5.81476 8.14455C5.58454 8.0477 5.37598 7.90585 5.20133 7.7273C5.02667 7.54876 4.88944 7.33713 4.79768 7.10483C4.70593 6.87254 4.66149 6.62425 4.66699 6.37455C4.67779 5.88441 4.88007 5.41799 5.23052 5.07517C5.58098 4.73235 6.05174 4.54038 6.54199 4.54038C7.03224 4.54038 7.50301 4.73235 7.85346 5.07517C8.20391 5.41799 8.4062 5.88441 8.41699 6.37455Z"
      fill="white"
    />
  </svg>
</template>
