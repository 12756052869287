import {
  getCompareUseCase,
  compareRepository,
  useCompareStore,
} from '~/domains/compare';
import { useSnackbarNotificationService } from '~/domains/core';
import { useI18n } from '~/infrastructure';

export default function useGetCompareAdapter(): {
  getCompare: () => Promise<void>;
} {
  const { notifyError } = useSnackbarNotificationService();
  const compareStore = useCompareStore();
  const { t } = useI18n();
  const getCompare = async (): Promise<void> => {
    await getCompareUseCase({
      notifier: {
        notifyError: () => {
          notifyError(t('page.compare.productsWereNotLoaded.error'));
        },
      },
      compareRepository,
      storage: compareStore,
    });
  };

  return {
    getCompare,
  };
}
