<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.6499 14C3.6499 13.641 3.35889 13.35 2.9999 13.35C2.64092 13.35 2.3499 13.641 2.3499 14L2.3499 19C2.3499 20.4636 3.53635 21.65 4.9999 21.65L18.9999 21.65C20.4635 21.65 21.6499 20.4636 21.6499 19L21.6499 14C21.6499 13.641 21.3589 13.35 20.9999 13.35C20.6409 13.35 20.3499 13.641 20.3499 14L20.3499 19C20.3499 19.7456 19.7455 20.35 18.9999 20.35L4.9999 20.35C4.25432 20.35 3.6499 19.7456 3.6499 19L3.6499 14ZM11.5131 16.434C11.6363 16.5714 11.8122 16.65 11.9968 16.65C12.1814 16.6501 12.3573 16.5716 12.4806 16.4343L17.4835 10.8629C17.7234 10.5958 17.7013 10.1848 17.4342 9.94496C17.1671 9.70512 16.7561 9.72721 16.5163 9.99431L12.647 14.3033L12.647 3.00002C12.647 2.64104 12.356 2.35002 11.997 2.35002C11.638 2.35002 11.347 2.64104 11.347 3.00002L11.347 14.3018L7.48379 9.99459C7.24409 9.72735 6.83314 9.70502 6.5659 9.94471C6.29866 10.1844 6.27633 10.5954 6.51602 10.8626L11.5131 16.434Z"
      fill="currentColor"
    />
  </svg>
</template>
