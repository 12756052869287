<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.167 4.3143C23.2844 4.70538 22.3363 4.96963 21.3395 5.08907C22.368 4.47368 23.1374 3.50514 23.5043 2.36415C22.538 2.93809 21.4805 3.34207 20.3777 3.55855C19.6361 2.76672 18.6538 2.24189 17.5833 2.06553C16.5129 1.88916 15.4142 2.07115 14.4577 2.58322C13.5013 3.09529 12.7407 3.9088 12.294 4.89744C11.8473 5.88609 11.7395 6.99456 11.9873 8.05076C10.0294 7.95245 8.1141 7.44357 6.36563 6.55713C4.61715 5.67069 3.0746 4.42651 1.83809 2.90533C1.4153 3.63465 1.17219 4.48025 1.17219 5.3808C1.17172 6.19151 1.37136 6.98979 1.7534 7.70484C2.13545 8.41988 2.68808 9.02957 3.36227 9.47981C2.58039 9.45493 1.81576 9.24366 1.13202 8.86358V8.927C1.13194 10.064 1.52526 11.1661 2.24523 12.0462C2.9652 12.9262 3.96747 13.5301 5.08199 13.7553C4.35667 13.9516 3.59622 13.9805 2.85809 13.8399C3.17254 14.8183 3.78506 15.6738 4.60991 16.2867C5.43475 16.8997 6.43062 17.2394 7.4581 17.2582C5.7139 18.6274 3.55981 19.3702 1.34236 19.3669C0.949567 19.367 0.557102 19.3441 0.166992 19.2982C2.41783 20.7454 5.03796 21.5134 7.7139 21.5105C16.7723 21.5105 21.7243 14.0079 21.7243 7.50112C21.7243 7.28972 21.719 7.07621 21.7095 6.86481C22.6727 6.16823 23.5042 5.30564 24.1649 4.31747L24.167 4.3143Z"
      fill="white"
    />
  </svg>
</template>
