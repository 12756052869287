import { ref, type Ref } from 'vue';
import useRouter from './useRouter';

interface Error {
  statusCode: string;
  statusMessage: string;
}

interface UseErrorReturn {
  error: Ref<Error | null>;
  showError: (error: Error) => void;
}

const error = ref<Error | null>(null);

/**
 * Hook for handling errors in the application.
 *
 * @returns An object containing the `error` state and a `showError` method for updating the error state.
 */
export default function useError(): UseErrorReturn {
  const router = useRouter();

  /**
   * Reset the error state after each navigation.
   */
  router.afterEach(() => {
    error.value = null;
  });

  /**
   * Set the error state.
   *
   * @param _error The error to set.
   */
  const showError = (_error: Error): void => {
    error.value = _error;
  };

  return {
    error,
    showError,
  };
}
