<template>
  <UiButtonLabeled
    :variant="basketVariant"
    :text="amountText"
    label="page.basket.title"
    rounded="xl"
    to="/basket"
    color="primary"
  >
    <template #prepend>
      <div :style="{ width: badgeWidth ? `${+badgeWidth + 20}px` : 'auto' }">
        <UiBadge
          ref="uiBadge"
          :offset-y="-1"
          :offset-x="-3"
          :content="basketCount"
          :color="basketCount ? 'red' : 'transparent'"
          :style="{
            marginRight: basketCount ? '14px' : '0',
          }"
          animate
        >
          <UiIcon icon="$cart" size="auto" />
        </UiBadge>
      </div>
    </template>
  </UiButtonLabeled>
</template>

<script setup lang="ts">
import { UiButtonLabeled, UiIcon, UiBadge } from '~/ui';
import { computed, type ComputedRef, ref, watchEffect, type Ref } from 'vue';
import { useBasketStatsStore } from '~/domains/basket';
import { storeToRefs } from 'pinia';
import { useElementSize } from '@vueuse/core';
import { useRoute } from '~/infrastructure';

const uiBadge = ref();
const route = useRoute();
const { basketStats, getQuantity } = storeToRefs(useBasketStatsStore());
const currency = computed(() => basketStats.value?.currency ?? '');
const amountText: ComputedRef<string> = computed(() =>
  basketStats.value?.amount !== undefined
    ? `${basketStats.value?.amount.toString()} ${currency.value}`
    : '',
);
let badgeWidth: Ref<any> | null = null;

const basketCount = computed(() =>
  getQuantity.value !== '0' ? getQuantity.value : '',
);

const basketVariant = computed(() =>
  route.path.startsWith('/basket') ? 'outlined' : 'text',
);

watchEffect(() => {
  if (uiBadge?.value?.$el?.querySelector('.v-badge__badge') !== undefined) {
    const elBadge: HTMLElement =
      uiBadge.value.$el.querySelector('.v-badge__badge');
    const { width } = useElementSize(elBadge);
    badgeWidth = width;
  }
});
</script>
