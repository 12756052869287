<template>
  <div class="ui-navigation-card">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.ui-navigation-card {
  display: inline-block;
  text-align: center;

  .ui-navigation-card-image {
    + * {
      margin-top: 2px;
    }
  }
}
</style>
