import {
  type ComparedSpecification,
  type Specification,
} from '~/domains/compare';

export const convertToSpecificationsViewEntryValue = (
  comparedSpecifications: ComparedSpecification[],
): Array<Specification<string | boolean>> => {
  return comparedSpecifications
    .map((spec) => {
      return [
        {
          attr: spec.caption,
          attrGroup: true,
          code: spec.caption,
        },
        [...spec.mergedFeatures.values()].map((value) => ({
          attr: value.name,
          ...Object.fromEntries(value.values),
        })),
      ].flat();
    })
    .flat();
};
