import { computed, type ComputedRef, ref, type Ref } from 'vue';
import { defineStore } from 'pinia';
import { type BasketStatistics } from '~/domains/basket';
import { type Price } from '~/utils';

export default defineStore('basketStatistics', () => {
  const basketStats: Ref<BasketStatistics | undefined> = ref();

  const updateBasketStats = (payload: BasketStatistics): void => {
    basketStats.value = payload;
  };

  const getAmount: ComputedRef<Price | undefined> = computed(
    () => basketStats.value?.amount,
  );

  const getQuantity: ComputedRef<string | undefined> = computed(
    () => basketStats.value?.quantity,
  );

  const getVatPercent: ComputedRef<string | undefined> = computed(
    () => basketStats.value?.vatPercent,
  );

  const clearBasketStats = (): void => {
    basketStats.value = undefined;
  };

  return {
    basketStats,
    getAmount,
    getQuantity,
    getVatPercent,
    updateBasketStats,
    clearBasketStats,
  };
});
