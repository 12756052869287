import { useSnackbar } from '~/ui';
import { useI18n } from 'vue-i18n';

interface SnackbarNotificationService {
  notifySuccess: (message?: string) => void;
  notifyWarning: (message?: string) => void;
  notifyError: (message?: string) => void;
}

export default function useSnackbarNotificationService(): SnackbarNotificationService {
  const snackbar = useSnackbar();
  const { t } = useI18n();

  const notifyError = (message?: string): void => {
    snackbar.add({
      type: 'error',
      text:
        message !== undefined && message !== ''
          ? message
          : t('common.error.somethingWentWrong'),
    });
  };
  const notifyWarning = (message?: string): void => {
    snackbar.add({
      type: 'warning',
      text:
        message !== undefined && message !== ''
          ? message
          : t('common.notification.warning.text'),
    });
  };

  const notifySuccess = (message?: string): void => {
    snackbar.add({
      type: 'info',
      text:
        message !== undefined && message !== ''
          ? message
          : t('common.notification.success.text'),
    });
  };

  return {
    notifySuccess,
    notifyWarning,
    notifyError,
  };
}
