import { type CompareGroup, type CompareResponse } from '~/domains/compare';
import { productDataSerializer } from '~/domains/product';

export default function compareResponseSerializer(
  response: CompareResponse,
): CompareGroup[] {
  return (
    response.groups?.map((group) => ({
      type: {
        ...group.type,
        quantity: group.type.quantity ?? group.products.length.toString(),
      },
      products: group.products.map((product) => productDataSerializer(product)),
    })) ?? []
  );
}
