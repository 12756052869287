import { type UserBillToAddressResponse } from '../types.d';
import { type UserBillToAddress } from '~/domains/user';

export default function userBillToAddressSerializer(
  data: UserBillToAddressResponse,
): UserBillToAddress {
  return {
    addressId: data?.address_id?.toString(),
    name: data?.name,
    legalName: data?.legal_name,
    type: data?.type,
    addressLine1: data?.address_line1,
    addressLine2: data?.address_line2,
    zipCode: data?.zip_code,
    city: data?.city,
    countryCode: data?.country_code,
    country: data?.country,
    contact: data?.contact,
    phone: data?.phone,
    cityId: data?.city_id?.toString(),
    provinceId: data?.province_id?.toString(),
  };
}
