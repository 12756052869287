import { type ProductImagesResponse } from '../../types';
import type ProductPreviewImages from '../../entities/ProductPreviewImages';

export default function productImagesSerializer(
  images: ProductImagesResponse,
): ProductPreviewImages {
  return {
    thumbs: images.small.map((image) => ({ src: image.url, alt: 'image' })),
    mainImages: images.big_thumbnail.map((image) => ({
      src: image.url,
      alt: 'image',
    })),
    bigImages: images.big.map((image) => ({
      src: image.url,
      alt: 'image',
    })),
    bigImagesThumbs: images.small_thumbnail.map((image) => ({
      src: image.url,
      alt: 'image',
    })),
  };
}
