<template>
  <AppHeaderActionWrapper :active="items?.length > 1" @click="handleClick">
    <div class="layout">
      <UiText variant="body-4" color="grey-400" class="layout__title">
        <AppTranslate :identifier="label" />:
      </UiText>
      <AppHeaderLayoutLabel :country="countryByCode" :label="title" />
    </div>
  </AppHeaderActionWrapper>
</template>

<script setup lang="ts">
import { UiText } from '~/ui';
import { AppTranslate } from '~/infrastructure';
import { computed } from 'vue';
import AppHeaderLayoutLabel from '~/app/layouts/header/components/AppHeaderLayoutLabel.vue';
import useGetCountryWithFlagViewModel from '~/domains/core/ui/viewModels/useGetCountryWithFlagViewModel.ts';
import AppHeaderActionWrapper from './AppHeaderActionWrapper.vue';

export interface Props {
  label: string;
  item: string;
  items: any[];
  countryCode: string;
  city?: string;
}

const props = defineProps<Props>();

const emit = defineEmits<(e: 'click') => void>();

const { countryWithFlag } = useGetCountryWithFlagViewModel();

const handleClick = (): void => {
  if (props.items?.length > 1) emit('click');
};

const countryByCode = computed(() => {
  return countryWithFlag.value?.find(
    (country) => country.countryCode === props.countryCode,
  );
});

const title = computed(() => {
  return props.city !== undefined ? `${props.item}, ${props.city}` : props.item;
});
</script>

<style lang="scss" scoped>
.app-header-layout-button {
  padding: 0 4px;

  @media (max-width: 1024px) {
    height: auto !important;
  }
}

.layout {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  margin-top: 2px;

  &__title {
    line-height: 12px;
  }

  @media (min-width: 1280px) {
    flex-direction: row;
    align-items: center;
    margin-top: 0;

    &__title {
      margin-right: 4px;
      line-height: inherit;
    }
  }
}
</style>
