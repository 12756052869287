<template>
  <UiRow
    no-gutters
    class="pa-4 flex-nowrap"
    style="min-width: 360px; max-width: 400px"
  >
    <UiCol cols="auto" class="align-self-center">
      <UiAvatar size="96" :image="currentAvatar" />
    </UiCol>
    <UiCol class="pl-5 flex-grow-1">
      <UiText
        v-if="managerName"
        variant="body-2"
        color="grey-800"
        class="manager-info-name"
      >
        {{ managerName }}
      </UiText>

      <UiRow no-gutters class="mt-2">
        <UiCol cols="12">
          <UiRow no-gutters class="py-1">
            <UiCol v-if="email" cols="12" class="d-flex">
              <UiLink :to="email.href" color="grey-800" class="d-inline-block">
                <UiRow no-gutters align="center">
                  <UiCol cols="auto">
                    <UiIcon
                      :icon="email?.icon"
                      color="grey-800"
                      class="d-block"
                    />
                  </UiCol>
                  <UiCol cols="auto" class="pl-2">
                    <UiText
                      variant="body-3"
                      color="grey-800"
                      class="manager-info-email"
                    >
                      {{ email?.label }}
                    </UiText>
                  </UiCol>
                </UiRow>
              </UiLink>
            </UiCol>
          </UiRow>

          <UiRow no-gutters class="py-1">
            <UiCol v-if="phone" cols="12" class="d-flex">
              <UiLink :to="phone.href" color="grey-800" class="d-inline-block">
                <UiRow no-gutters align="center">
                  <UiCol cols="auto">
                    <UiIcon
                      :icon="phone?.icon"
                      color="grey-800"
                      class="d-block"
                    />
                  </UiCol>
                  <UiCol cols="auto" class="pl-2">
                    <UiText
                      variant="body-3"
                      color="grey-800"
                      class="manager-info-email"
                    >
                      {{ phone?.label }}
                    </UiText>
                  </UiCol>
                </UiRow>
              </UiLink>
            </UiCol>
          </UiRow>
        </UiCol>
      </UiRow>

      <div class="mt-1">
        <UiRow v-if="messengers.length > 0" class="mx-n2 my-n1">
          <UiCol
            v-for="(messenger, index) in messengers"
            :key="index"
            cols="auto"
            class="px-2 py-1"
          >
            <UiLink :to="messenger.href" class="d-inline-block">
              <UiIcon :icon="messenger.icon" />
            </UiLink>
          </UiCol>
        </UiRow>
      </div>
    </UiCol>
  </UiRow>
</template>

<script lang="ts" setup>
import { UiRow, UiAvatar, UiCol, UiText, UiIcon, UiLink } from '~/ui';
import { type ManagerInfo } from '~/app/types.d';
import { computed } from 'vue';

export interface Props {
  phone: ManagerInfo;
  email: ManagerInfo;
  avatar: string;
  managerName: string;
  messengers: ManagerInfo[];
}

const props = defineProps<Props>();

const currentAvatar = computed(() => props.avatar ?? '/svg/avatar.svg');
</script>
