import {
  type Pager,
  type Attachment,
  type PagerResponse,
  type PagerQueryRequest,
} from '~/infrastructure';
import { type Price } from '~/utils';
import type { Filter } from '~/domains/account';
import { type LocationQueryValue } from 'vue-router';

export enum AccountStatementDetailsLineCondition {
  BRAND_NEW = 'BRAND_NEW',
  REFURBISHED = 'REFURBISHED',
  SECOND_HAND = 'SECOND_HAND',
  DEFECTIVE = 'DEFECTIVE',
  PROMOTION = 'PROMOTION',
  FIX_ASSESTS = 'FIX_ASSESTS',
  SPARE_PARTS = 'SPARE_PARTS',
  UNKNOWN = 'UNKNOWN',
}

export type AccountInvoiceType = 'Sales invoice' | 'Other' | 'Cash receipt';

export type AccountInvoiceStatusResponse =
  | 'CLOSED'
  | 'PAYMENT_WAITING'
  | 'OVERDUE_BY_TERMS'
  | 'NOT_CALCULATED';

export type AccountInvoiceDecor =
  | 'CELL_DECOR_NONE'
  | 'CELL_DECOR_BLUE'
  | 'CELL_DECOR_RED';

export enum AccountInvoiceStatus {
  CLOSED = 'closed',
  PAYMENT_WAITING = 'inProgress',
  OVERDUE_BY_TERMS = 'notPaid',
  NOT_CALCULATED = 'notCalc',
}

export type AccountSortTypes =
  | 'ENTRY_ASC'
  | 'ENTRY_DESC'
  | 'DOC_NUMBER_ASC'
  | 'DOC_NUMBER_DESC'
  | 'ISSUED_ASC'
  | 'ISSUED_DESC'
  | 'DUE_DATE_ASC'
  | 'DUE_DATE_DESC'
  | 'VALUE_ASC'
  | 'VALUE_DESC'
  | 'REMAINING_AMOUNT_ASC'
  | 'REMAINING_AMOUNT_DESC'
  | 'STATUS_ASC'
  | 'STATUS_DESC'
  | 'STATEMENT_ASC'
  | 'STATEMENT_DESC'
  | 'OVERDUE_DAYS_ASC'
  | 'OVERDUE_DAYS_DESC';

export type AccountSortNames =
  | 'ENTRY'
  | 'DOC_NUMBER'
  | 'ISSUED'
  | 'DUE_DATE'
  | 'VALUE'
  | 'REMAINING_AMOUNT'
  | 'STATUS'
  | 'STATEMENT'
  | 'OVERDUE_DAYS';

export type AccountSortByFieldTypes =
  | 'documentType'
  | 'documentNumber'
  | 'documentDateFrom'
  | 'documentDateTill'
  | 'amount'
  | 'remainingAmount'
  | 'openAccount'
  | 'overdue'
  | 'status';

export interface AccountStatementDetailsResponse {
  id?: string;
  total?: string;
  vat_percent?: string;
  vat_value?: string;
  so_number?: string;
  row_so_total?: string;
  row_txt_total?: string;
  is_owner?: boolean;
  owner?: boolean;
  lines: Array<{
    line_number?: number;
    article?: string;
    sku?: string;
    description?: string;
    qty?: number;
    price?: number;
    sum?: number;
    currency?: string;
    element_id?: number;
    condition?: AccountStatementDetailsLineCondition;
    ean?: string;
  }>;
}

export interface AccountInvoiceResponse {
  doc_type?: AccountInvoiceType;
  entry?: number;
  doc_number?: string;
  doc_date_from?: string;
  doc_date_till?: string;
  amount?: string;
  iso_currency?: string;
  open_account?: string;
  statement_overdue_status: AccountInvoiceStatusResponse;
  decor: AccountInvoiceDecor;
  remaining_amount?: string;
  sum_closed?: string;
  overdue_days?: number;
  open?: boolean;
  amount_erp?: string;
  iso_currency_erp?: string;
  check_merchant?: boolean;
  check_buyer?: boolean;
  tracking?: {
    trac_url?: string;
  };
  attachments?: Attachment[];
}

export interface AccountStatementFilterResponse {
  open: boolean;
  from: string;
  till: string;
  doc: string;
  sort?: AccountSortTypes;
}

export interface AccountStatementResponse {
  lines?: AccountInvoiceResponse[];
  pager: PagerResponse;
  filter: AccountStatementFilterResponse;
}

export interface AccountInvoice {
  documentType: AccountInvoiceType | null;
  entry: number | null;
  documentNumber: string | null;
  documentDateFrom?: Date;
  documentDateTill?: Date;
  amount: Price | null;
  isoCurrency: string | null;
  openAccount: Price | null;
  sumClosed: Price | null;
  remainingAmount: Price | null;
  overdue: number | null;
  open: boolean | null;
  amountErp: Price | null;
  isoCurrencyErp: string | null;
  statementOverdueStatus: AccountInvoiceStatus;
  decor: AccountInvoiceDecor;
  checkMerchant: boolean | null;
  checkBuyer: boolean | null;
  tracking: {
    tracUrl: string | null;
  } | null;
  attachments: Attachment[] | null;
}

export interface AccountStatement {
  lines: AccountInvoice[];
  filter: Filter;
  pager: Pager;
}

export interface AccountStatementDetails {
  id: string | null;
  total: string | null;
  vatPercent: string | null;
  vatValue: string | null;
  soNumber: string | null;
  rowSoTotal: string | null;
  rowTxtTotal: string | null;
  isOwner: boolean | null;
  owner: boolean | null;
  lines: Array<{
    lineNumber: number | null;
    article: string | null;
    sku: string | null;
    description: string | null;
    qty: number | null;
    price: number | null;
    sum: number | null;
    currency: string | null;
    elementId: number | null;
    condition: AccountStatementDetailsLineCondition | null;
    ean: string | null;
  }>;
}

export interface AccountFilterQueryRequest {
  doc?: string;
  from?: string;
  till?: string;
  open?: boolean;
  ps?: number;
  rs?: number;
}

export interface AccountQueryRequest
  extends AccountFilterQueryRequest,
    PagerQueryRequest {}

export interface AccountFilterQuery {
  document?: string;
  dateFrom?: Date | string;
  dateTill?: Date | string;
  open?: boolean;
}

export interface AccountQuery {
  filter: AccountFilterQuery;
  pager: Pager;
  sort?: LocationQueryValue;
}

export interface AccountSortQuery {
  sb: AccountSortTypes;
}
