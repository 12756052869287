<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.0765 3.30135C14.0362 3.2737 12.8809 3.66979 11.7915 4.75476L11.7858 4.76035L11.7858 4.76031L4.99817 11.3573C4.74074 11.6075 4.32923 11.6016 4.07903 11.3442C3.82883 11.0868 3.83469 10.6752 4.09212 10.425L10.877 3.83079C12.1955 2.51905 13.6857 1.96393 15.1111 2.00181C16.5252 2.0394 17.8219 2.65916 18.7721 3.59982C19.722 4.54015 20.3485 5.8239 20.3915 7.21739C20.4348 8.62445 19.8812 10.0862 18.5735 11.3573L18.5735 11.3573L9.07077 20.593C9.07077 20.593 9.07076 20.593 9.07076 20.593C8.26592 21.3753 7.32622 21.6935 6.41732 21.6415C5.52325 21.5903 4.70098 21.1841 4.0925 20.5927C3.48438 20.0017 3.05936 19.1955 3.00572 18.3106C2.95103 17.4085 3.28683 16.4853 4.09212 15.7026L4.54515 16.1687L4.09212 15.7026L12.2373 7.78626C12.4947 7.53606 12.9062 7.54192 13.1564 7.79935C13.4066 8.05678 13.4008 8.4683 13.1433 8.7185L4.99817 16.6349L4.54705 16.1707L4.99817 16.6349C4.44615 17.1714 4.27305 17.7324 4.30334 18.232C4.33467 18.7488 4.5885 19.262 4.99854 19.6605C5.40823 20.0586 5.94345 20.3122 6.49163 20.3436C7.02498 20.3741 7.61229 20.1977 8.16469 19.6608L8.16471 19.6608L17.6674 10.4251C18.7354 9.387 19.1233 8.26987 19.0921 7.25743C19.0605 6.2314 18.5963 5.25502 17.8575 4.52371C17.1191 3.79272 16.1291 3.32933 15.0765 3.30135Z"
      fill="currentColor"
    />
  </svg>
</template>
