<template>
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="34" height="34" rx="10" fill="#1C4D88" />
    <path
      d="M23.2199 25.75H10.7799C10.4332 25.7359 10.0926 25.6535 9.77765 25.5075C9.46274 25.3616 9.17969 25.155 8.9447 24.8996C8.70971 24.6442 8.52739 24.3449 8.40818 24.019C8.28896 23.693 8.23519 23.3468 8.24994 23V20C8.24994 19.8011 8.32896 19.6103 8.46961 19.4697C8.61027 19.329 8.80103 19.25 8.99994 19.25C9.19886 19.25 9.38962 19.329 9.53027 19.4697C9.67093 19.6103 9.74994 19.8011 9.74994 20V23C9.72412 23.2969 9.81359 23.5924 9.99977 23.8251C10.186 24.0579 10.4546 24.21 10.7499 24.25H23.2199C23.5153 24.21 23.7839 24.0579 23.9701 23.8251C24.1563 23.5924 24.2458 23.2969 24.2199 23V20C24.2199 19.8011 24.299 19.6103 24.4396 19.4697C24.5803 19.329 24.771 19.25 24.9699 19.25C25.1689 19.25 25.3596 19.329 25.5003 19.4697C25.6409 19.6103 25.7199 19.8011 25.7199 20V23C25.7499 23.6954 25.504 24.3744 25.0358 24.8894C24.5676 25.4045 23.915 25.7137 23.2199 25.75Z"
      fill="white"
    />
    <path
      d="M16.9998 20.7508C16.9013 20.7513 16.8037 20.7321 16.7127 20.6943C16.6216 20.6565 16.5391 20.6009 16.4698 20.5308L12.4698 16.5308C12.3374 16.3886 12.2652 16.2006 12.2687 16.0063C12.2721 15.812 12.3508 15.6266 12.4882 15.4892C12.6256 15.3518 12.811 15.2731 13.0053 15.2696C13.1996 15.2662 13.3877 15.3383 13.5298 15.4708L16.9998 18.9408L20.4698 15.4708C20.612 15.3383 20.8001 15.2662 20.9944 15.2696C21.1887 15.2731 21.3741 15.3518 21.5115 15.4892C21.6489 15.6266 21.7276 15.812 21.731 16.0063C21.7344 16.2006 21.6623 16.3886 21.5298 16.5308L17.5298 20.5308C17.4606 20.6009 17.3781 20.6565 17.287 20.6943C17.196 20.7321 17.0984 20.7513 16.9998 20.7508Z"
      fill="white"
    />
    <path
      d="M17 20.75C16.8019 20.7474 16.6126 20.6676 16.4725 20.5275C16.3324 20.3874 16.2526 20.1981 16.25 20V9C16.25 8.80109 16.329 8.61032 16.4697 8.46967C16.6103 8.32902 16.8011 8.25 17 8.25C17.1989 8.25 17.3897 8.32902 17.5303 8.46967C17.671 8.61032 17.75 8.80109 17.75 9V20C17.7474 20.1981 17.6676 20.3874 17.5275 20.5275C17.3874 20.6676 17.1981 20.7474 17 20.75Z"
      fill="white"
    />
  </svg>
</template>
