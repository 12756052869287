<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.8501 5.31003C3.8501 4.39876 4.58883 3.66003 5.5001 3.66003H18.5001C19.4114 3.66003 20.1501 4.39876 20.1501 5.31003V7.36766C20.1501 7.83254 19.954 8.27585 19.61 8.58857L14.7647 12.9934C14.6917 13.0598 14.6501 13.1538 14.6501 13.2524V17.692C14.6501 18.317 14.297 18.8883 13.738 19.1678L11.738 20.1678C10.6409 20.7163 9.3501 19.9186 9.3501 18.692V13.2524C9.3501 13.1538 9.3085 13.0598 9.23553 12.9934L4.39019 8.58857C4.04621 8.27585 3.8501 7.83254 3.8501 7.36766V5.31003ZM5.5001 4.96003C5.3068 4.96003 5.1501 5.11673 5.1501 5.31003V7.36766C5.1501 7.46627 5.1917 7.56031 5.26466 7.62664L10.11 12.0315C10.454 12.3442 10.6501 12.7875 10.6501 13.2524V18.692C10.6501 18.9522 10.9239 19.1214 11.1566 19.005L13.1566 18.005C13.2752 17.9458 13.3501 17.8246 13.3501 17.692V13.2524C13.3501 12.7875 13.5462 12.3442 13.8902 12.0315L18.7355 7.62664C18.8085 7.56031 18.8501 7.46628 18.8501 7.36766V5.31003C18.8501 5.11673 18.6934 4.96003 18.5001 4.96003H5.5001Z"
      fill="currentColor"
    />
  </svg>
</template>
