import api from '~/infrastructure/api/api.service';
import { COMPARE } from '../../routes';
import { type CompareRepository } from '../../ports';
import {
  compareAddProductsDeserializer,
  compareRemoveProductDeserializer,
  compareResponseSerializer,
} from '../serializers';
import { type CompareResponse } from '../../types';

const repository: CompareRepository = {
  async getProducts() {
    const response: CompareResponse = await api(COMPARE, {
      method: 'GET',
    });

    return compareResponseSerializer(response);
  },
  async addProducts(products) {
    const response: CompareResponse = await api(COMPARE, {
      method: 'POST',
      body: compareAddProductsDeserializer(products),
    });

    return compareResponseSerializer(response);
  },

  async deleteProduct(product) {
    const response: CompareResponse = await api(COMPARE, {
      method: 'POST',
      body: compareRemoveProductDeserializer(product),
    });

    return compareResponseSerializer(response);
  },
  async deleteAllFromProductType(productTypeId) {
    const response: CompareResponse = await api(`${COMPARE}/${productTypeId}`, {
      method: 'DELETE',
    });

    return compareResponseSerializer(response);
  },
  async deleteAll() {
    await api(COMPARE, {
      method: 'DELETE',
    });
  },
};

export default repository;
