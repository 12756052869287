import { defineStore } from 'pinia';
import { useFilter } from '~/infrastructure';

const useProductFilterStore = defineStore('sidebarFilter', () => {
  const {
    isOpen: isOpenFilter,
    open: openFilter,
    close: closeFilter,
    toggleFilterState,
  } = useFilter();

  return {
    isOpenFilter,
    openFilter,
    closeFilter,
    toggleFilterState,
  };
});

export default useProductFilterStore;
