import type { WarehouseTypeRequest } from './entitites';

export const WAREHOUSE_TYPE: Record<string, WarehouseTypeRequest> = {
  system: 'SYSTEM',
  dropship: 'DROPSHIP',
};

export const DOMAIN_PATTERNS = {
  ASBC: '.asbc',
  ASBIS: '.asbis',
  BREEZY: '.breezy',
};

export const DOMAINS = {
  ASBC: 'ASBC',
  ASBIS: 'ASBIS',
  BREEZY: 'BREEZY',
};
