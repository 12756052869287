<template>
  <div class="app-navbar-search">
    <UiSearch
      v-model="search"
      :label="t('components.search.label.text')"
      rounded="xl"
      button
      @submit="handleSubmit"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue';
import { UiSearch } from '~/ui';
import { useRoute, useRouter, useI18n } from '~/infrastructure';

const search = ref('');

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

watchEffect(() => {
  search.value = route.query.search?.toString() ?? '';
});

const handleSubmit = (): void => {
  router.push({
    path: '/catalog',
    query: {
      search: search.value,
    },
  });
};
</script>

<style scoped>
.app-navbar-search {
  max-width: 530px;
  width: 100%;
}
</style>
