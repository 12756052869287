import { useFetch } from '~/infrastructure/composables';
import { type Ref } from 'vue';
import {
  type CountryWithFlag,
  useGetCountryWithFlagAdapter,
} from '~/domains/core';

interface GetCountriesViewModelReturn {
  countryWithFlag: Ref<CountryWithFlag[] | undefined>;
  loading: Ref<boolean>;
}

export default function useGetCountryWithFlagViewModel(): GetCountriesViewModelReturn {
  const getCountryWithFlag = useGetCountryWithFlagAdapter();
  const { data: countryWithFlag, loading } = useFetch(getCountryWithFlag);

  return {
    loading,
    countryWithFlag,
  };
}
