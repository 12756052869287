export default {
  primary: '#1C4D88',
  black: '#0A0A0A',
  white: '#FFFFFF',
  orange: '#FE7028',
  yellow: '#F3D743',
  olive: '#9EC272',
  purple: '#826EC5',
  'mint-green': '#35D487',

  red: '#FF5656',
  'red-100': '#E56862',
  'red-200': '#FD2D40',

  'grey-50': '#F6F7F9',
  'grey-100': '#ECEEF2',
  'grey-200': '#BFC7DB',
  'grey-400': '#8791A9',
  'grey-600': '#58627C',
  'grey-800': '#3B4151',

  'blue-50': '#F2F7FD',
  'blue-100': '#DCE6FF',
  'blue-200': '#C4DAF3',
  'blue-500': '#5697DA',
  'blue-700': '#1C4D88',
  'blue-800': '#193E6A',
  'blue-900': '#0500EA',
};
