import { type Product } from '~/domains/product';
import {
  type BasketLinesRequest,
  type BasketLineRequest,
  type BasketUpdateMode,
} from '~/domains/basket/types.d';

export default function basketUpdateDeserializer(
  products: Product[],
  mode: BasketUpdateMode,
): BasketLinesRequest {
  const data: BasketLineRequest[] = products.map(
    (product): BasketLineRequest => ({
      article: product.sku,
      ...(mode !== 'DELETE' && { qty: product.quantity?.ordered.toString() }),
      ...(mode === 'RESERVE' && { id: product?.lineId }),
      mode,
    }),
  );

  return {
    lines: data,
  };
}
