import { watchEffect, computed } from 'vue';
import { useScroll } from '~/infrastructure/composables';

/**
 * Directive for setting element position relative app header
 *
 */
const pageTop = {
  mounted(el: HTMLElement) {
    const { isScrollingBottom } = useScroll();

    el.style.transition =
      'top, var(--layout-transition-duration, --layout-transition-timing)';

    watchEffect(() => {
      const offset = computed(() =>
        isScrollingBottom.value ? '0' : 'var(--header-height)',
      );

      el.style.top = offset.value;
    });
  },
};

export default pageTop;
