import {
  addToCompareUseCase,
  compareRepository,
  useCompareStore,
} from '~/domains/compare';
import { useSnackbarNotificationService } from '~/domains/core';
import { type Product } from '~/domains/product';
import { useI18n } from '~/infrastructure';

export default function useAddToCompareAdapter(): {
  addToCompare: (products: Product[]) => Promise<void>;
} {
  const { notifyError, notifySuccess } = useSnackbarNotificationService();
  const compareStore = useCompareStore();
  const { t } = useI18n();
  const addToCompare = async (products: Product[]): Promise<void> => {
    await addToCompareUseCase(products, {
      notifier: {
        notifySuccess: (productsQuantity) => {
          notifySuccess(
            t('page.catalog.productsWereAdded', [productsQuantity]),
          );
        },
        notifyError: () => {
          notifyError(t('common.error.somethingWentWrong'));
        },
      },
      compareRepository,
      storage: compareStore,
    });
  };

  return {
    addToCompare,
  };
}
