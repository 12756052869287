import { type CategoryBySlugResponse, type CategoryBySlug } from '../types';
import { pagerSerializer } from '~/infrastructure';
import categorySerialiser from '../category.serializer';
import {
  productFilterSerializer,
  type ProductResponse,
  productSerializer,
} from '~/domains/product';

export default function categoryBySlugSerializer(
  data: CategoryBySlugResponse,
): CategoryBySlug {
  return {
    tree: categorySerialiser.category(data.tree),
    pager: pagerSerializer(data.pager),
    filter: productFilterSerializer(data.filter),
    products:
      data.products?.map((product: ProductResponse) =>
        productSerializer(product),
      ) ?? [],
  };
}
