<template>
  <UiButtonLabeled
    :variant="compareVariant"
    :color="compareColor"
    label="components.productsDataTable.paginator.comparePanel.goToCompare.text"
    rounded="xl"
    to="/compare"
  >
    <template #prepend>
      <UiBadge
        :offset-y="-1"
        :offset-x="-3"
        :content="compareCount"
        :color="compareCount ? 'red' : 'transparent'"
        animate
        class="mr-1"
      >
        <UiIcon icon="$compare" />
      </UiBadge>
    </template>
  </UiButtonLabeled>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { UiButtonLabeled, UiIcon, UiBadge } from '~/ui';
import { useCompareStore } from '~/domains/compare';
import { useUserStore } from '~/domains/user';
import { useRoute } from '~/infrastructure';

const compare = useCompareStore();
const userStore = useUserStore();
const route = useRoute();
const compareCount = computed(() => {
  let compareCountVal = '';
  // Если данных от запроса /compare нет то вставляем данные от запроса /user/info
  if (compare.count === undefined || compare.count === '') {
    if (userStore.user?.compareOverview.productsCount !== undefined)
      compareCountVal =
        userStore.user?.compareOverview.productsCount !== 0
          ? userStore.user?.compareOverview.productsCount.toString()
          : '';
  }
  // Иначе вставляем данные от запроса /compare
  else compareCountVal = compare.count !== '0' ? compare.count : '';

  return Number(compareCountVal) < 1000 ? compareCountVal : '999+';
});

const compareVariant = computed(() =>
  route.path.startsWith('/compare') ? 'outlined' : 'text',
);

const compareColor = computed(() =>
  route.path.startsWith('/compare') ? 'primary' : 'grey-600',
);
</script>
