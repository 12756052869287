<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 1L16.6673 5.6673L15.1108 7.2227L12 4.1108L8.8892 7.2227L7.3327 5.6673L12 1ZM1 12L5.6673 7.3327L7.2227 8.8892L4.1108 12L7.2227 15.1108L5.6673 16.6673L1 12ZM23 12L18.3327 16.6673L16.7773 15.1108L19.8892 12L16.7773 8.8892L18.3327 7.3327L23 12ZM12 14.2C11.4165 14.2 10.8569 13.9682 10.4444 13.5556C10.0318 13.1431 9.8 12.5835 9.8 12C9.8 11.4165 10.0318 10.8569 10.4444 10.4444C10.8569 10.0318 11.4165 9.8 12 9.8C12.5835 9.8 13.1431 10.0318 13.5556 10.4444C13.9682 10.8569 14.2 11.4165 14.2 12C14.2 12.5835 13.9682 13.1431 13.5556 13.5556C13.1431 13.9682 12.5835 14.2 12 14.2ZM12 23L7.3327 18.3327L8.8892 16.7773L12 19.8892L15.1108 16.7773L16.6673 18.3327L12 23Z"
      fill="currentColor"
    />
  </svg>
</template>
