import { type ProductLineManagerResponse } from '~/domains/product';
import type ProductLineManager from '~/domains/product/entities/ProductLineManager';

export default function productLineManagerSerializer(
  data: ProductLineManagerResponse,
): ProductLineManager {
  return {
    firstName: data.first_name,
    lastName: data.last_name,
    email: data.email,
  };
}
